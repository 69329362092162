import axios from "axios";
import FlyingCartImage from "../../../Components/FlyingCartImage/FlyingCartImage";
import ReactDOM from 'react-dom';

export const quantityIncrement = (sku, qtyVal, minQty, maxQty) => {
    return {
        type: "QUANTITY__INCR__STORE",
        payload: { sku, qtyVal, minQty, maxQty },
    };
};

export const quantityDecrement = (sku, qtyVal, minQty, maxQty) => {
    return {
        type: "QUANTITY__DECR__STORE",
        payload: { sku, qtyVal, minQty, maxQty },
    };
};

export const addToCartAction = (product, langCode, prodQty, linearDiscount, CustomerGroup, userData, toast, prodImg, flyCartRef, maxXY) => {
    return async(dispatch) => {
        try {

            const getLinearDiscount     = linearDiscount ? linearDiscount : 0;

            const checkPrice            = CustomerGroup === "retailer" 
                                            ? product?.retailer_price
                                            : CustomerGroup === "wholesaler" 
                                                ? product?.wholesaler_price 
                                                : product?.b2c_price;

            const outletDiscount        = product?.outlet_status === 1 
                                            ? CustomerGroup === "retailer" 
                                                ? product?.discount_retailer 
                                                : CustomerGroup === "wholesaler" 
                                                    ? product?.discount_wholesaler
                                                    : 20
                                            : 0;

            const totalDiscount         = parseInt(outletDiscount) + parseInt(getLinearDiscount);
            const checkBeforePrice      = product?.price_before ? product?.price_before : product?.before_price;
            const getBeforePrice        = product?.outlet_status === 1 ? checkPrice : checkBeforePrice;
            const getPrice              = (parseFloat(checkPrice) - ((parseFloat(checkPrice) / 100) * parseFloat(totalDiscount))).toFixed(2);

            const languageCode      = langCode;
            const productSKU        = product?.sku ? product?.sku : product?.product_sku;
            const productQty        = prodQty;
            const beforePrice       = getBeforePrice;
            const price             = getPrice;
            const preorderDate      = product?.pre_order_desc;
            const priceList         = userData?.price_list ? userData?.price_list : '';
            const userID            = userData?.userid ? userData?.userid : '';
            const cartId            = product?.cart_id ? product?.cart_id : '';
            const prodImages        = Array.isArray(product?.image) ? product?.image[0] : product?.image;
            let orderType           =  '';

            if(product?.type) {
                orderType = product?.type;
            } else if( product?.pre_sale == 1 
                || (product?.pre_sale == 0 && CustomerGroup === "retailer" && product?.min_qty_retailer > product?.b2b_qty)
                || (product?.pre_sale == 0 && CustomerGroup === "wholesaler" && product?.min_qty_wholesaler > product?.b2b_qty)
            ) {
                orderType = 'pre_order';
            } else if(CustomerGroup === "retailer" || CustomerGroup === "wholesaler"){
                orderType = 'b2b_qty';
            } else {
                orderType = 'b2c_or_quest_qty';
            }

            let actionURL  = `${process.env.REACT_APP_BASE_API_URL}/addCart`;
                actionURL += `?lang=${languageCode}`;
                actionURL += `&sku=${productSKU}`;
                actionURL += `&qty=${productQty}`;
                actionURL += `&before_price=${beforePrice}`;
                actionURL += `&price=${price}`;
                actionURL += `&discount=${totalDiscount}`;
                actionURL += `&cart_id=${cartId}`;
                actionURL += `&user_id=${userID}`;
                actionURL += `&price_list=${priceList}`;
                actionURL += `&type=${orderType}`;
                actionURL += `&linear_discount=${getLinearDiscount}`;
                actionURL += `&image=${prodImages}`;
                actionURL += `&format=${product?.format}`;
                actionURL += `&b2b_qty=${product?.b2b_qty}`;
                actionURL += `&pre_order_qty=${product?.pre_order_qty}`;
                actionURL += `&pre_sale=${product?.pre_sale}`;
                actionURL += `&slug=${product?.slug}`;
                actionURL += `&preorder_date=${preorderDate}`;
                actionURL += `&retailer_price=${product?.retailer_price}`;
                actionURL += `&wholesaler_price=${product?.wholesaler_price}`;
                actionURL += `&b2c_price=${product?.b2c_price}`;
                
            const data = await axios({
                method: 'post',
                url: actionURL,
            });

            if(data?.data?.success) {
                dispatch({ type: "CARTDATA__LIST", payload: data?.data });
                
                const productRef = flyCartRef;
                const productImgRef = productRef.getBoundingClientRect();
            
                let content = document.createElement("DIV");
                content.id = "flytocart";
                document.body.appendChild(content);

                const maxX = maxXY ? 0 : 140;
                const maxY = maxXY ? 0 : 130;
            
                const rootDiv = document.getElementById('flytocart');
            
                ReactDOM.render(<FlyingCartImage imageSrc={prodImg} maxX={maxX} maxY={maxY} targetSize={{posWidth: productImgRef.width,posHeight: productImgRef.height}} targetFrom={{posTop: productImgRef.top,posLeft: productImgRef.left}}/>, rootDiv);
            
                setTimeout(() => {
                  document.getElementById("flytocart").remove();
                }, 1001);
            }else{
                toast.error('add to cart error.')
            }

        } catch(e) {
            console.log(e)
        }
    }
};

export const removeFromCart = (cartId, skuId, toast, t) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/removecartdetails/${cartId}`
            })

            if(data?.data?.success){
                dispatch({ type: "REMOVE_FROM_CART", payload: skuId });
                toast.success(t('product_removed_cart_success'))
            }else{
                console.log("minicart remove error");
            }
        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};

export const clearCartList = (cartId, toast, t) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/clearcart?cart_id=${cartId}`
            })

            if (data?.data?.success) {
                dispatch({ type: "CLEAR_CART" });
                toast.success(t('cart_cleared_successfully'))
            }else {
                console.log("Can't clear the Cart")
            }

        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};
  
  //Clear Cart
  
export const clearCart = () => {
    return {
        type: "CLEAR_CART",
    };
};