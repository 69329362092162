import React, { Suspense } from 'react'
import SeoContent from '../../../Components/SeoContent/SeoContent';
import LangSwitchUrl from '../../../Components/SeoContent/LangSwitchUrl';
import Slider from 'react-slick';
import BrandBlock from '../BrandBlock/BrandBlock';
import CategoryLicensedProduct from '../../CategoryPage/CategoryLicensedProduct/CategoryLicensedProduct';
import FullBannerwithConent from '../../CategoryPage/FullWithBanner/FullBannerwithConent';
import BestSeller from '../../Home/BestSeller/BestSeller';
import DoubleBanner from '../DoubleBanner/DoubleBanner';
import FullWithBanner from '../../CategoryPage/FullWithBanner/FullWithBanner';
import ProductCategories from '../../Home/ProductCategories/ProductCategories';
import BrandAndDiscover from '../BrandAndDiscover/BrandAndDiscover';
import CategoryContent from '../../CategoryPage/CategoryContent/CategoryContent';
import BrandSupplier from '../../CategoryPage/BrandSupplier/BrandSupplier';
import "../Brands/Brands.scss";
import FiveBanner from '../BrandBlockFiveBanner/FiveBanner';

function Prodg({ seoContent, getApiData, alterNative, params, getBrandPageData, brandBlock, bannerContent, settings }) {

    const filteredProducttypeBlock = getApiData?.product_type_image?.filter((item) => {
        return item?.Visibility === 1;
    });

    return (
        <>
            <Suspense fallback={null}>
                {seoContent &&
                    <>
                        <SeoContent seoContent={getApiData?.seo_tag_content} seoDetails={seoContent} alterNative={alterNative} />
                        <LangSwitchUrl alterNative={alterNative} />
                    </>
                }

                <div className='Brand__container Main__section'>

                    {getApiData?.main_banner?.length > 0 ? <div className='categorypage_slider_wrapper'>
                        <Slider {...settings} className="text-center categorypage_slider">
                            {bannerContent}
                        </Slider>
                    </div> :
                        <div className='imagesUploadSoon'>
                            Slider Images Will Upload Soon
                        </div>
                    }
                    <FiveBanner
                        fiveBannerContent={getApiData?.brand_block_content}
                        FiveBannerImg={getApiData?.brand_block_image}
                    />
                    {getApiData?.banner_1?.length > 0 ? <div className='sec__container mb2'>
                        <FullBannerwithConent
                            bannerContent={getApiData?.ads_block_content}
                            bannerData={getApiData?.banner_1?.[0]}
                            contentData='contentBanner'
                            isBrand={true}
                        />
                    </div> :
                        <div className='imagesUploadSoon'>
                            Banner Images Will Upload Soon
                        </div>}
                    <BrandAndDiscover
                        advantage={getApiData?.banner_1}
                        identifier={getApiData?.identifier_block}
                        leftbanner={getApiData?.ads_block_images}
                    />

                    <BestSeller
                        sellerContent={getApiData?.best_selling_product_block_content}
                        sellerData={getApiData?.best_selling_product_block}
                        isBrand={true}
                    />

                    {getApiData?.banner_2?.length > 0 ? <div className='sec__container mb2 mt-4'>
                        <FullWithBanner
                            bannerData={getApiData?.banner_2?.[0]}
                            isContentCenter={true}
                        />
                    </div> :
                        <div className='imagesUploadSoon'>
                            Banner Images Will Upload Soon
                        </div>}

                    <ProductCategories
                        Content={getApiData?.product_type_block_content}
                        Data={filteredProducttypeBlock}
                        cactegoriesLink="Home"
                        isBrand={true}
                    />

                    <div className='sec__container mb2'>
                        {getApiData?.banner_3?.length > 0 ? <FullBannerwithConent
                            bannerData={getApiData?.banner_3?.[0]}
                            isBrand={true}
                            Prodg={true}
                        /> :
                            <div className='imagesUploadSoon'>
                                Banner Images Will Upload Soon
                            </div>}

                        {getApiData?.banner_4?.length > 0 ? <FullWithBanner
                            bannerData={getApiData?.banner_4?.[0]}
                            OhMyPop={true}
                        /> :
                            <div className='imagesUploadSoon'>
                                Banner Images Will Upload Soon
                            </div>}

                        <CategoryContent
                            contentLeft={getApiData?.content_block?.content_block_1}
                            contentRight={getApiData?.content_block?.content_block_2}
                        />
                    </div>

                    <BrandSupplier
                        pageDetails={getApiData?.brand_list}
                        pageForm="oh-my-pop"
                    />

                </div>
            </Suspense>

        </>
    )
}
export default Prodg;