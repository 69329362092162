import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import './OrderSummery.scss';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CouponCode from '../../../Components/CouponCode/CouponCode';
import { PaymentSelection, placeOrderAction } from '../../../redux/features/Checkout/Action';
import { HiPencil } from 'react-icons/hi';
import Payment from '../../../Components/Checkout/Payment/Payment';
import { usePlaceOrderEvent } from '../../../GTM/usePlaceOrderEvent';
// import PaypalMethod from '../../../Components/Checkout/PayPalMethod/PayPalMethod';

function OrderSummery({ setActiveStep }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accept, setAccept] = useState(true);
  const [currentPayment, setCurrentPayment] = useState("cashondelivery")

  const { addPlaceOrderEvent } = usePlaceOrderEvent();

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const cartDataList = useSelector(
    (state) => state?.cartData
  )

  // get User Data
  const userData = useSelector(
    (state) => state?.userdata
  )

  const currentshipping = useSelector(state => state?.currentshipping);
  const currentbilling = useSelector(state => state?.currentbilling);

  const CustomerGroup = userData?.group ? userData?.group : "";
  const linearDiscount = userData?.linear_discount ? userData?.linear_discount : 0;

  const quantityStore = useSelector(
    (state) => state?.quantityStore
  )

  const cartIdList = Object.keys(cartDataList)?.map(
    (e) => cartDataList[e]?.cart_id
  );

  const totalPrice = Object?.keys(cartDataList)?.reduce(
    (acc, item) => acc + cartDataList[item]?.qty * cartDataList[item]?.price,
    0
  );
  // price formate
  const priceFormate = (lang, value) => {
    return (
      <>
        {lang === "es" ? (
          <>
            {Number(value).toLocaleString("es-ES", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : lang === "fr" ? (
          <>
            {Number(value).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : (
          <> {Number(value).toFixed(2)}</>
        )}
        <span className="currency">€</span>
      </>
    );
  };

  const PlaceOrderNav = () => {
    let userData = JSON.parse(localStorage.getItem('userdata'))
    let discount = '';
    let couponCode = '';
    dispatch(placeOrderAction(CustomerGroup, userData, languageCode, cartDataList, cartIdList, totalPrice, totalPrice, discount, couponCode, currentPayment, currentshipping, currentbilling, navigate, addPlaceOrderEvent));
  }

  useEffect(() => {
    if (currentPayment === "cashondelivery") return
    dispatch(PaymentSelection(currentPayment))
  }, [currentPayment])

  const preSaleProductCount = Object.keys(cartDataList)?.filter((item, kay) => {
    return cartDataList[item]?.pre_sale == 1;
  })

  const normalProductCount = Object.keys(cartDataList)?.filter((item, kay) => {
    return cartDataList[item]?.pre_sale == 0;
  })

  return (
    <div className='OrderSummery__warp'>
      <div className='OrderSummery__title'>
        <h1>{t("Order_Summary")}</h1>
      </div>

      <div className='OrderSummery__section'>
        <div className='CartListing__list'>
          <div className='CartTable'>
            <div className='CartTable__head'>
              <div className='CartTable__head__title CartTable__prod'>
                <span>{t("product_name")}</span>
              </div>
              <div className='CartTable__head__title CartTable__price'>
                <span>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>
              </div>
              <div className='CartTable__head__title CartTable__discount'>
                <span>{t("discount")}</span>
              </div>
              <div className='CartTable__head__title CartTable__client'>
                <span>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>
              </div>

              <div className='CartTable__head__title CartTable__price_discount'>
                <span>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>
              </div>

              <div className='CartTable__head__title CartTable__quan'>
                <span>{t("quantity")}</span>
              </div>
              <div className='CartTable__head__title CartTable__subtotal'>
                <span>{t("sub_total")}</span>
              </div>
            </div>

            {normalProductCount?.length > 0 &&
              <div className='CartTable__row '>
                <div className='immediate_service'>
                  <span className='label'>{t("service_immediate_service")}</span>
                </div>
              </div>
            }

            {
              Object.keys(cartDataList)?.filter(item => cartDataList[item]?.pre_sale == 0)?.map((value, j) => {
                // min order quantity
                const minQty = CustomerGroup === "retailer"
                  ? cartDataList[value]?.min_qty_retailer
                  : CustomerGroup === "wholesaler"
                    ? cartDataList[value]?.min_qty_wholesaler
                    : 1;

                // max order quantity
                const maxQty = (CustomerGroup === "retailer" || CustomerGroup === "wholesaler")
                  ? cartDataList[value]?.b2b_qty
                  : cartDataList[value]?.b2c_qty;

                const subTotals = cartDataList[value]?.qty * cartDataList[value]?.price;

                return (
                  <React.Fragment key={j}>
                    <div className='CartTable__row'>
                      <div className='CartTable__row_col CartTable__prod'>
                        <div className='Product__Image'>
                          <Link
                            to={`/${languageCode}/ProductDetails/${cartDataList[value]?.slug}`}
                            title={cartDataList[value]?.format}
                          >
                            <img
                              src={`${process.env.REACT_APP_BASE_PRODUCT_URL}/${cartDataList[value]?.image}`}
                              alt={cartDataList[value]?.brand}
                              title={cartDataList[value]?.format}
                              width={75}
                              height={75}
                            />
                          </Link>
                        </div>

                        <div className='Product__Details'>
                          <span className='Product__Name'>{cartDataList[value]?.format}</span>
                          <span className='Product__Sku'>
                            <span className='label'>SKU: </span>
                            <span className='value'>{cartDataList[value]?.product_sku}</span>
                          </span>
                          <div className='Product__Details_row'>
                            <div class="row_price row_incal">
                              <span class="label">{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>:&nbsp;
                              <span>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                            </div>

                            <div class="row_discount row_incal">
                              <span class="label">{t("discount")}</span>:&nbsp;
                              <span>
                                {cartDataList[value]?.discount == "undefined" ? (
                                  <>0 %</>
                                ) : (
                                  <>{cartDataList[value]?.discount} %</>
                                )}

                                sdsdsd sd sd
                              </span>
                            </div>

                            <div class="row_client row_incal">
                              <span class="label">{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>:&nbsp;
                              <span>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                            </div>

                            <div class="row_qty row_incal">
                              <span class="label">{t("quantity")}</span>:&nbsp;
                              <span>{cartDataList[value]?.qty}</span>
                            </div>

                            <div class="row_total row_incal">
                              <span class="label">{t("sub_total")}</span>:&nbsp;
                              <span>{priceFormate(languageCode, subTotals)}</span>
                            </div>
                          </div>
                        </div>

                        <div className='product_immediate_service'>
                          <span>*</span>  {t("Immediate_service")}: {cartDataList[value]?.qty} {t("QTY")} &nbsp; ({cartDataList[value]?.pre_order_desc})
                        </div>
                      </div>

                      <div className='CartTable__row_col CartTable__price'>
                        <span>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__discount'>
                        <span>
                          {cartDataList[value]?.discount == "undefined" ? (
                            <>0 %</>
                          ) : (
                            <>{cartDataList[value]?.discount} %</>
                          )}
                        </span>
                      </div>

                      <div className='CartTable__row_col CartTable__client'>
                        <span>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__price_discount'>
                        <div class="row_price row_incal">
                          <span class="label">{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>:&nbsp;
                          <span>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                        </div>
                        <div class="row_discount row_incal">
                          <span class="label">{t("discount")}</span>:&nbsp;
                          <span>
                            {cartDataList[value]?.discount == "undefined" ? (
                              <>0 %</>
                            ) : (
                              <>{cartDataList[value]?.discount} %</>
                            )}
                          </span>
                        </div>
                        <div class="row_client row_incal">
                          <span class="label">{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>:&nbsp;
                          <span>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                        </div>
                      </div>

                      <div className='CartTable__row_col CartTable__quan'>
                        <div className='CartQuantity'>
                          {cartDataList[value]?.qty}
                        </div>
                      </div>

                      <div className='CartTable__row_col CartTable__subtotal'>
                        <span>{priceFormate(languageCode, subTotals)}</span>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }

            {preSaleProductCount?.length > 0 &&
              <div className='CartTable__row '>
                <div className='pre_service'>
                  <span className='label'>{t("preOrder")}</span>
                </div>
              </div>
            }

            {
              Object.keys(cartDataList)?.filter(item => cartDataList[item]?.pre_sale == 1)?.map((value, j) => {
                // min order quantity
                const minQty = CustomerGroup === "retailer"
                  ? cartDataList[value]?.min_qty_retailer
                  : CustomerGroup === "wholesaler"
                    ? cartDataList[value]?.min_qty_wholesaler
                    : 1;

                // max order quantity
                const maxQty = (CustomerGroup === "retailer" || CustomerGroup === "wholesaler")
                  ? cartDataList[value]?.b2b_qty
                  : cartDataList[value]?.b2c_qty;

                const subTotals = cartDataList[value]?.qty * cartDataList[value]?.price;

                return (
                  <React.Fragment key={j}>
                    <div className='CartTable__row'>
                      <div className='CartTable__row_col CartTable__prod'>
                        <div className='Product__Image'>
                          <Link
                            to={`/${languageCode}/ProductDetails/${cartDataList[value]?.slug}`}
                            title={cartDataList[value]?.format}
                          >
                            <img
                              src={`${process.env.REACT_APP_BASE_PRODUCT_URL}/${cartDataList[value]?.image}`}
                              alt={cartDataList[value]?.brand}
                              title={cartDataList[value]?.format}
                              width={75}
                              height={75}
                            />
                          </Link>
                        </div>

                        <div className='Product__Details'>
                          <span className='Product__Name'>{cartDataList[value]?.format}</span>
                          <span className='Product__Sku'>
                            <span className='label'>SKU: </span>
                            <span className='value'>{cartDataList[value]?.product_sku}</span>
                          </span>
                          <div className='Product__Details_row'>
                            <div class="row_price row_incal">
                              <span class="label">{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>:&nbsp;
                              <span>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                            </div>

                            <div class="row_discount row_incal">
                              <span class="label">{t("discount")}</span>:&nbsp;
                              <span>
                                {cartDataList[value]?.discount == "undefined" ? (
                                  <>0 %</>
                                ) : (
                                  <>{cartDataList[value]?.discount} %</>
                                )}
                              </span>
                            </div>

                            <div class="row_client row_incal">
                              <span class="label">{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>:&nbsp;
                              <span>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                            </div>

                            <div class="row_qty row_incal">
                              <span class="label">{t("quantity")}</span>:&nbsp;
                              <span>{cartDataList[value]?.qty}</span>
                            </div>

                            <div class="row_total row_incal">
                              <span class="label">{t("sub_total")}</span>:&nbsp;
                              <span>{priceFormate(languageCode, subTotals)}</span>
                            </div>
                          </div>
                        </div>

                        <div className='product_pre_service'>
                          <span>*</span>  {t("preOrder")}: {cartDataList[value]?.qty} {t("QTY")} &nbsp; ({cartDataList[value]?.pre_order_desc})
                        </div>
                      </div>

                      <div className='CartTable__row_col CartTable__price'>
                        <span>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__discount'>
                        <span>
                          {cartDataList[value]?.discount == "undefined" ? (
                            <>0 %</>
                          ) : (
                            <>{cartDataList[value]?.discount} %</>
                          )}
                        </span>
                      </div>

                      <div className='CartTable__row_col CartTable__client'>
                        <span>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__price_discount'>
                        <div class="row_price row_incal">
                          <span class="label">{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>:&nbsp;
                          <span>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                        </div>
                        <div class="row_discount row_incal">
                          <span class="label">{t("discount")}</span>:&nbsp;
                          <span>
                            {cartDataList[value]?.discount == "undefined" ? (
                              <>0 %</>
                            ) : (
                              <>{cartDataList[value]?.discount} %</>
                            )}
                          </span>
                        </div>
                        <div class="row_client row_incal">
                          <span class="label">{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("pdp_price")}</> : <>{t("price")}</>}</span>:&nbsp;
                          <span>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                        </div>
                      </div>

                      <div className='CartTable__row_col CartTable__quan'>
                        <div className='CartQuantity'>
                          {cartDataList[value]?.qty}
                        </div>
                      </div>

                      <div className='CartTable__row_col CartTable__subtotal'>
                        <span>{priceFormate(languageCode, subTotals)}</span>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }

          </div>

          <div className='Address__summery'>
            <div className="Address__summery__block">
              <div className="Address__summery__title">{t("billing_address")} <span onClick={() => navigate(`/${languageCode}/checkout/addressinfo`)}><HiPencil /></span></div>
              <div className='Address__summery__body'>
                <span className='label'>
                  {currentbilling?.first_name}&nbsp;{currentbilling?.last_name}<br />
                  {currentbilling?.address_1} <br />
                  {currentbilling?.city}<br />
                  {currentbilling?.state}<br />
                  {currentbilling?.country_name} - {currentbilling?.postal_code}.<br />
                  Phone: {currentbilling?.telephone}<br />
                  Email: {currentbilling?.email}
                </span>
              </div>
            </div>

            <div className="Address__summery__block">
              <div className="Address__summery__title">{t("shipping_address")} <span onClick={() => navigate(`/${languageCode}/checkout/addressinfo`)}><HiPencil /></span></div>
              <div className='Address__summery__body'>
                <span className='label'>
                  {currentshipping?.first_name}&nbsp;{currentshipping?.last_name}<br />
                  {currentshipping?.address_1} <br />
                  {currentshipping?.city}<br />
                  {currentshipping?.state}<br />
                  {currentshipping?.country_name} - {currentshipping?.postal_code}.<br />
                  Phone: {currentshipping?.telephone}<br />
                  Email: {currentshipping?.email}
                </span>
              </div>
            </div>
          </div>

        </div>

        <div className='Price__summery'>
          <div className="Price__details__block">
            <div className="Price__details__title">{t("in_your_cart")}</div>
            <div className='Price__details__body'>
              <div className='row'>
                <span className='label'>{t("sub_total")}</span>
                <span className="price">{priceFormate(languageCode, totalPrice)}</span>
              </div>
              <div className='row'>
                <span className='label'>{t("coupon_discount")}</span>
                <span className="price">0 %</span>
              </div>
            </div>
            <div className='Price__details__footer'>
              <span className='label'>{t("total")}</span>
              <span className="price">{priceFormate(languageCode, totalPrice)}</span>
            </div>
          </div>

          <CouponCode totalPrice={totalPrice} />

          <div className="TermsCondition__block">
            <div className="TermsCondition__checkbox">
              <input type="checkbox" checked={accept} onChange={(e) => { setAccept(e.target.checked); }} />
            </div>

            {t("agree_the")} <Link target="_blank" to={`/${languageCode}/legal-notice`}> {t("terms_conditions")} </Link>
          </div>

          {accept == false ? (
            <p className="TermsCondition__error">
              {t("terms_conditions_validation")}
            </p>
          ) : (
            <></>
          )}

          <div className="Price__details__nav">
            {(userData?.group === "wholesaler" || userData?.group === "retailer") &&
              <button className="CartSummeryNav" title="Make an Order" onClick={() => PlaceOrderNav()}>
                {t("place_order")}
              </button>
            }
            {/* {currentPayment==="paypal" && paymentselect?.payment_method === 'paypal' && <PaypalMethod totalPrice={totalPrice} />} */}
          </div>
        </div>
      </div>

      {(userData?.group !== "wholesaler" && userData?.group !== "retailer") &&
        <div className='OrderSummery__payment'>
          <div className='OrderSummery__payment_title'>
            <span>{t("payment_methods")}</span>
          </div>
          <Payment setCurrentPayment={setCurrentPayment} currentPayment={currentPayment} totalPrice={totalPrice} PlaceOrderNav={PlaceOrderNav} />
        </div>
      }
    </div>
  )
}

export default OrderSummery


