import React, { useState, useEffect } from 'react'
import './PLP.scss'
import { useDispatch, useSelector } from 'react-redux';
import { getFilters, getProductListing, plpMobileFilterClose, plpMobileFilterOpen } from '../../redux/features/PLP/Action';
import ProductGrid from '../../Components/ProductGrid/ProductGrid';
import { useParams } from 'react-router';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductGridSkeleton from '../../Components/ProductGrid/ProductSkeleton/ProductGridSkeleton';
import { useTranslation } from "react-i18next";
import ProductList from '../../Components/ProductList/ProductList';
import ProductListSkeleton from '../../Components/ProductList/ProductListSkeleton/ProductListSkeleton';
import { BsFillFunnelFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import SeoContent from '../../Components/SeoContent/SeoContent';
import LangSwitchUrl from '../../Components/SeoContent/LangSwitchUrl';

function PLP() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const clusterid = useParams();

  const sortData = [
    { label: "SORTBYBRANDS-ZA", value: "ztoa" },
    { label: "SORTBYBRANDS-AZ", value: "atoz" },
    { label: "PRICE-LH", value: "low-high" },
    { label: "PRICE-HL", value: "high-low" }
  ]

  const getMobileFilter = useSelector((state) => state?.plpMobileFilter);
  // Getting language code
  const languageCode = useSelector((state) => state?.languageCode);

  // Getting Left Filter data
  const breadcrumbs = useSelector(state => state?.breadcrumbs)
  const plpfilterdata = useSelector((state) => state?.plpfilterdata)
  const plpproducttype = useSelector((state) => state?.plpproducttype)
  const plpcolors = useSelector((state) => state?.plpcolors)
  const plplisting = useSelector((state) => state?.plplisting)
  const plpbannerimage = useSelector((state) => state?.plpbannerimage)
  const plptitle = useSelector((state) => state?.plptitle)
  const plpAltTag = useSelector((state) => state?.plpAltTag)
  const plpheader = useSelector((state) => state?.plpheader)
  const plpmeta = useSelector((state) => state?.plpmeta)
  const plpsubbrands = useSelector(state => state?.plpsubbrands)
  const plpfooter = useSelector(state => state?.plpfooter)
  const metaSeoDatas = useSelector((state) => state?.seodatas)

  const [brands, setBrands] = useState([])
  const [updatedFilter, setupdateFilter] = useState([])
  const [pType, setPType] = useState([])
  const [color, setColor] = useState([])
  const [sortType, setSortType] = useState()
  const [sortName, setSortName] = useState()
  const [sortCluster, setSortCluster] = useState("LICENCE GROUP")
  const [sortBrand, setSortBrand] = useState([])
  const [sortID, setSortID] = useState()
  const [sortMethod, setSortMethod] = useState(sortData[0])
  const [open, setOpen] = useState()
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState()
  const [data, setData] = useState([])
  const [autoLoader, setAutoLoader] = useState(1)
  const [brandAcctAcin, setBrandAcctAcin] = useState()
  const [seoContent, setSeoContent] = useState();
  const [alterNative, setAlterNative] = useState();

  useEffect(() => {
    if (metaSeoDatas?.seo_content_details?.meta_title) {
      setSeoContent({ ...metaSeoDatas?.seo_content_details, ...metaSeoDatas?.seo_tag_content });
    }
    if (metaSeoDatas?.alternative_url?.length > 0) {
      setAlterNative(metaSeoDatas?.alternative_url);
    }
  }, [metaSeoDatas])

  const handlePageChange = (selectedPage) => {
    setAutoLoader(1)
    setCurrentPage(selectedPage.selected + 1);
  };

  // purposely commented
  // const loadNextPage = () => {
  //   if (currentPage !== 1 || pType?.length > 0 || color?.length > 0) return
  //   setAutoLoader(autoLoader + 1)
  // }

  const loadNextPage = () => {
    setAutoLoader(autoLoader + 1)
  }

  const [checkFilterUrl, setCheckFilterUrl] = useState()

  // API for Left Side Filter
  useEffect(() => {
    // if(clusterid?.name) {
    //   if(checkFilterUrl?.lg === clusterid?.lg && checkFilterUrl?.clusterid === clusterid?.clusterid && checkFilterUrl?.name === clusterid?.name) return
    // } else {
    //   if(checkFilterUrl?.lg === clusterid?.lg && checkFilterUrl?.clusterid === clusterid?.clusterid) return
    // } 
    setCheckFilterUrl(clusterid)
    setData([])
    setPType([])
    setBrands([])
    setColor([])
    setSortID()
    setView("grid")
    let user = JSON.parse(localStorage.getItem('userdata'))
    dispatch(getFilters(clusterid, languageCode, user))
    clearSorting()
  }, [clusterid])

  // Function Call, when brands add/remove from the left filter
  const brandSelection = (selection, slug_name, subcatid) => {
    if (selection) {
      let findId = brands?.find(b => b === slug_name)
      if (findId === undefined) {
        setBrands(prevState => [...prevState, slug_name])
      }
    } else {
      let findId = []
      findId = brands?.filter(b => b !== slug_name)
      setBrands(findId)
    }

    //added for pagination
    setAutoLoader(1);
    setCurrentPage(1);
    setBrandAcctAcin({ "accId": subcatid });
  }

  // Function to add/remove the Parent ID, when brand selected
  useEffect(() => {
    if (plpsubbrands?.length > 0) {
      const updatedParentArray = plpsubbrands?.map(parent => {
        const updatedChildrenArray = parent.brandsname.filter(child => brands.includes(child?.brandslugname));
        return {
          ...parent,
          children: updatedChildrenArray
        };
      }).filter(parent => parent.children.length > 0);
      setupdateFilter(updatedParentArray)
      // Emptying Cluster Sort, when brand is selected from the left side filter
      setSortID()
      setSortBrand([])
      defaultSort()
    } else {
      const updatedParentArray = plpfilterdata?.map(parent => {
        const updatedChildrenArray = parent.brandtype.filter(child => brands.includes(child?.slug_name));
        return {
          ...parent,
          children: updatedChildrenArray
        };
      }).filter(parent => parent.children.length > 0);
      setupdateFilter(updatedParentArray)
      // Emptying Cluster Sort, when brand is selected from the left side filter
      setSortID()
      setSortBrand([])
      defaultSort()
    }

  }, [brands])


  // Function call to add/remove product type from left filter
  const typeSelection = (selection, id) => {
    if (selection) {
      let findId = pType?.find(b => b === id)
      if (findId === undefined) {
        setPType(prevState => [...prevState, id])
      }
    } else {
      let findId = []
      findId = pType?.filter(b => b !== id)
      setPType(findId)
    }

    //added for pagination
    setCurrentPage(1);
    setAutoLoader(1);
    clearSorting()
  }

  // Function call to add/remove color from left filter
  const colorSelection = (selection, colorData) => {
    if (selection) {
      let findId = color?.find(b => b === colorData)
      if (findId === undefined) {
        setColor(prevState => [...prevState, colorData])
      }
    } else {
      let findId = []
      findId = color?.filter(b => b !== colorData)
      setColor(findId)
    }
  }

  useEffect(() => {
    if (currentPage === 1 && autoLoader > 1 && data?.length > 0) {
      setData(prevState => [...prevState, ...plplisting?.product_list])
    } else {
      setData(plplisting?.product_list)
    }
    setLoader(false)
  }, [plplisting])


  // API Dispatch
  useEffect(() => {
    if (checkFilterUrl?.clusterid !== clusterid?.clusterid && brands?.length) return
    setLoader(true)
    let user = JSON.parse(localStorage.getItem('userdata'))
    let clusters = []
    if (plpmeta?.meta_keywords !== "BRANDS") clusters = updatedFilter?.map(UF => { return UF?.subcatid })
    dispatch(getProductListing(sortID ? sortID : clusters, sortBrand?.length > 0 ? sortBrand : brands, sortType !== undefined ? sortType : pType, color, user, sortMethod?.value, clusterid, currentPage, autoLoader, plpmeta?.meta_keywords))
  }, [updatedFilter, pType, color, sortType, sortBrand, brands, sortMethod, clusterid, currentPage, autoLoader])

  // Sorting when Product Type is selected 
  const typeSorting = (value) => {
    setSortType(value?.productslugname)
    setSortName(value?.productname)
  }

  // Sorting when Cluster is selected
  const ClusterSorting = (value) => {
    setSortCluster(value)
    setSortBrand([])
    if (updatedFilter?.length > 0) {
      let currentSortData = updatedFilter?.find(UF => UF.subcatname === value)
      setSortID(currentSortData?.subcatid)
      setOpen(currentSortData?.subcatid)
      currentSortData?.children?.filter(CH => setSortBrand(prevState => [...prevState, CH?.slug_name]))
    } else {
      plpfilterdata?.filter(PFD => {
        if (PFD?.subcatname === value) {
          setSortID(PFD?.subcatid)
          setOpen(PFD?.subcatid)
          PFD?.brandtype?.filter((item) => item.count > 0).filter(BT => setSortBrand(prevState => [...prevState, BT?.slug_name]))
        }
      })
    }
  }

  const BrandClusterSorting = (value, value1) => {
    setSortBrand(value)
    setSortCluster(value1)
  }

  // Clearing Product Type Sort
  const clearSorting = () => {
    setSortType()
    setSortName()
  }

  // Resetting Clusrter Sorting, when LICENCE GROUP is Selected
  const defaultSort = () => {
    if (sortCluster === "LICENCE GROUP") return
    setSortCluster("LICENCE GROUP")
    setSortBrand([])
    setSortID()
  }

  const SortingSelect = (SD) => {
    setSortMethod(SD)
    setCurrentPage(1)
    setAutoLoader(1)
    setData([])
  }

  const checkBrandAccordion = (subcatid) => {
    if (brands?.length === 0) return false;
    let found = false;

    plpfilterdata.forEach((subcategory) => {
      subcategory.brandtype.forEach((brand) => {
        if (brands.includes(brand.slug_name) && subcategory.subcatid === subcatid) {
          found = true;
        }
      });
    });
    return found;
  };

  // console.log("plplisting", plplisting);
  // console.log("currentPage", currentPage);
  // console.log("autoLoader", autoLoader);
  // console.log("pType", pType);

  return (
    <div className='plp Main__section'>

      {seoContent &&
        <>
          <SeoContent seoDetails={seoContent} alterNative={alterNative} />
          <LangSwitchUrl alterNative={alterNative} />
        </>
      }

      <div className='plp__breadcrumbs'>
        <p>
          <Link to={`/${languageCode}`} title='Go to Homepage'>{breadcrumbs.home} </Link> <span style={{ margin: "0 10px" }}>/</span> {breadcrumbs?.parent_name ? (
            <Link
              to={
                (() => {
                  const urlParts = window.location.pathname.split('/');
                  const lastIndex = urlParts.lastIndexOf('product');
                  if (lastIndex !== -1 && lastIndex < urlParts.length - 1) {
                    urlParts.splice(lastIndex, 2);
                  } else {
                    urlParts.pop();
                  }
                  return urlParts.join('/');
                })()
              }
            >
              {breadcrumbs?.parent_name}
            </Link>
          ) : (
            ""
          )}
          <span>{breadcrumbs?.parent_name ? <span style={{ margin: "0 10px" }}>/</span> : ""} {`${" "}${breadcrumbs?.current_page_title}`}</span>
        </p>

        {/* <p>
          <Link to={`/${languageCode}`}>{breadcrumbs.home}</Link> / {breadcrumbs?.parent_name ? (
            <Link
              to={
                window.location.href.substring(0, window.location.href.lastIndexOf('/')).endsWith('/product')
                  ? window.location.href.substring(0, window.location.href.lastIndexOf('/')).substring(0, window.location.href.lastIndexOf('/'))
                  : window.location.href.substring(0, window.location.href.lastIndexOf('/'))
              }
            >
              {breadcrumbs?.parent_name}
            </Link>
          ) : (
            ""
          )}
          <span>{breadcrumbs?.parent_name ? "/" : ""} {breadcrumbs?.current_page_title}</span>
        </p> */}

      </div>

      {/* <Suspense fallback={null}> */}
      <div className='plp__container'>
        {plpfilterdata?.length === 0 ?
          <Skeleton className='plp__filter__skeleton' count="10" variant="rectangular" animation="wave" height={36} />
          :
          <div className={`plp__filters ${getMobileFilter}`}>
            <div className='plp__filters__sticky'>

              {/* Brand Filters`` */}
              {plpfilterdata?.length > 0 &&
                <>
                  <div className="accordion" id="accordionToType">
                    {plpfilterdata?.length === 0 ? <Skeleton count="5" variant="rectangular" animation="wave" width={288} height={36} /> : <h2 className='plp__filterheading'>{breadcrumbs?.current_page_title}</h2>}
                    {plpfilterdata?.map((FD, i) =>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id={FD?.subcatid} sd>
                          <button className={`accordion-button ${checkBrandAccordion(FD?.subcatid) === true ? "nonebutton" : ""}`} aria-label='accordionbtn' type="button" data-bs-toggle="collapse"
                            data-bs-target={`#${FD?.subcatslugname !== `categories${FD?.subcatid}` ? FD?.subcatslugname : `brands${FD?.subcatid}`}`} aria-expanded="false" aria-controls={FD?.subcatslugname !== `categories${FD?.subcatid}` ? FD?.subcatslugname : `brands${FD?.subcatid}`}>
                            {FD?.subcatname ? FD?.subcatname.toUpperCase() : t("BrandType")}
                          </button>
                        </h2>

                        <div id={FD?.subcatslugname !== `categories${FD?.subcatid}` ? FD?.subcatslugname : `brands${FD?.subcatid}`}
                          className={`accordion-collapse collapse 
                          ${FD?.subcatslugname === "" ? "show" : open !== undefined && open === FD?.subcatid ? "show" : ""} 
                          ${brandAcctAcin?.accId === FD?.subcatid ? "show" : ""}
                          ${checkBrandAccordion(FD?.subcatid) === true && "showAcc"} `}
                          data-bs-parent="#accordionToType"
                          aria-labelledby={FD?.subcatid} >
                          <div className="accordion-body">
                            {FD?.brandtype?.filter(item => item.count > 0)?.map((FDBT) =>
                              <label title={`${FDBT?.brandname.toUpperCase()} (${FDBT?.count})`} key={FDBT?.id}>
                                <input type="checkbox" onChange={e => brandSelection(e.target.checked, FDBT?.slug_name, FD?.subcatid)} />
                                {FDBT?.brandname.toUpperCase()} ({FDBT?.count})
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              }


              {/* Sub Brands Filter */}
              {plpsubbrands?.length > 0 && (
                <div className="accordion" id="accordionSubBrands">
                  {plpsubbrands?.map((SB, index) => (
                    <div className="accordion-item" key={SB.productslugname}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className="accordion-button"
                          aria-label="accordionbtn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`collapse${index}`}
                        >
                          {SB.productname}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionSubBrands"
                      >
                        <div className="accordion-body">
                          {SB.brandsname?.filter(item => item.count > 0)?.map((brand) => (
                            <label title={`${brand?.brandname.toUpperCase()} (${brand?.count})`} key={brand?.brandslugname}>
                              <input
                                type="checkbox"
                                onChange={e => brandSelection(e.target.checked, brand?.brandslugname)}
                              />
                              {brand?.brandname.toUpperCase()} ({brand?.count})
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}



              {/* Product Type Filters */}
              {plpproducttype?.length > 0 && clusterid?.product === undefined &&
                <div className="accordion" id="accordionType">
                  <div className="accordion-item">
                    {loader ? <Skeleton count="1" variant="rectangular" animation="wave" width={288} height={36} /> :
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" aria-label='accordionbtn' type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          {t("ProductType")}
                        </button>
                      </h2>
                    }

                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionType">
                      <div className="accordion-body">
                        {plpproducttype?.filter(item => item?.count > 0)?.map(PT =>
                          <label title={`${PT?.productname.toUpperCase()} (${PT?.count})`} key={PT?.productslugname}>
                            <input type="checkbox" onChange={e => typeSelection(e.target.checked, PT?.productslugname)} />
                            {PT?.productname.toUpperCase()} ({PT?.count})
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }

              {/* Color Filters */}
              {plpcolors?.length > 0 &&
                <div className="accordion" id="accordionColor">
                  <div className="accordion-item">
                    {loader ? <Skeleton count="1" variant="rectangular" animation="wave" width={288} height={36} /> :
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button" type="button" aria-label='accordionbtn' data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          {t("COLOR")}
                        </button>
                      </h2>
                    }
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionColor">
                      <div className="accordion-body plp__colors">
                        {plpcolors?.map(PC =>
                          <span key={PC?.color}>
                            <input type="checkbox" value={PC?.color} id={`cb${PC?.color}`} name="color" onChange={(e) => colorSelection(e.target.checked, PC?.color)} />
                            <label title={`${PC?.color.toUpperCase()}`} for={`cb${PC?.color}`}>
                              <img src={`${process.env.REACT_APP_BASE_URL}/public/attribute/${PC?.color_image}`} alt="" key={PC?.color} />
                            </label>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }

        <div className='plp__content'>
          <h1>{plptitle ? plptitle : <Skeleton count="1" variant="rectangular" animation="wave" width={288} height={36} />}</h1>
          <h3 style={{ textAlign: 'center' }}>{plptitle === "" ? <Skeleton count="1" variant="rectangular" animation="wave" width={288} height={200} /> :
            <img className='plp__banner' src={`${process.env.REACT_APP_BASE_URL}/${plpbannerimage}`} alt={plpAltTag?.image_alt_tag ? plpAltTag?.image_alt_tag : seoContent?.image_alt_tag} title={plpAltTag?.image_title_tag ? plpAltTag?.image_alt_tag : seoContent?.image_title_tag} />
          }</h3>
          <p className="plp__header_content" dangerouslySetInnerHTML={{ __html: plpheader }}></p>

          <div className='plp__mobile__filter'>
            <button type='button' className='plp__mobile__btn' onClick={() => dispatch(plpMobileFilterOpen())}>
              <BsFillFunnelFill />{" "}{t("filter")}
            </button>
          </div>
          <div className={`plp__mobile__filter__back ${getMobileFilter}`} onClick={() => dispatch(plpMobileFilterClose())} />

          <div className='plp__count'>
            <p>{t("total_items")}: {plplisting?.product_count}</p>
            <div className='plp__paginationView'>
              <span>
                <svg onClick={() => setView("grid")} className={`${view === 'grid' && "selected"}`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-3x3" viewBox="0 0 16 16">
                  <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13zM1.5 1a.5.5 0 0 0-.5.5V5h4V1H1.5zM5 6H1v4h4V6zm1 4h4V6H6v4zm-1 1H1v3.5a.5.5 0 0 0 .5.5H5v-4zm1 0v4h4v-4H6zm5 0v4h3.5a.5.5 0 0 0 .5-.5V11h-4zm0-1h4V6h-4v4zm0-5h4V1.5a.5.5 0 0 0-.5-.5H11v4zm-1 0V1H6v4h4z" />
                </svg>
                <svg onClick={() => setView("list")} className={`${view === 'list' && "selected"}`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
              </span>
              <div key={plplisting.prd || plplisting.product_count}>
                <ReactPaginate
                  pageCount={plplisting?.no_pages} // Total number of pages
                  marginPagesDisplayed={4} // Number of pages to display before/after the current page
                  pageRangeDisplayed={2} // Number of pages to display within the pagination
                  onPageChange={handlePageChange} // Callback function when a page is clicked
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  nextLabel=">"
                  previousLabel="<"
                />
              </div>
            </div>
          </div>
          {!loader ?
            <div className='plp__sorting'>
              {!clusterid?.name && plpsubbrands?.length === 0 && clusterid.clusterid !== "brands" && plplisting?.Sub_Category_Details?.[0]?.subcat?.length > 0 &&
                <div className="dropdown">
                  <span className='plp__filterText'>{t("filter_by")}</span>
                  <button className="btn btn-secondary dropdown-toggle" aria-label='dropdownbtn' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {t(sortCluster)}
                  </button>
                  <ul className="dropdown-menu">
                    {sortCluster !== "LICENCE GROUP" && <li className="dropdown-item" onClick={() => defaultSort()}>{t("LICENCE GROUP")} </li>}
                    {plplisting?.Sub_Category_Details?.[0]?.subcat?.length > 0 ? plplisting?.Sub_Category_Details?.[0]?.subcat?.map(UF =>
                      <li><a className="dropdown-item" onClick={() => ClusterSorting(UF?.subcatname)}>{UF?.subcatname}</a></li>
                    )
                      : ""
                    }
                  </ul>
                  {t(sortCluster) !== t("LICENCE GROUP") &&
                    <svg onClick={() => defaultSort()} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                    </svg>
                  }
                </div>
              }

              {/* purposely commented */}
              {/* {plplisting?.brand_unique?.length > 0 &&
                <div className="dropdown">
                  <span className='plp__filterText'>{t("filter_by")}</span>
                  <button className="btn btn-secondary dropdown-toggle" aria-label='dropdownbtn' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {t(sortCluster)}
                  </button>
                  <ul className="dropdown-menu">
                    {sortCluster !== "LICENCE GROUP" && <li className="dropdown-item" onClick={() => defaultSort()}>{t("LICENCE GROUP")} </li>}
                    {plplisting?.brand_unique?.length > 0 ? plplisting?.brand_unique?.map(UF =>
                      <li><a className="dropdown-item" onClick={() => BrandClusterSorting(UF?.brandslugname, UF?.brandname)}>{UF?.brandname}</a></li>
                    )
                      : ""
                    }
                  </ul>
                  {t(sortCluster) !== t("LICENCE GROUP") &&
                    <svg onClick={() => defaultSort()} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                    </svg>
                  }
                </div>
              } */}

              {clusterid?.clusterid === "productos" || clusterid?.product === "product" ||
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" aria-label='dropdownbtn' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {sortName ? sortName : t("ProductType")}
                  </button>
                  <ul className="dropdown-menu">
                    {plplisting?.product_unique?.length > 0 ? plplisting?.product_unique?.map(UNI =>
                      <li><a className="dropdown-item" onClick={() => typeSorting(UNI)}>{UNI?.productname}</a></li>
                    )
                      : plpproducttype?.map(UNI =>
                        <li><a className="dropdown-item" onClick={() => typeSorting(UNI)}>{UNI?.productname}</a></li>
                      )
                    }
                  </ul>
                  {sortName &&
                    <svg onClick={() => clearSorting()} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                    </svg>
                  }
                </div>
              }

              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" aria-label='dropdownbtn' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {t(sortMethod?.label)}
                </button>
                <ul className="dropdown-menu">
                  {sortData?.map(SD =>
                    <li><a className="dropdown-item" onClick={() => SortingSelect(SD)}>{t(SD?.label)}</a></li>
                  )}
                </ul>
              </div>
            </div>
            : <Skeleton count="1" variant="rectangular" animation="wave" width={320} height={36} />
          }

          {plplisting?.product_count === 0 ? <p className='plp__noCount'>
            {t("NoProductAvailable")}
          </p>
            : currentPage === 1 ?
              <div className="plp__grid">
                <InfiniteScroll
                  dataLength={data?.length}
                  next={loadNextPage}
                  hasMore={plplisting?.no_pages === autoLoader ? false : true}
                  loader={currentPage === 1 && (<>
                    {[...Array(12)].map((_, index) => (
                      view === "grid" ? <ProductGridSkeleton /> : <ProductListSkeleton />
                    ))}
                  </>)}
                >
                  {data?.length === 0 && ([...Array(12)].map((_, index) => (
                    view === "grid" ? <ProductGridSkeleton /> : <ProductListSkeleton />
                  )))}
                  {data?.length > 0 && view === "grid" ? data?.map((d, i) => <ProductGrid productData={d} key={i} productKey={i} />) : data?.map((d, i) => <ProductList productData={d} key={i} />)}
                </InfiniteScroll>
              </div>
              :
              <div className="plp__grid">
                {!loader ? data?.map((d, i) =>
                  view === "grid" ? <ProductGrid productData={d} key={i} productKey={i} /> : <ProductList productData={d} key={i} />
                ) : [...Array(12)].map((_, index) => (
                  view === "grid" ? <ProductGridSkeleton /> : <ProductListSkeleton />
                ))}
              </div>
          }

          <div className='plp_footer'>
            <div dangerouslySetInnerHTML={{ __html: plpfooter }} />
          </div>
        </div>
      </div>
      {/* </Suspense>  */}
    </div>
  )
}

export default PLP