import React, { Suspense } from 'react';
import './BrandSupplier.scss';
import { useParams } from 'react-router';

function BrandSupplier(data) {

  const params = useParams();

  const isBrand = ["forever-ninette", "oh-my-pop", "prodg"];
  const isCategory = ["disney", "harry-potter", "warner", "marvel"];

  return (
    <Suspense fallback={null}>
      <div className='BrandSupplier__section'>
        <div className='BrandSupplier__block'>
          {data?.pageForm === "brands"
            ? (
              <>
                <div className='BrandSupplier__link__text' dangerouslySetInnerHTML={{ __html: data?.pageDetails?.page_description_footer }} />
              </>
            )
            : (
              <>
                {!isBrand.includes(params?.slugName) && <><div className="BrandSupplier__content">
                  <div className='BrandSupplier__title'>
                    <span dangerouslySetInnerHTML={{ __html: data?.pageContent?.title }}></span>
                  </div>
                  <div className='Licensed__text'>
                    <span dangerouslySetInnerHTML={{ __html: data?.pageContent?.content }}></span>
                  </div>

                </div>
                  <div className='BrandSupplier__link__text' dangerouslySetInnerHTML={{ __html: data?.pageDetails?.page_description_footer }} />
                </>}
              </>
            )
          }

          {isBrand.includes(data?.pageForm) && <div style={{ textAlign: 'center', marginBottom: "25px" }} dangerouslySetInnerHTML={{ __html: data?.pageDetails?.title }}>
          </div>}

          {isBrand.includes(data?.pageForm) && <div dangerouslySetInnerHTML={{ __html: data?.pageDetails?.content }}>
          </div>}

          {isCategory.includes(params.slugName) && <div dangerouslySetInnerHTML={{ __html: data?.pageDetails?.description }} />}

        </div>
      </div>
    </Suspense>
  )
}
export default BrandSupplier;