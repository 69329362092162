import React, { useEffect, useMemo, useState } from 'react'
import './BillingAddressInfo.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getAddressList } from '../../../redux/features/MyAccount/Action'
import { useParams } from 'react-router'
import PopupAddressModal from '../../PopupAddressModal/PopupAddressModal.js'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

function BillingAddressInfo({currentBilling, setCurrentBilling, currentShipping, setCurrentShipping}) {
  const dispatch = useDispatch()
  const { lg } = useParams()
  const { t, i18n } = useTranslation();
  const [list, setList] = useState([])
  const [editAddress, setEditAddress] = useState({})
  const currentbilling = useSelector(state => state?.currentbilling)
  const currentshipping = useSelector(state => state?.currentshipping)

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if(lg === undefined || user?.userid === undefined) return
    dispatch(getAddressList(user?.userid, lg))
  }, [])

  const addressList = useSelector((state) => state?.addressList);

  useEffect(() => {
    if (!addressList || addressList.length === 0) return;

    const sortedList = [...addressList].sort((a, b) => {
      if (a.default_billing_address === 1) {
        return -1; // a comes first
      } else if (b.default_billing_address === 1) {
        return 1; // b comes first
      }
      return 0; // no change
    });

    setList(sortedList);
    if(currentbilling?.address_id) return

    let findBillingID = addressList?.find(AL => AL?.default_billing_address === 1)
    setCurrentBilling(findBillingID)
    dispatch({ type: "CURRENT__BILLING", payload: findBillingID })
    
    if(currentshipping?.address_id) return

    let findShippingID = addressList?.find(AL => AL?.default_shipping_address === 1)
    setCurrentShipping(findShippingID)
    dispatch({ type: "CURRENT__SHIPPING", payload: findShippingID });

  }, [addressList, currentbilling?.address_id, currentshipping?.address_id, setCurrentBilling, setCurrentShipping])

  useEffect(() => {
    let findBillingID = addressList?.find(AL => AL?.default_billing_address === 1)
    setCurrentBilling(findBillingID)
    dispatch({ type: "CURRENT__BILLING", payload: findBillingID })

    let findShippingID = addressList?.find(AL => AL?.default_shipping_address === 1)
    setCurrentShipping(findShippingID)
    dispatch({ type: "CURRENT__SHIPPING", payload: findShippingID });
  }, [addressList])

  const selectingBillingAddress = (L) => {
    if (addressList.length > 0) {
      if (L?.address_id === currentBilling?.address_id) {
        setCurrentBilling();
        const newList = list.map((Li) => ({ ...Li, default_billing_address: 0 }));
        setList(newList);
      } else {
        setCurrentBilling(L);
        const newList = list.map((Li) =>
          Li?.address_id === L?.address_id
            ? { ...Li, default_billing_address: 1 }
            : { ...Li, default_billing_address: 0 }
        );
        setList(newList);
      }
    } else {
      if(addressList?.length < 2) {
        // setCurrentBilling(L);
        dispatch({ type: "CURRENT__BILLING", payload: L });
        const newList = list.map((Li) =>
          Li?.address_id === L?.address_id
            ? { ...Li, default_billing_address: 1 }
            : { ...Li, default_billing_address: 0 }
        );
        newList.map((Li) => 
            Li?.address_id === L?.address_id || setCurrentBilling() && dispatch({ type: "CURRENT__BILLING", payload: L })
          )
        setList(newList);
      }
    }
  };

  const selectingShippingAddress = (L) => {
    if (addressList.length > 0) {
      if(L?.address_id === currentShipping?.address_id) {
        setCurrentShipping()
        const newList = list.map((Li) => ({ ...Li, default_shipping_address: 0 }));
        setList(newList);
      } else {
        setCurrentShipping(L);
        const newList = list.map((Li) =>
          Li?.address_id === L?.address_id
            ? { ...Li, default_shipping_address: 1 }
            : { ...Li, default_shipping_address: 0 }
          ); 
        setList(newList);
      }
    } else {
      if(addressList?.length < 2) {
        // setCurrentShipping(L);
        dispatch({ type: "CURRENT__SHIPPING", payload: L });
        const newList = list.map((Li) =>
          Li?.address_id === L?.address_id
            ? { ...Li, default_shipping_address: 1 }
            : { ...Li, default_shipping_address: 0 }
        );
        newList.map((Li) => 
            Li?.address_id === L?.address_id || setCurrentShipping() && dispatch({ type: "CURRENT__SHIPPING", payload: L })
          )
        setList(newList);
      }
    }
  }

  // Get Address Status
  const addressStatus = useSelector(state => state.addressStatus)

  useEffect(() => {
    if(addressStatus?.success === 'true') {
      toast.success(addressStatus?.message)
      var addressFormButton = document.getElementById("checkout__addressForm");
      addressFormButton.setAttribute("data-bs-dismiss", "modal");
      addressFormButton.click();
      addressFormButton.removeAttribute("data-bs-dismiss");
      dispatch({ type: "ADDRESS__STATUS", payload: '' })
      let user = JSON.parse(localStorage.getItem('userdata'))
      if(addressStatus.message === "Guest Address is Added" || addressStatus.message === "Guest Address is Updated") return
      dispatch(getAddressList(user?.userid, lg))
    }
  }, [addressStatus])

  const handleCancel = (e) => {
    e.preventDefault()
    e.stopPropagation()
    var addressFormButton = document.getElementById("checkout__addressForm");
    addressFormButton.setAttribute("data-bs-dismiss", "modal");
    addressFormButton.click();
    addressFormButton.removeAttribute("data-bs-dismiss");
  }

  const editAddressData = (e, value) => {
    e.preventDefault()
    e.stopPropagation()
    setEditAddress(value)
  }

  const createAddress = () => {
    setEditAddress({})
  }

  const guestlist = useSelector(state => state?.guestlist)
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if(!user?.userid) {
      setList(guestlist)
    }
  }, [window.location.pathname])

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if(!user?.userid) {
      dispatch({ type: "GUEST__ADDRESS__LIST", payload: list })
    }
  }, [list])

  const newAddressMemo = useMemo(() => {
    const currentUserData = JSON.parse(localStorage.getItem('userdata'));
    return currentUserData?.group === 'wholesaler' || currentUserData?.group === 'retailer' ?
      <>
        <div className='BillingAddressInfo__list__radio'>
          <label>
            <div>
              {currentbilling?.default_billing_address === 1 && (
                <p className='BAI__defaultText'>{t("billing_address")}<span>{" "}({t("default")})</span></p>
              )}
              <p>{currentbilling?.first_name} {currentbilling?.last_name}</p>
              <p>{currentbilling?.address_1} {currentbilling?.address_2}</p>
              <p>{currentbilling?.city}, {currentbilling?.state}</p>
              <p>{currentbilling?.country_name}-{currentbilling?.postal_code}</p>
              <p>{currentbilling?.telephone}</p>
              <p>{currentbilling?.email}</p>
            </div>
          </label>
        </div>
        <button className='BillingAddressInfo__newAddress' onClick={createAddress} data-bs-toggle="modal" data-bs-target="#checkout__addressForm" >{t("add_shipping_address")} +</button>
      </>
      :
      <>
        <button className='BillingAddressInfo__newAddress' onClick={createAddress} data-bs-toggle="modal" data-bs-target="#checkout__addressForm" >{t("add_new_address")} +</button>
      </>
  }, [currentbilling])

  const LISTMEMO = useMemo(() => {
    const currentUserData = JSON.parse(localStorage.getItem('userdata'));
    return list?.length > 0 ? list.map((L, i) => (
      <div key={i} className={`BillingAddressInfo__list ${
        currentUserData?.group === 'wholesaler' || currentUserData?.group === 'retailer' && L?.default_billing_address === 1 && ""}`
      }>
        <div className='BillingAddressInfo__list__radio'>
          <label>
            <div>
              <p>{L?.first_name} {L?.last_name},</p>
              <p>{L?.address_1},</p>
              <p>{L?.city}, {L?.state},</p>
              <p>{L?.country_name}-{L?.postal_code},</p>
              <p>{L?.telephone}</p>
              <p>{L?.email}</p>
            </div>
          </label>
          <div className='BAI__checkbox'>
            {(currentUserData?.group !== 'wholesaler' && currentUserData?.group !== 'retailer') && (
              <label className='ShippingAddressInfo__list__radio__checkbox' onClick={() => selectingBillingAddress(L)}>
                <input type="checkbox" onChange={() => selectingBillingAddress(L)} checked={currentbilling?.address_id === L?.address_id} />
                {t("use_billing_address")}
              </label>
            )}
            <label className='ShippingAddressInfo__list__radio__checkbox' onClick={() => selectingShippingAddress(L)}>
              <input type="checkbox" onChange={() => selectingShippingAddress(L)} checked={currentshipping?.address_id === L?.address_id} />
              {t("use_shipping_address")}
            </label>
          </div>
        </div>
        <button className={`BillingAddressInfo__list__editAddress ${
          (currentUserData?.group === 'wholesaler' || currentUserData?.group === 'retailer') && L?.default_billing_address === 1 && 'BillingAddressInfo__list__editDisabled'}`}
          disabled={(currentUserData?.group === 'wholesaler' || currentUserData?.group === 'retailer') && L?.default_billing_address === 1}
          onClick={(e) => editAddressData(e, L)} data-bs-toggle="modal" data-bs-target="#checkout__addressForm">{t("edit")}</button>
      </div>
    )) :
      <p>{t("no_address_available")}</p>
  }, [list, currentbilling, currentshipping]);

  return (
    <div className='BillingAddressInfo'>
      <h4 className='bai__title'>{t("address_info")}</h4>
      {localStorage.getItem('userdata') === null && list?.length >= 2 ? ""
        :
        newAddressMemo
      }
      <div className='BillingAddressInfo__container'>
        {LISTMEMO}
      </div>
      <div class="modal fade" id="checkout__addressForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <PopupAddressModal  handleCancel={handleCancel} editAddress={editAddress} page="checkout" setList={setList} list={list} userInfo={JSON.parse(localStorage.getItem('userdata'))} />
      </div>
    </div>
  )
}

export default BillingAddressInfo