import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './EmptyCart.scss';

function EmptyCart() {
  const { t, i18n } = useTranslation();

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  return (
    <div class="EmptyCart__Section">
      <div class="EmptyCart_Title">
        <h1>{t("shopping_cart_is_empty")}</h1>
      </div>
      <div class="EmptyCart__warp">
        <p class="label">{t("cart_no_products")}</p>
        <p>{t("start_shopping")}</p>
        <p className="EmptyCart__action">
          <Link className="EmptyCart__homelink" to={`/${languageCode}`}>
            <span>{t("back_to_home")}</span>
          </Link>
        </p>
      </div>
    </div>
  )
}

export default EmptyCart
