import React, { useEffect, useState } from 'react';
import './BrandPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getBrandPage } from '../../redux/features/BrandPage/Action';
import { brandPageApiData } from '../../redux/features/brandPageApiData/Action';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import OhMyPop from './Brands/OhMyPop';
import Prodg from './Brands/Prodg';
import ForeverNinette from './Brands/ForeverNinette';

function BrandPage() {

  const dispatch = useDispatch();
  const params = useParams();

  const [seoContent, setSeoContent] = useState();
  const [alterNative, setAlterNative] = useState();

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const getUserData = useSelector(
    (state) => state?.userdata
  );

  const getBrandPageData = useSelector(
    (state) => state?.brandPageData
  );

  const getApiData = useSelector(
    (state) => state?.brandPageApiData
  );

  useEffect(() => {
    dispatch(getBrandPage(getUserData, languageCode, params?.slugName));
    dispatch(brandPageApiData(params));
  }, [languageCode, getUserData, params]);

  useEffect(() => {
    if (getBrandPageData?.meta_details?.seo_content_details?.meta_title) {
      setSeoContent({ ...getBrandPageData?.meta_details?.seo_content_details, ...getBrandPageData?.seo_tag_content });
    }
    if (getBrandPageData?.meta_details?.alternative_url?.length > 0) {
      setAlterNative(getBrandPageData?.meta_details?.alternative_url);
    }
  }, [getBrandPageData])

  const brandBlock = getBrandPageData?.brand_block?.map((value, i) => {
    const newData = {
      sort_description: value?.description,
      title: value?.product_keyword,
      image: value?.image,
      nolink: "brand"
    }
    return newData;
  });

  const bannerContent = getApiData?.main_banner?.map((value, i) => {
    return (
      <React.Fragment key={i}>
        <a href={value?.url} target="_blank" rel="noreferrer">
          <img
            className="d-block w-100"
            src={`${process.env.REACT_APP_BASE_URL}/${value?.image}`}
            alt={value?.image_alt}
            title={value?.image_title ? value?.image_title : 'karactermania'}
          />

        </a>
      </React.Fragment>
    );
  });

  const CustomRightArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronRight />
    </div>
  );

  const CustomLeftArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronLeft />
    </div>
  );

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    prevArrow: <CustomLeftArrow className="slick-prev" />,
    nextArrow: <CustomRightArrow className="slick-next" />,
  };

  return (
    <>
      {params?.slugName === "forever-ninette" && <ForeverNinette
        seoContent={seoContent}
        getApiData={getApiData}
        alterNative={alterNative}
        params={params}
        getBrandPageData={getBrandPageData}
        bannerContent={bannerContent}
        settings={settings}
      />}

      {params?.slugName === "oh-my-pop" && <OhMyPop
        seoContent={seoContent}
        getApiData={getApiData}
        alterNative={alterNative}
        params={params}
        getBrandPageData={getBrandPageData}
        brandBlock={brandBlock}
        bannerContent={bannerContent}
        settings={settings}
      />}

      {params?.slugName === "prodg" && <Prodg
        seoContent={seoContent}
        getApiData={getApiData}
        alterNative={alterNative}
        params={params}
        getBrandPageData={getBrandPageData}
        brandBlock={brandBlock}
        bannerContent={bannerContent}
        settings={settings}
      />}
    </>

  )
}

export default BrandPage;