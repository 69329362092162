
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

function PaypalMethod({ totalPrice, PlaceOrderNav }) {
  const [success, setSuccess] = useState(false)
  const options = {
    "client-id": "AW5GjqJxghYybz8gmeM48ZdBtNSXaio2SMNvA3C-CirZo0XlyL6VT3p8MJFo6X3DR37Bw14irltkE7-a",
    currency: "EUR",
  }

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: totalPrice,
          },
        },
      ],
    });
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      if(details?.status === "COMPLETED") {
        setSuccess(true)
      }
    });
  }

  const onError = (err) => {
    console.log(err);
  };

  useEffect(() => {
    if(success) {
      PlaceOrderNav();
      setSuccess(false)
    }
  }, [success])

  return (
    <PayPalScriptProvider
      options={options}
    >
      <PayPalButtons
      createOrder={createOrder}
      onApprove={onApprove}
      onError={onError}
      />
    </PayPalScriptProvider>
  );
}

export default PaypalMethod;
