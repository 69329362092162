import React, { useEffect, useState } from 'react'
import './ProgressBar.scss'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next';

function ProgressBar({ steps, activeStep, setActiveStep }) {
  const {lg, checkoutType} = useParams()
  const { t } = useTranslation();
  const navigate = useNavigate()
  
  const navigateSteps = (index, step) => {
    setActiveStep(index)    
    navigate(`/${lg}/checkout/${step?.type}`)
  }

  useEffect(() => {
    if(steps?.length === 0) return
    if(checkoutType === 'cartlist') {
      setActiveStep(0)
    }
    if(checkoutType === 'register') {
      setActiveStep(1)
    }
    if(checkoutType === 'addressinfo') {
      setActiveStep(steps?.length - 2)
    }
    if(checkoutType === 'ordersummery') {
      setActiveStep(steps?.length - 1)
    }
  }, [steps])

  return (
    <div className="progressbar">
      {steps.map((step, index) => (
        <div className="step-progress__step" key={index}>
          <div className={`step-progress__line ${index <= activeStep ? 'active' : ''}
            ${index === 0 && "firstStep"}
            ${index === steps?.length - 1 && "lastStep"}`}></div>
          <div onClick={() => navigateSteps(index, step)} className={`step-progress__number ${index <= activeStep ? 'active' : 'inactive'}`}>
            {index === activeStep ? 
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
              </svg>
              : 
              index + 1
            }
          </div>
          <div className="step-progress__title">{t(step.title)}</div>
        </div>
      ))}
    </div>
  )
}

export default ProgressBar