import React from 'react';
import './ProductSkeleton.scss';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import sklogo from './../../../assects/logoplaceholder.svg'

function ProductGridSkeleton() {
  return (
    <div className='skeleton__product__block'>
      <div className='skeleton__product__img__block'>
        <Skeleton variant="rectangular" animation="wave" width={300} height={270} />
        <div className='skeleton__product__img'>
          <img src={sklogo} alt='sklogo' width={22} height={22}/>
        </div>
      </div>

      <div className='skeleton__product__desc'>
        <div className='skeleton__product__title'>
          <div className='skeleton__product__row'>
            <span className='skeleton__product__name'>
            <Skeleton variant="rectangular" animation="wave" width={200} height={20} />
            <Skeleton variant="rectangular" animation="wave" width={200} height={20} />
            </span>
            <span className='skeleton__product__sku'>
              <Skeleton variant="rectangular" animation="wave" width={75} height={20} />
            </span>
          </div>
        </div>

        <div className='skeleton__product__row'>
          <span className='skeleton__product__brand'>
            <Skeleton variant="rectangular" animation="wave" width={200} height={20} />
          </span>
          <span className='skeleton__product__discount'></span>
          <span className='skeleton__product__price'>
            <Skeleton variant="rectangular" animation="wave" width={75} height={20} />
          </span>
        </div>

        <div className='skeleton__product__row distributor__row'>
          <span className='distributor__price'>
            <Skeleton variant="rectangular" animation="wave" width={200} height={20} />
          </span> 
          <span className='skeleton__product__discount'>
          </span>
          <span className='skeleton__product__price'>
            <Skeleton variant="rectangular" animation="wave" width={75} height={20} />
          </span>
        </div>
      </div>
    </div>
  )
}

export default ProductGridSkeleton
