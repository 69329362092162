import React, { useEffect } from 'react';
import './SuccessPage.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';

function SuccessPage() {
  const { t, i18n } = useTranslation();
  const navigate    = useNavigate();
  const {lg}        = useParams()

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const orderData = useSelector(
    (state) => state?.placeOrderData
  );

  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate(`/${lg}/`)
  //   }, 10000);
  // }, [orderData]);

  return (
    <div className='SuccessPage__Section Main__section'>
      <div className='SuccessPage__Block'>
          <p className='SuccessPage__Img'><IoCheckmarkCircleOutline /></p>
          <p className='SuccessPage__title'>{t("thankyouPurchase")}</p>
          <p className='SuccessPage__orderNo'>{t("yourOrderNumber")}:&nbsp;<span className='SuccessPage__orderinfo'>{orderData?.message?.order_id}</span></p>
          <p className='SuccessPage__info'> {t("emailYouOrderConfirmation")}</p>
          <p className='SuccessPage__nav'>
            <Link className="EmptyCart__homelink" to={`/${languageCode}`}>
              <span>{t("ContinueShopping")}</span>
            </Link>
          </p>
      </div>
        
    </div>
  )
}

export default SuccessPage
