import React, { useEffect, useState } from 'react';
import { IoBagHandleSharp, IoTrashOutline } from "react-icons/io5";
import './Minicart.scss';
import { miniNavClose, miniNavOpen } from '../../../redux/features/Header/Action';
import { useDispatch, useSelector } from 'react-redux';
import { VscChromeClose } from 'react-icons/vsc';
import { Link, useNavigate } from 'react-router-dom';
import { removeFromCart } from '../../../redux/features/QuantityBox/Action';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Minicart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [miniCount, setMiniCount] = useState(0);

  const getMiniNavData = useSelector(
    (state) => state?.miniCartNav
  )

  const cartDataList = useSelector(
    (state) => state?.cartData
  )

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  // get User Data
  const userData = useSelector(
    (state) => state?.userdata
  )

  // customer group
  const CustomerGroup = userData?.group ? userData?.group : "general";

  // price formate
  const priceFormate = (lang, value) => {
    return (
      <>
        {lang === "es" ? (
          <>
            {Number(value).toLocaleString("es-ES", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : lang === "fr" ? (
          <>
            {Number(value).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : (
          <> {Number(value).toFixed(2)}</>
        )}
        <span className="currency">€</span>
      </>
    );
  };

  const totalPrice = Object?.keys(cartDataList)?.reduce(
    (acc, item) => acc + cartDataList[item]?.qty * cartDataList[item]?.price, 0
  );

  useEffect(() => {
    setMiniCount(Object.keys(cartDataList).length);
  }, [cartDataList])

  const checkoutNav = ((product) => {
    dispatch(miniNavClose());
    navigate(`/${languageCode}/checkout/cartlist`);
  })

  return (
    <>
      <div className='Minicart__block shopping__cart'>
        <div className='Minicart__Icon' onClick={() => dispatch(miniNavOpen())}>
          <span className="base__count">
            {miniCount}
          </span>
          <IoBagHandleSharp />
        </div>
      </div>
      <div className={`miniCart__Nav__section ${getMiniNavData}`}>
        <div className='miniCart__popup'>
          <div className='miniCart__header'>
            <div className='minicart__header__title'>
              <span className='minicart__title'> {t("cart")} </span>
              <span className='miniCart__Count'>({miniCount})</span>
            </div>
            <div className='miniCart__header__action'>
              <button className="button__close" aria-label="button__close" onClick={() => dispatch(miniNavClose())}>
                <VscChromeClose />
              </button>
            </div>
          </div>
          <div className='miniCart__body'>
            {miniCount === 0
              ? (
                <div className="miniCart__empty text-center">
                  {t("cart_empty")}
                </div>
              )
              : (
                <>
                  {
                    Object.keys(cartDataList)?.map((value, j) => {
                      return (
                        <React.Fragment key={j}>
                          <div className='miniCart__Product__Block'>
                            <div className='miniCart__Product__Image'>
                              <Link
                                to={`/${languageCode}/ProductDetails/${cartDataList[value]?.slug}`}
                                title={cartDataList[value]?.format}
                              >
                                <img
                                  src={`${process.env.REACT_APP_BASE_PRODUCT_URL}/${cartDataList[value]?.image}`}
                                  alt={cartDataList[value]?.brand}
                                  title={cartDataList[value]?.format}
                                  width={135}
                                  height={135}
                                />
                              </Link>
                            </div>

                            <div className='miniCart__Product__Details'>
                              <div className='miniCart__Product__row'>
                                <Link
                                  to={`/${languageCode}/ProductDetails/${cartDataList[value]?.slug}`}
                                  title={cartDataList[value]?.format}
                                >
                                  <span className='miniCart__Product__Name'>
                                    {cartDataList[value]?.format}
                                  </span>
                                </Link>
                              </div>
                              <div className='miniCart__Product__row'>
                                <div>
                                  <span className='miniCart__Product__Qty'>
                                    {t("QTY")}: {cartDataList[value]?.qty}
                                  </span><br />

                                  <span className='miniCart__Product__Price'>
                                    {(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") ? <>{t("mini_price")}:</> : <>{t("price")}:</>}
                                    &nbsp;{priceFormate(languageCode, cartDataList[value]?.price)}
                                  </span>
                                </div>
                                <div>
                                  <span className='miniCart__Product__Action' onClick={() => dispatch(removeFromCart(cartDataList[value]?.cart_id, cartDataList[value]?.product_sku, toast, t))}>
                                    <IoTrashOutline />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })
                  }
                </>
              )
            }
          </div>
          <div className='miniCart__footer'>
            <div className='miniCart__footer__row'>
              <span className='miniCart__footer__text'>{t("total")}</span>
              <span className='miniCart__footer__price'>{priceFormate(languageCode, totalPrice)}</span>
            </div>
            <div className='miniCart__footer__row'>
              <button className="miniCart__btn btn__checkout" id="btn__checkout" aria-label='btnheckout'
                onClick={() => checkoutNav()}>
                {t("Goto_checkout")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`miniCart__Navback__section ${getMiniNavData}`} onClick={() => dispatch(miniNavClose())}>
      </div>
    </>
  )
}

export default Minicart
