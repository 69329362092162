import React, { useEffect } from 'react';
import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useAuthListener } from './StoreConfig/useAuthListener';
import './App.scss';

import Header from './Components/Header/Header';
import Home from './Pages/Home/Home';
import Footer from './Components/Footer/Footer';
import Aboutus from './Pages/Aboutus/Aboutus';
import Register from './Pages/Register/Register';
import B2CRegister from './Pages/Register/B2CRegister/B2CRegister';
import ShopRegister from './Pages/Register/ShopRegister/ShopRegister';
import PLP from './Pages/PLP/PLP';
import MyAccount from './Pages/MyAccount/MyAccount';
import CategoryPage from './Pages/CategoryPage/CategoryPage';
import BrandPage from './Pages/BrandPage/BrandPage';
import ProductDetails from './Pages/ProductDetails/ProductDetails';
import ContactUs from './Pages/CMS/ContactUs/ContactUs';
import CmsPages from './Pages/CMS/CmsPages/CmsPages';
import Checkout from './Pages/Checkout/Checkout';
import SearchPage from './Pages/SearchPage/SearchPage';
import SuccessPage from './Pages/SuccessPage/SuccessPage';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import Loader from './Components/Loader/Loader';
import AdminUserLogin from './Pages/AdminUserLogin/AdminUserLogin';

function App() {
  useAuthListener();

  const currentLanguage = useSelector((state) => state?.currentLanguage);
  const loadertrigger = useSelector((state) => state?.loadertrigger)

  const browseLanguage = currentLanguage ? currentLanguage : process.env.REACT_APP_BASE_LANGUAGE;

  // function disableConsoleLogs() {
  //   if (window.location.origin !== 'http://localhost:3000') {
  //     console.log = function () {};
  //     console.warn = function () {};
  //     console.error = function () {};
  //     console.info = function () {};
  //     console.debug = function () {};
  //   }
  // }

  // useEffect(() => {
  //   disableConsoleLogs();

  //   // Cleanup function
  //   return () => {
  //     // Restore console methods to their original implementation
  //     console.log = console.__log__;
  //     console.warn = console.__warn__;
  //     console.error = console.__error__;
  //     console.info = console.__info__;
  //     console.debug = console.__debug__;
  //   };
  // }, []);

  return (
    <div className="App">
      <Suspense fallback={null}>
        <Routes>

          <Route path="/" element={<Navigate to={`/${browseLanguage}`} />} />

          <Route path="/:lg" exact element={
            <>
              <Header />
              <Home />
            </>
          } />

          <Route path="/:lg/adminuserlogin/:userId/:userEmail" element={
            <>
              <Header />
              <AdminUserLogin />
            </>
          } />

          <Route path="/:lg/aboutus" exact element={
            <>
              <Header />
              <Aboutus />
            </>
          } />

          <Route path="/:lg/login" exact element={
            <>
              <Header />
              <Register />
            </>
          } />

          <Route path="/:lg/individualregister" exact element={
            <>
              <Header />
              <B2CRegister />
            </>
          } />

          <Route path="/:lg/shopRegister" exact element={
            <>
              <Header />
              <ShopRegister type="RETAILER" />
            </>
          } />

          <Route path="/:lg/wholeregister" exact element={
            <>
              <Header />
              <ShopRegister type="WHOLESALER" />
            </>
          } />

          <Route path="/:lg/checkout/:checkoutType" exact element={
            <>
              <Header />
              <Checkout />
            </>
          } />

          <Route path="/:lg/search/:searchInput" exact element={
            <>
              <Header />
              <SearchPage />
            </>
          } />

          <Route path="/:lg/thankyou" exact element={
            <>
              <Header />
              <SuccessPage />
            </>
          } />

          <Route path="/:lg/resetPassword/:tokan" element={
            <>
              <Header />
              <ResetPassword />
            </>
          } />

          <Route path="/:lg/:clusterid/:name?" exact element={
            <>
              <Header lgChange="lgChange" />
              <PLP />
            </>
          } />

          <Route path="/:lg/:clusterid/:product/:Pname?" exact element={
            <>
              <Header lgChange="lgChange" />
              <PLP />
            </>
          } />

          <Route path={`/:lg/category/:category/:slugName`} exact element={
            <>
              <Header lgChange="lgChange" />
              <CategoryPage />
            </>
          }
          />

          <Route path={`/:lg/brand/:slugName`} exact element={
            <>
              <Header lgChange="lgChange" />
              <BrandPage />
            </>
          }
          />

          <Route path={`/:lg/ProductDetails/:slugName`} exact element={
            <>
              <Header lgChange="lgChange" />
              <ProductDetails />
            </>
          }
          />

          <Route path="/:lg/myaccount" exact element={
            <>
              <Header />
              <MyAccount />
            </>
          } />

          <Route path="/:lg/contactus" exact element={
            <>
              <Header />
              <ContactUs />
            </>
          } />

          <Route path="/:lg/secure-payment" exact element={
            <>
              <Header />
              <CmsPages />
            </>
          } />

          <Route path="/:lg/shipping-returns" exact element={
            <>
              <Header />
              <CmsPages />
            </>
          } />

          <Route path="/:lg/legal-notice" exact element={
            <>
              <Header />
              <CmsPages />
            </>
          } />

          <Route path="/:lg/cookies" exact element={
            <>
              <Header />
              <CmsPages />
            </>
          } />

        </Routes>

        {loadertrigger && <Loader />}

        <Footer />
        <ToastContainer />
      </Suspense>
    </div>
  );
}

export default App;
