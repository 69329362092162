import axios from "axios";

export const getHomeMainBanner = (languageCode) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/homepagemainbanner/${languageCode}`
            })

            dispatch({ type: "HOME__MAIN__BANNER", payload: data?.data });

        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};

export const getHomePageSeoData = (languageCode) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/homepageseocontent/${languageCode}`
            })

            dispatch({ type: "HOME__SEO__DATA", payload: data?.data });

        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};


export const getHomePage = (getUserData, languageCode) => {
    return async(dispatch) => {
        if(languageCode){
            try {
                const priceList = getUserData?.price_list ? getUserData?.price_list : '';
                const groupId   = getUserData?.group ? getUserData?.group : '';
                const linearDiscount = getUserData?.linear_discount ? getUserData?.linear_discount : '';
    
                const data = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BASE_API_URL}/homepage/${languageCode}?price_list=${priceList}&group=${groupId}&linear_discount=${linearDiscount}`
                })

                dispatch({ type: "HOMEPAGE__DATA", payload: data?.data });

            } catch(e) {
                console.log(e)
            }
        }
    }
};
