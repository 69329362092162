import React from 'react'
import { useTranslation } from 'react-i18next';
import { Formik, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { miniSearchClose, miniSearchOpen, searchAction } from '../../../redux/features/Header/Action';
import { useNavigate } from 'react-router';
import './Search.scss';
import { BsSearch } from "react-icons/bs";

function Search() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getMiniSearchData = useSelector(
    (state) => state?.miniSearchData
  )

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  // Get UserData if loggedin
  const userdata = useSelector(
    (state) => state?.userdata
  )

  const formik = useFormik({
    initialValues: {
      searchInput: "",
    },

    validate: (values) => {
      const errors = {};

      if (!values.searchInput) {
        errors.option = "Please fill to find the Product";
      }

      return errors;
    },

    onSubmit: values => {
      dispatch(searchAction(languageCode, values?.searchInput, userdata, navigate));
      formik.resetForm();
      dispatch(miniSearchClose());
    },
  });

  return (
    <>
      <div className='Search__section maxview__search'>
        <Formik>
          <form onSubmit={formik.handleSubmit}>
            <div className="Search__block" role="search">
              <input className="form-control Search__input" minLength={3} type="text" name="searchInput" id="maxSearchInput"
                placeholder={t("search")} title="Please fill to find the Product" autoComplete="off"
                value={formik.values.searchInput} onChange={formik.handleChange} />
              <button className="Search__btn" type="submit" title={t("search")}> {t("search")} </button>
            </div>
          </form>
        </Formik>
      </div>

      <div className='Search__section mobile__search'>
        <button className={`mobile__search__icon ${getMiniSearchData}`} type="button" title={t("search")} onClick={() => dispatch(miniSearchOpen())}>
          <BsSearch />
        </button>

        <div class={`mobile__search__box ${getMiniSearchData}`}>
          <Formik>
            <form onSubmit={formik.handleSubmit}>
              <div className="Search__block" role="search">
                <input className="form-control Search__input" minLength={3} type="text" name="searchInput" id="minSearchInput"
                  placeholder={t("search")} title="Please fill to find the Product" autoComplete="off"
                  value={formik.values.searchInput} onChange={formik.handleChange} />
                <button className="mobile__search__botton" type="submit" title={t("search")}><BsSearch /></button>
              </div>
            </form>
          </Formik>
        </div>
      </div>
      <div className={`search__back__section ${getMiniSearchData}`} onClick={() => dispatch(miniSearchClose())} />
    </>
  )
}

export default Search
