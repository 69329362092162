import React, { Suspense, useEffect, useState } from 'react';
import './CategoryPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryPage } from '../../redux/features/CategoryPage/Action';
import { useParams } from 'react-router';
import CategoryLicensedProduct from './CategoryLicensedProduct/CategoryLicensedProduct';
import FullWithBanner from './FullWithBanner/FullWithBanner';
import BestSeller from '../Home/BestSeller/BestSeller';
import ProductCategories from '../Home/ProductCategories/ProductCategories';
import FullBannerwithConent from './FullWithBanner/FullBannerwithConent';
import FAQ from '../Home/FAQ/FAQ';
import BrandSupplier from './BrandSupplier/BrandSupplier';
import RegisterAndDiscover from '../Home/RegisterAndDiscover/RegisterAndDiscover';
import CharacterSliderBlock from '../Home/CharacterSliderBlock/CharacterSliderBlock';
import SeoContent from '../../Components/SeoContent/SeoContent';
import LangSwitchUrl from '../../Components/SeoContent/LangSwitchUrl';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Slider from 'react-slick';
import { categoryPageApiData } from '../../redux/features/categoryPageApiData/Action';

function CategoryPage() {

  const dispatch = useDispatch();
  const params = useParams();

  const [seoContent, setSeoContent] = useState();
  const [alterNative, setAlterNative] = useState();

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const getUserData = useSelector(
    (state) => state?.userdata
  )

  const getCategoryPageData = useSelector(
    (state) => state?.categoryPageData
  )

  const getApiData = useSelector((state) =>
    state?.categoryPageApiData
  );

  useEffect(() => {
    dispatch(categoryPageApiData(languageCode, params?.category, params?.slugName));
    dispatch(getCategoryPage(getUserData, languageCode, params?.category, params?.slugName));
  }, [languageCode, getUserData, params, dispatch]);

  useEffect(() => {
    if (getCategoryPageData?.meta_details?.seo_content_details?.meta_title) {
      setSeoContent({ ...getCategoryPageData?.meta_details?.seo_content_details, ...getCategoryPageData?.seo_tag_content });
    }
    if (getCategoryPageData?.meta_details?.alternative_url?.length > 0) {
      setAlterNative(getCategoryPageData?.meta_details?.alternative_url);
    }
  }, [getCategoryPageData?.meta_details]);

  const bannerContent = getApiData?.main_banner?.map((value, i) => {
    return (
      <React.Fragment key={i}>
        <a href={value?.url} target="_blank" rel="noreferrer">
          <img
            className="d-block w-100"
            src={`${process.env.REACT_APP_BASE_URL}/${value?.sliderimage}`}
            alt={value?.image_alt}
            title={value?.image_title ? value?.image_title : 'karactermania'}
          />
        </a>
      </React.Fragment>
    );
  });

  const CustomRightArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronRight />
    </div>
  );

  const CustomLeftArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronLeft />
    </div>
  );

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    prevArrow: <CustomLeftArrow className="slick-prev" />,
    nextArrow: <CustomRightArrow className="slick-next" />,
  };

  //filtering character & product type block
  const filteredCharacterBlock = getApiData?.characters_block?.filter((item) => {
    return item?.Visibility === 1;
  });

  const filteredProducttypeBlock = getApiData?.product_type_block?.filter((item) => {
    return item?.Visibility === 1;
  });

  return (
    <>
      <Suspense fallback={null}>
        {seoContent &&
          <>
            <SeoContent seoContent={getApiData?.seo_tag_content} seoDetails={seoContent} alterNative={alterNative} />
            <LangSwitchUrl alterNative={alterNative} />
          </>
        }

        <div className='Category__container Main__section'>

          {getApiData?.main_banner?.length > 0 ? <div className='categorypage_slider_wrapper'>
            <Slider {...settings} className="text-center categorypage_slider">
              {bannerContent}
            </Slider>
          </div> :
            <div className='imagesUploadSoon'>
              Images Will Upload Soon
            </div>
          }

          <CategoryLicensedProduct
            licenseContent={getApiData?.license_block_content}
            licenseData={getApiData?.license_block}
            Gallerydesc={false}
          />

          <CharacterSliderBlock
            charactersContent={getApiData?.characters_block_content}
            charactersData={filteredCharacterBlock}
            parentSlug={params?.category}
            isCategory={true}
          />

          {getApiData?.banner_1?.length > 0 ? <div className='sec__container mb2 mt-4'>
            <FullWithBanner
              bannerData={getApiData?.banner_1?.[0]}
            />
          </div> :
            <div className='imagesUploadSoon'>
              Banner Images Will Upload Soon
            </div>
          }

          <BestSeller
            sellerContent={getApiData?.best_selling_product_block_content}
            sellerData={getApiData?.best_selling_product_block}
          />

          {getApiData?.banner_2?.length > 0 ? <div className='sec__container mb2 mt-4'>
            <FullWithBanner
              bannerData={getApiData?.banner_2?.[0]}
            />
          </div> :
            <div className='imagesUploadSoon'>
              Banner Images Will Upload Soon
            </div>
          }

          <ProductCategories
            Content={getApiData?.product_type_block_content}
            Data={filteredProducttypeBlock}
            cactegoriesLink={params?.category}
          />

          <div className='sec__container mb2'>

            {getApiData?.banner_3?.length > 0 ?
              <FullBannerwithConent
                bannerData={getApiData?.banner_3?.[0]}
              /> :
              <div className='imagesUploadSoon'>
                Banner Images Will Upload Soon
              </div>
            }

            {getApiData?.banner_4?.length > 0 ?
              <FullWithBanner
                bannerData={getApiData?.banner_4?.[0]}
              /> :
              <div className='imagesUploadSoon'>
                Banner Images Will Upload Soon
              </div>
            }
          </div>

          <RegisterAndDiscover
            advantage={getApiData?.advantages_block}
            identifier={getApiData?.identifier_block}
            isTitleShow={true}
          />

          <FAQ
            faqContent={getApiData?.faq}
            faqData={getApiData?.faq?.content}
          />

          <BrandSupplier
            pageDetails={getApiData?.brand_block}
            pageContent={getApiData?.brand_block}
          />
        </div>
      </Suspense>
    </>

  )
}

export default CategoryPage;