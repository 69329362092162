import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteToken } from '../redux/features/Register/Action';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router';

export function useAuthListener() {
  const dispatch    = useDispatch();
  const navigate    = useNavigate();
  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
        if (event.key === 'userdata' && event.newValue === null) {
            // User has logged out in another tab
            dispatch(deleteToken(navigate, languageCode, toast));
            dispatch({ type: "CURRENT__SHIPPING", payload: '' });
            dispatch({ type: "CURRENT__BILLING", payload: '' });
            dispatch({ type: "CLEAR_CART" });
        }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);
}
