import React, { Suspense } from 'react'
import { HiMinus, HiPlus } from "react-icons/hi";
import { useDispatch, useSelector } from 'react-redux';
import { quantityDecrement, quantityIncrement } from '../../redux/features/QuantityBox/Action';

function QuantityBox(data) {
  const dispatch      = useDispatch();
  const quantityStore = useSelector((state) => state?.quantityStore);

  const qtyVal = quantityStore[data?.sku] ? quantityStore[data?.sku] : data?.minQty;

  return (
    <Suspense fallback={null}>
      <button className="cart__btn btn__decrease" id="btn__decrease" aria-label='btn_decrease'
        onClick={(event) => {
          event.preventDefault();
          dispatch(quantityDecrement(data?.sku, qtyVal, data?.minQty, data?.maxQty));
        }} >
        <HiMinus className="decrease__icon inde__icon"/>
      </button>

      <input type="text" className="cart__input qty__input item__count" id="qty_input"
        value={qtyVal} min={data?.minQty} max={data?.maxQty} autoComplete="off" readOnly
      />

      <button className="cart__btn btn__increase" id="btn__increase" aria-label='btn_increase'
        onClick={(event) => {
          event.preventDefault();
          dispatch(quantityIncrement(data?.sku, qtyVal, data?.minQty, data?.maxQty));
        }} >
        <HiPlus className="increase__icon inde__icon"/>
      </button>
    </Suspense>
  )
}

export default QuantityBox
