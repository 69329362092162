import React, { useEffect } from 'react'
import './Catalogs.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getCatalog } from '../../../redux/features/MyAccount/Action'

function Catalogs() {
  const dispatch = useDispatch()
  const mycatalog = useSelector(state => state?.mycatalog)

  useEffect(() => {
    // if(mycatalog?.catalog_content?.content) return
    dispatch(getCatalog())
  }, [])
  
  return (
    <div className='catalogs'>
      <div className='catalogs__container'
        dangerouslySetInnerHTML={{ __html: mycatalog?.catalog_content?.content }}
      ></div>
    </div>
  )
}

export default Catalogs