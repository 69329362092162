import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Aboutus.scss'
import { getAboutUs } from '../../redux/features/CMS/Action';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import LazyLoad from "react-lazyload";
import Placeholder from '../../Components/Placeholder/Placeholder';
import SeoContent from '../../Components/SeoContent/SeoContent';
import LangSwitchUrl from '../../Components/SeoContent/LangSwitchUrl';

function Aboutus() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [seoContent, setSeoContent] = useState();
  const [alterNative, setAlterNative] = useState();

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const aboutBanner = useSelector(
    (state) => state?.aboutBannerData
  );
  const aboutContent = useSelector(
    (state) => state?.aboutContentData
  );

  const aboutGallery = useSelector(
    (state) => state?.aboutGalleryData
  );

  useEffect(() => {
    if (languageCode) {
      dispatch(getAboutUs(languageCode));
    }
  }, [languageCode])

  useEffect(() => {
    if (aboutContent?.seo_tag_content) {
      setSeoContent({ ...aboutContent?.seo_content_details, ...aboutContent?.seo_tag_content });
    }
    if (aboutContent?.alternative_url?.length > 0) {
      setAlterNative(aboutContent?.alternative_url);
    }
  }, [aboutContent])

  const CustomRightArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronRight />
    </div>
  );

  const CustomLeftArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronLeft />
    </div>
  );

  const bannerSettings = {
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: <CustomLeftArrow className="slick-prev" />,
    nextArrow: <CustomRightArrow className="slick-next" />
  };

  const licenseSettings = {
    infinite: aboutGallery?.licenses_gallery?.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomLeftArrow className="slick-prev" />,
    nextArrow: <CustomRightArrow className="slick-next" />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };

  const bannerData = aboutBanner?.map((value, i) => {
    return (
      <div className="banner__block">
        <div className="banner__img">
          {value?.banner &&
            <img className='languageImg' src={`${process.env.REACT_APP_BASE_URL}/${value?.banner}`}
              alt="image" loading="eager" width={100} height={100}
            />
          }
        </div>
        <div className="banner__content">
          <p className="banner__title">{value?.banner_title}</p>
          <p className="banner__des">{value?.banner_description}</p>
        </div>
      </div>
    )
  })

  const licensesGallery = aboutGallery?.licenses_gallery?.map((value, i) => {
    return (
      <div className="licensesGallery__block">
        <LazyLoad height={210}>
          <img className='licensesGallery__img' src={`${process.env.REACT_APP_BASE_URL}/${value?.image}`}
            alt="image" loading="eager" width={100} height={100}
          />
        </LazyLoad>
      </div>
    )
  })

  const lifeGallery = aboutGallery?.lifestyle_gallery?.map((value, i) => {
    return (
      <div className="lifeGallery__block">
        <LazyLoad height={495}>
          <img className='lifeGallery__img' src={`${process.env.REACT_APP_BASE_URL}/${value?.image}`}
            alt="image" loading="eager" width={100} height={100}
          />
        </LazyLoad>
      </div>
    )
  })

  const trustGallery = aboutGallery?.trust_gallery?.map((value, i) => {
    return (
      <div className="trustGallery__block">
        <div className="trustGallery__img">
          <LazyLoad height={363}>
            <img className='trustGalleryimg' src={`${process.env.REACT_APP_BASE_URL}/${value?.image}`}
              alt="image" loading="eager" width={100} height={100} />
          </LazyLoad>
        </div>
        <div className="trustGallery__content">
          <p className="trustGallery__title">{value?.title}</p>
        </div>
      </div>
    )
  })

  return (
    <Suspense fallback={null}>
      {seoContent &&
        <>
          <SeoContent seoDetails={seoContent} alterNative={alterNative} />
          <LangSwitchUrl alterNative={alterNative} />
        </>
      }
      <div className='Aboutus__container Main__section'>
        {aboutBanner?.length > 0 &&
          <div className="Aboutus__banner">
            <Slider {...bannerSettings} className="text-center">
              {bannerData}
            </Slider>
          </div>
        }

        <div className="Aboutus__licenses">
          <div className='licenses_header'>
            <div className='content-left' dangerouslySetInnerHTML={{ __html: aboutContent?.licenses_content?.content_1 }} />
            <div className='content-right' dangerouslySetInnerHTML={{ __html: aboutContent?.licenses_content?.content_2 }} />
          </div>
          <div className='licenses_gallery'>
            <div className='licenses_gallery_slider'>
              <Slider {...licenseSettings} className="text-center">
                {licensesGallery}{licensesGallery}
              </Slider>
            </div>
          </div>
          <div className='licenses_footer'>
            <div className='content-left'>
              <div className='content_left_img'>
                {aboutContent?.licenses_content?.image &&
                  <LazyLoad height={660}>
                    <img className='contentleft_img' src={`${process.env.REACT_APP_BASE_URL}/${aboutContent?.licenses_content?.image}`}
                      alt="image" loading="eager" width={160} height={160}
                    />
                  </LazyLoad>
                }
              </div>
            </div>
            <div className='content-right'>
              <div className='content-txt' dangerouslySetInnerHTML={{ __html: aboutContent?.licenses_content?.description }} />
              <div className='content-action'>
                <div className='content-action-button' dangerouslySetInnerHTML={{ __html: aboutContent?.licenses_content?.title }} />
              </div>
            </div>
          </div>
        </div>

        <div className='Aboutus__contactar'>
          <div className='contact__block'>
            <div className='container_left'>
              <h2 dangerouslySetInnerHTML={{ __html: aboutContent?.contact_content?.content_1 }}></h2>
              <p dangerouslySetInnerHTML={{ __html: aboutContent?.contact_content?.content_2 }}></p>
            </div>
            <div className='container_right'>
              <div className='container_right_img'>
                {aboutContent?.contact_content?.image &&
                  <LazyLoad height={660}>
                    <img className='contentleft_img' src={`${process.env.REACT_APP_BASE_URL}/${aboutContent?.contact_content?.image}`}
                      alt="image" loading="eager" width={160} height={160}
                    />
                  </LazyLoad>
                }
              </div>
              <div className='container_right_content'>
                <div className='left-para'>
                  <p dangerouslySetInnerHTML={{ __html: aboutContent?.contact_content?.description }}></p>
                </div>
                <div className='right-btn'>
                  <p dangerouslySetInnerHTML={{ __html: aboutContent?.contact_content?.title }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='Aboutus__request'>
          <div className='contact__block'>
            <div className='container_left'>
              <h2 dangerouslySetInnerHTML={{ __html: aboutContent?.request_content?.content_1 }}></h2>
              <p dangerouslySetInnerHTML={{ __html: aboutContent?.request_content?.content_2 }}></p>
            </div>
            <div className='container_right'>
              <div className='container_right_img'>
                {aboutContent?.request_content?.image &&
                  <LazyLoad height={660}>
                    <img className='contentleft_img' src={`${process.env.REACT_APP_BASE_URL}/${aboutContent?.request_content?.image}`}
                      alt="image" loading="eager" width={160} height={160}
                    />
                  </LazyLoad>
                }
              </div>
            </div>
          </div>
          <div className='contact__block'>
            <div className='container_bottom_content'>
              <div className='left-para'>
                <p dangerouslySetInnerHTML={{ __html: aboutContent?.request_content?.description }}></p>
              </div>
              <div className='mit-para'>
                <p dangerouslySetInnerHTML={{ __html: aboutContent?.request_content?.description }}></p>
              </div>
              <div className='right-btn'>
                <p dangerouslySetInnerHTML={{ __html: aboutContent?.request_content?.title }}></p>
              </div>
            </div>
          </div>
        </div>

        <div className='Aboutus__register'>
          <div className='contact__block'>
            <div className='container_left'>
              <h2 dangerouslySetInnerHTML={{ __html: aboutContent?.dealer_content?.content_1 }}></h2>
              <p dangerouslySetInnerHTML={{ __html: aboutContent?.dealer_content?.content_2 }}></p>
            </div>
            <div className='container_right'>
              <div className='container_right_img'>
                {aboutContent?.dealer_content?.image &&
                  <LazyLoad height={363}>
                    <img className='contentleft_img' src={`${process.env.REACT_APP_BASE_URL}/${aboutContent?.dealer_content?.image}`}
                      alt="image" loading="eager" width={160} height={160}
                    />
                  </LazyLoad>
                }
              </div>
              <div className='container_right_content'>
                <div className='left-para'>
                  <p dangerouslySetInnerHTML={{ __html: aboutContent?.dealer_content?.description }}></p>
                </div>
                <div className='right-btn'>
                  <p dangerouslySetInnerHTML={{ __html: aboutContent?.dealer_content?.title }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='Aboutus__lifestyle'>
          <div className='Aboutus__lifestyle__blk'>
            <h2 className='Aboutus__lifestyle__title'>{t("Lifestyle")}</h2>
            <div className='Aboutus__lifestyle_gallery'>
              {lifeGallery}
            </div>
          </div>
        </div>

        <div className='Aboutus__trustgallery'>
          <div className='Aboutus__trustgallery__blk'>
            <h2 className='Aboutus__trustgallery__title' dangerouslySetInnerHTML={{ __html: aboutContent?.trust_content?.content_1 }} />
            <div className='Aboutus__trustgallery_gallery'>
              {trustGallery}
            </div>
            <div className='Aboutus__trustgallery__content'>
              <p dangerouslySetInnerHTML={{ __html: aboutContent?.trust_content?.description }} />
            </div>
            <div className='Aboutus__trustgallery__action'>
              <p dangerouslySetInnerHTML={{ __html: aboutContent?.trust_content?.title }} />
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}


export default Aboutus
