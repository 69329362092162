import "../BrandBlockFiveBanner/FiveBanner.scss";

const FiveBanner = (data) => {

    function removeTags(input) {
        return input?.replace(/<[^>]*>/g, '');
    }

    return (
        <>
            <div className="oh-my-pop-brand-content">
                <h2 className="Brand-title" dangerouslySetInnerHTML={{ __html: data?.fiveBannerContent?.title }}></h2>
                <p className="Brand-desc" dangerouslySetInnerHTML={{ __html: data?.fiveBannerContent?.content }}></p>
            </div>
            <div className="BrandBlockFiveBanner">
                <div className="FiveBannerTwo">
                    <div className="ImageWrapper">
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/${data?.FiveBannerImg?.[0]?.image}`}
                            alt={`${data?.FiveBannerImg?.[0]?.image_alt_tag}`}
                            className="five-img"
                            title={`${data?.FiveBannerImg?.[0]?.image_title_tag}`}
                        />
                        <div className="BrandImgContent">
                            <h2 className="Gallery__title" title={removeTags(data?.FiveBannerImg?.[0]?.title)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[0]?.title }}></h2>
                            <p className="img-desc" title={removeTags(data?.FiveBannerImg?.[0]?.content)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[0]?.content }}></p>
                        </div>
                    </div>
                    <div className="ImageWrapper">
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/${data?.FiveBannerImg?.[1]?.image}`}
                            alt={`${data?.FiveBannerImg?.[1]?.image_alt_tag}`}
                            className="five-img"
                            title={`${data?.FiveBannerImg?.[1]?.image_title_tag}`}
                        />
                        <div className="BrandImgContent">
                            <h2 className="Gallery__title" title={removeTags(data?.FiveBannerImg?.[1]?.title)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[1]?.title }}></h2>
                            <p className="img-desc" title={removeTags(data?.FiveBannerImg?.[1]?.content)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[1]?.content }}></p>
                        </div>
                    </div>
                </div>
                <div className="FiveBannerFull">
                    <div className="ImageWrapper">
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/${data?.FiveBannerImg?.[2]?.image}`}
                            alt={`${data?.FiveBannerImg?.[2]?.image_alt_tag}`}
                            className="five-img"
                            title={`${data?.FiveBannerImg?.[2]?.image_title_tag}`}
                        />
                        <div className="BrandImgContent">
                            <h2 className="Gallery__title" title={removeTags(data?.FiveBannerImg?.[2]?.title)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[2]?.title }}></h2>
                            <p className="img-desc" title={removeTags(data?.FiveBannerImg?.[2]?.content)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[2]?.content }}></p>
                        </div>
                    </div>
                </div>
                <div className="FiveBannerTwo">
                    <div className="ImageWrapper">
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/${data?.FiveBannerImg?.[3]?.image}`}
                            alt={`${data?.FiveBannerImg?.[3]?.image_alt_tag}`}
                            className="five-img"
                            title={`${data?.FiveBannerImg?.[3]?.image_title_tag}`}
                        />
                        <div className="BrandImgContent">
                            <h2 className="Gallery__title" title={removeTags(data?.FiveBannerImg?.[3]?.title)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[3]?.title }}></h2>
                            <p className="img-desc" title={removeTags(data?.FiveBannerImg?.[3]?.content)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[3]?.content }}></p>
                        </div>
                    </div>
                    <div className="ImageWrapper">
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/${data?.FiveBannerImg?.[4]?.image}`}
                            alt={`${data?.FiveBannerImg?.[4]?.image_alt_tag}`}
                            className="five-img"
                            title={`${data?.FiveBannerImg?.[4]?.image_title_tag}`}
                        />
                        <div className="BrandImgContent">
                            <h2 className="Gallery__title" title={removeTags(data?.FiveBannerImg?.[4]?.title)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[4]?.title }}></h2>
                            <p className="img-desc" title={removeTags(data?.FiveBannerImg?.[4]?.content)} dangerouslySetInnerHTML={{ __html: data?.FiveBannerImg?.[4]?.content }}></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FiveBanner;