import axios from "axios";

export const getFilters = (clusterid, languageCode, user) => {
    return async (dispatch) => {
        dispatch({ type: "PLP__FILTER__DATA", payload: [] });
        dispatch({ type: "PLP__PRODUCT__TYPE", payload: [] });
        dispatch({ type: "PLP__COLORS", payload: [] });
        dispatch({ type: "PLP_BANNER__IMAGE", payload: '' })
        dispatch({ type: "PLP_TITLE", payload: '' })
        dispatch({ type: "PLP_ALTTAG", payload: '' })
        dispatch({ type: "PLP_HEADER", payload: '' })
        dispatch({ type: "PLP_SEODATAS", payload: '' })
        dispatch({ type: "PLP_META", payload: '' })
        dispatch({ type: "PLP_SUB_BRANDS", payload: '' })
        dispatch({ type: "PLP_FOOTER", payload: '' })

        let LANGUAGE = clusterid?.lg
        let SLUGNAME = clusterid?.clusterid
        let SELECTEDFILTER = clusterid?.product === "product" || clusterid?.product === "productos" ? clusterid?.Pname : clusterid?.name ? clusterid?.name : clusterid?.clusterid
        let BRANDDATA = clusterid?.product === "product" || clusterid?.clusterid === "productos" ? "" : clusterid?.name ? clusterid?.name : ""
        let PRODUCTDATA = clusterid?.product === "product" ? clusterid?.Pname : clusterid?.clusterid === "productos" ? clusterid?.name : ""
        let PRICELIST = user?.price_list ? user?.price_list : '21'
        let GROUP = user?.group ? user?.group : "general"
        let LINEARDISCOUNT = user?.linear_discount ? user?.linear_discount : ""

        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/productlistingsubcategory/${LANGUAGE}/${SLUGNAME}/${SELECTEDFILTER}?brandtype=${BRANDDATA}&producttype=${PRODUCTDATA}&price_list=${PRICELIST}&group=${GROUP}&linear_discount=${LINEARDISCOUNT}&page=1&sortOption=ztoa`
            })

            dispatch({ type: "PLP__BREADCRUMBS", payload: data?.data?.Breadcrumbs });
            dispatch({ type: "PLP__FILTER__DATA", payload: data?.data?.Sub_Category_Details?.subcat });
            dispatch({ type: "PLP__PRODUCT__TYPE", payload: data?.data?.product_unique });
            dispatch({ type: "PLP__COLORS", payload: data?.data?.Color_block });
            dispatch({ type: "PLP_BANNER__IMAGE", payload: data?.data?.product_listing_block_page_image })
            dispatch({ type: "PLP_TITLE", payload: data?.data?.Product_listing_block_contentTitle })
            dispatch({ type: "PLP_ALTTAG", payload: data?.data?.Category_block })
            dispatch({ type: "PLP_HEADER", payload: data?.data?.Product_listing_block_content })
            dispatch({ type: "PLP_META", payload: { "meta_title": data?.data?.Product_listing_block_metaTitle, "meta_keywords": data?.data?.Product_listing_block_metaKey, "meta_description": data?.data?.Product_listing_block_metaDesc } })
            dispatch({ type: "PLP_SEODATAS", payload: { ...data?.data?.meta_details, seo_tag_content: data?.data?.seo_tag_content } })
            dispatch({ type: "PLP_SUB_BRANDS", payload: data?.data?.brand_type_list })
            dispatch({ type: "PLP_FOOTER", payload: data?.data?.product_listing_block_page_description_footer })
        } catch (e) {
            console.log(e)
        }
    }
}

export const getProductListing = (clusters, brands, pType, color, user, sortMethod, clusterid, currentPage, autoLoader, plpmeta) => {
    return async (dispatch) => {

        let LANGUAGE = clusterid?.lg
        let SLUGNAME = clusterid?.clusterid
        let SELECTEDFILTER = clusterid?.name ? clusterid?.name : clusterid?.clusterid
        let BRANDDATA = clusterid?.product === "product" ? brands : clusterid?.clusterid === "productos" ? "" : clusterid?.name ? clusterid?.name : brands ? brands : ""
        let PRODUCTDATA = clusterid?.product === "product" || clusterid?.product === "productos" ? clusterid?.Pname : clusterid?.clusterid === "productos" ? clusterid?.name : pType
        let SUBCAT = clusters === undefined ? "" : clusters
        let PRICELIST = user?.price_list ? user?.price_list : '21'
        let GROUP = user?.group ? user?.group : 'general'
        let LINEARDISCOUNT = user?.linear_discount ? user?.linear_discount : ""
        let PAGECOUNT = currentPage === 1 && autoLoader > 1 ? autoLoader : currentPage

        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/product_filter/${LANGUAGE}/${SLUGNAME}/${PRODUCTDATA != "" ? PRODUCTDATA : SELECTEDFILTER}?brandtype=${BRANDDATA}&producttype=${PRODUCTDATA}&color=${color}&subcat=${SUBCAT}&price_list=${PRICELIST}&group=${GROUP}&linear_discount=${LINEARDISCOUNT}&page=${PAGECOUNT}&sortOption=${sortMethod}`
            })
            dispatch({ type: "PLP__LISTING", payload: data?.data });
        } catch (e) {
            console.log(e)
        }
    }
}


export const plpMobileFilterOpen = () => {
    return async (dispatch) => {
        dispatch({ type: "PLP__MOBILEFILTER__OPEN" });
    }
};

export const plpMobileFilterClose = () => {
    return async (dispatch) => {
        dispatch({ type: "PLP__MOBILEFILTER__CLOSE" });
    }
};