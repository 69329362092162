import axios from "axios";
import { toast } from 'react-toastify';

export const getToken = (email, password, languageCode, allCartList, toast, t) => {
    return async (dispatch) => {
        dispatch({ type: "LOADER__TRIGGER", payload: true });
        dispatch({ type: "LOGIN__API", payload: '' });
        try {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/login`,
                data: {
                    email: email,
                    password: password
                }
            })
            if (data?.data?.token) {
                localStorage.setItem('userdata', JSON.stringify(data?.data));
                dispatch({ type: "LOGIN__API", payload: data?.data });
                toast.success(`${data?.data?.email}` + " " + t("is_login_success"));
                dispatch({ type: "QUANTITY__CLEAR__STORE" });
                dispatch({ type: "LOADER__TRIGGER", payload: false });

                try {
                    const cartData = await axios({
                        method: 'get',
                        url: `${process.env.REACT_APP_BASE_API_URL}/usercartdetails/${languageCode}?user_id=${data?.data?.userid}&price_list=${data?.data?.price_list}&group=${data?.data?.group}&linear_discount=${data?.data?.linear_discount}&cart_id=${allCartList}`,
                        data: {
                            email: email,
                            password: password
                        }
                    })

                    if (cartData?.data?.cart_data?.length > 0) {
                        cartData?.data?.cart_data?.map((value, key) => {
                            const cartdate = { "cart_data": [value] };
                            let sku = value?.product_sku;
                            let qtyVal = value?.qty;
                            dispatch({ type: "QUANTITY__SET__STORE", payload: { sku, qtyVal } });
                            dispatch({ type: "CARTDATA__LIST", payload: cartdate });
                        })
                    }
                } catch (e) {
                    console.log(e)
                }

            } else {
                toast.error(data?.data?.message)
                dispatch({ type: "LOADER__TRIGGER", payload: false });
            }
        } catch (e) {
            dispatch({ type: "LOADER__TRIGGER", payload: false });
            console.log(e)
        }
    }
}

export const getTokenAdmin = (lg, userId, userEmail, toast, t) => {
    return async (dispatch) => {
        dispatch({ type: "LOGIN__API", payload: '' });

        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/loginascustomer/${lg}/${userId}/${userEmail}`,
            })

            if (data?.data?.token) {
                localStorage.setItem('userdata', JSON.stringify(data?.data));
                dispatch({ type: "LOGIN__API", payload: data?.data });
                toast.success(`${data?.data?.email}` + " " + t("is_login_success"));

                dispatch({ type: "QUANTITY__CLEAR__STORE" });
                dispatch({ type: "LOADER__TRIGGER", payload: false });
            } else {
                toast.error(data?.data?.message)
                dispatch({ type: "LOADER__TRIGGER", payload: false });
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const updateToken = (userData, languageCode) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/userdetails/${languageCode}/${userData?.userid}`,
            })

            if (data?.data) {
                const getUserData = { ...userData, group: data?.data?.account_type, linear_discount: data?.data?.linear_discount, price_list: data?.data?.price_list };
                localStorage.setItem('userdata', JSON.stringify(getUserData));
                dispatch({ type: "LOGIN__API", payload: getUserData });
            } else {
                toast.error(data?.data?.message)
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const setToken = (userData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "LOGIN__API", payload: userData });
        } catch (e) {
            console.log(e)
        }
    }
}

export const deleteToken = (navigate, languageCode, toast, t) => {
    return async (dispatch) => {
        dispatch({ type: "LOADER__TRIGGER", payload: true });
        localStorage.removeItem('userdata')
        dispatch({ type: "LOGIN__API", payload: '' });
        dispatch({ type: "CUSTOMER__INFO", payload: '' });
        dispatch({ type: "ACCOUNT__INFO", payload: '' });
        dispatch({ type: "CHANGE__PASSWORD", payload: '' });
        dispatch({ type: "MY__ORDERS", payload: '' });
        dispatch({ type: "ORDER__DETAILS", payload: '' });
        dispatch({ type: "MY__INVOICES", payload: '' });
        dispatch({ type: "MY__CATALOGS", payload: '' });
        dispatch({ type: "MY__LAUNCH", payload: '' });
        dispatch({ type: "QUANTITY__CLEAR__STORE" });
        navigate(`/${languageCode}/login`);
        toast.error(t('is_logout_success'));
        dispatch({ type: "LOADER__TRIGGER", payload: false });
    }
}

export const deleteAdminToken = () => {
    return async (dispatch) => {
        dispatch({ type: "LOADER__TRIGGER", payload: true });
        localStorage.removeItem('userdata');
        dispatch({ type: "LOGIN__API", payload: '' });
        dispatch({ type: "CUSTOMER__INFO", payload: '' });
        dispatch({ type: "ACCOUNT__INFO", payload: '' });
        dispatch({ type: "CHANGE__PASSWORD", payload: '' });
        dispatch({ type: "MY__ORDERS", payload: '' });
        dispatch({ type: "ORDER__DETAILS", payload: '' });
        dispatch({ type: "MY__INVOICES", payload: '' });
        dispatch({ type: "MY__CATALOGS", payload: '' });
        dispatch({ type: "MY__LAUNCH", payload: '' });
        dispatch({ type: "QUANTITY__CLEAR__STORE" });
        dispatch({ type: "LOADER__TRIGGER", payload: false });
    }
}

export const forgotPassword = (email, languageCode, toast) => {
    return async (dispatch) => {
        dispatch({ type: "LOADER__TRIGGER", payload: true });
        try {
            const data = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/forget_password?password_email=${email}`
            })
            if (data?.data?.message) {
                dispatch({ type: "FORGOT__PASSWORD", payload: data })
            }
            dispatch({ type: "LOADER__TRIGGER", payload: false });
        } catch (e) {
            dispatch({ type: "LOADER__TRIGGER", payload: false });
            console.log(e)
        }
    }
}

export const b2cRegister = (firstname, lastname, email, password, confirmpassword, languageCode, toast, navigate, addCustomerRegisterEvent) => {
    return async (dispatch) => {
        dispatch({ type: "LOADER__TRIGGER", payload: true });
        try {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_API_URL}/register_user`,
                data: {
                    first_name: firstname,
                    last_name: lastname,
                    password: password,
                    email: email,
                    item_name: "general",
                    confirm_password: confirmpassword,
                    language: languageCode
                }
            })

            if (data?.data?.success === "true") {
                let userData = {
                    FirstName: firstname,
                    LastName: lastname,
                    Email: email,
                    Language: languageCode,
                    RegisterType: 'Private Customer'
                }

                addCustomerRegisterEvent(userData);

                toast.success(data?.data?.message)
                navigate(`/${languageCode}/login`)
            } else {
                toast.error(data?.data?.message)
            }
            dispatch({ type: "LOADER__TRIGGER", payload: false });
        } catch (e) {
            dispatch({ type: "LOADER__TRIGGER", payload: false });
            console.log(e)
        }
    }
}

export const b2bRegister = (value, type, languageCode, toast, navigate, countryText, addShopRegisterEvent) => {
    return async (dispatch) => {
        dispatch({ type: "LOADER__TRIGGER", payload: true });
        try {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_API_URL}/register_customer`,
                data: {
                    first_name: value.firstname,
                    last_name: value.lastname,
                    tax_vat_number: value.vat,
                    telephone: value.phone,
                    billing_address: value.billing,
                    country: value.country,
                    state: value.state,
                    city: value.city,
                    postal_code: value.zipcode,
                    password: value.password,
                    email: value.email,
                    company: value.company,
                    item_name: type,
                    confirm_password: value.confirmpassword,
                    web: null,
                    language: languageCode
                }
            })
            if (data?.data?.success === "true") {
                toast.success(data?.data?.message);

                let userData = {
                    RegisterType: type,
                    FirstName: value.firstname,
                    LastName: value.lastname,
                    Email: value.email,
                    Telephone: value.phone,
                    Address: value.billing,
                    Country: countryText,
                    State: value.state,
                    City: value.city,
                    Postal: value.zipcode,
                    Company: value.company,
                    TaxNumber: value.vat,
                    Language: languageCode,
                }

                addShopRegisterEvent(userData);
                navigate(`/${languageCode}/login`)
            } else {
                toast.error(data?.data?.message)
            }
            dispatch({ type: "LOADER__TRIGGER", payload: false });
        } catch (e) {
            dispatch({ type: "LOADER__TRIGGER", payload: false });
            console.log(e)
        }
    }
}

export const getCountryList = (languageCode) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/country_list/${languageCode}`
            })
            dispatch({ type: "COUNTRYLIST__API", payload: data.data?.country_list });
        } catch (e) {
            console.log(e)
        }
    }
}

export const getRegisterContent = (type, languageCode) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/${type == "RETAILER" ? "retailerregister" : "wholesalerregister"}/${languageCode ? languageCode : "en"}`
            })
            dispatch({ type: "REGISTER__CONTENT", payload: data.data });
        } catch (e) {
            console.log(e)
        }
    }
}

export const getB2CRegisterContent = (languageCode) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                url: `${process.env.REACT_APP_BASE_API_URL}/individualregister/${languageCode ? languageCode : "en"}`,
                method: 'get'
            })
            dispatch({ type: "B2BREGISTER__CONTENT", payload: data.data });
        } catch (e) {
            console.log(e)
        }
    }
}

export const getLoginContent = (languageCode) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/loginpage_content/${languageCode}`
            })
            dispatch({ type: "LOGIN__CONTENT", payload: data?.data })
        } catch (e) {
            console.log(e)
        }
    }
}
