import { Link } from "react-router-dom";
import "../DoubleBanner/DoubleBanner.scss";

const DoubleBanner = ({ banner2, banner3 }) => {

    function removeTags(input) {
        return input?.replace(/<[^>]*>/g, '');
    }

    return (
        <>
            <div className="DoubleBannerWrapper">
                <div className="ImageWrapper">
                    <Link to={banner2?.url}>
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/${banner2?.banner_image}`}
                            alt={`${banner2?.image_alt}`}
                            className="sideBannerImage"
                            title={`${banner2?.image_title}`}
                        />
                    </Link>
                    <p className="Gallery__title" title={removeTags(banner2?.title)} dangerouslySetInnerHTML={{ __html: banner2?.title }}></p>
                </div>

                <div className="ImageWrapper">
                    <Link to={banner3?.url}>
                        <img
                            style={{ width: "100%" }}
                            alt={`${banner3?.image_alt}`}
                            src={`${process.env.REACT_APP_BASE_URL}/${banner3?.banner_image}`}
                            className="sideBannerImage"
                            title={banner3?.image_title}
                        />
                    </Link>
                    <p className="Gallery__title" title={removeTags(banner3?.title)} dangerouslySetInnerHTML={{ __html: banner3?.title }}></p>
                </div>
            </div >
        </>
    );
}

export default DoubleBanner;