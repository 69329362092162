import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import "./ResetPassword.scss"
import { Formik } from "formik";
import { useFormik } from "formik";
import { resetPasswords } from '../../redux/features/MyAccount/Action';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { lg, tokan } = useParams();
    
  const languageCode = useSelector(
    (state) => state?.languageCode
  );
  
  const resetResponse = useSelector((state) => state?.resetPassData);
    
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm: "",
    },
  
    validate: (values) => {
      const errors = {};
  
      if (!values.password) {
        errors.password = t("password_blank_validation");
      } else if (values.password.length < 6) {
        errors.password = t("password_formate_validation");
      }
 
      if (!values.confirm) {
        errors.confirm = t("confirm_blank_validation");
      } else if (values.confirm.length < 6) {
        errors.confirm = t("confirm_formate_validation");
      } else if (values.password !== values.confirm) {
        errors.confirm = t("match_password_validation");
      }
  
      return errors;
    },
  
    onSubmit: (values, { resetForm }) => {
      dispatch(resetPasswords(values, tokan, navigate, languageCode, toast))
    },
  });

  return (
    <div className='password_reset Main__section'>
      <div className='password_reset_container'>
        <div className='password_reset_block'>
          <h1>{t("Reset_Password")}</h1>
          <p>{t("please_enter_new_password")}</p>
    
          <form onSubmit={formik.handleSubmit} className="password_reset_form">
            <input class="form-control" type="password" name="password" id="password" placeholder={t("reset_password_input")}  
              onChange={formik.handleChange} value={formik.values.password} autocomplete="off"
            />
            
            <p className="password_reset_error">
              {formik.errors.password && formik.touched.password && formik.errors.password}
            </p>
    
            <input class="form-control" type="password" name="confirm" id="confirm" placeholder= {t("confirm_reset_password_input")} 
              onChange={formik.handleChange} value={formik.values.confirm} autocomplete="off"
            />
  
            <p className="password_reset_error">
              {formik.errors.confirm && formik.touched.confirm && formik.errors.confirm}
            </p>
        
            <button className='password_reset_create' aria-label='submit' type='submit' disabled={recaptchaToken}>{t("update_reset_password")}</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword