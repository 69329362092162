import React, { Suspense } from 'react'
import { Link } from "react-router-dom";
import './CharacterSliderBlock.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyLoad from "react-lazyload";
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

function CharacterSliderBlock(data) {

  function removeTags(input) {
    return input.replace(/<[^>]*>/g, '');
  }

  const languageCode = localStorage.getItem('languageCode');
  const charLinks = data?.charactersLink === 'Home' ? 'licenses' : data?.parentSlug;

  const sliderData = data?.charactersData?.map((item, index) => {
    return (
      <Link to={`${data.isThisHome === true ? `licenses/${item?.slugname}` : item?.url}`} className='Character__slider__item' key={index}>
        <div className="Slider__image">
          <LazyLoad height={525}>
            <img className="Character__image" src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`} title={item?.image_title_tag?.toUpperCase()} alt={item?.image_alt_tag?.toUpperCase()} width={190} height={512} />
          </LazyLoad>
        </div>
        <div className='Slider__content'>
          <span className='Character__title text-uppercase' title={item?.image_title_tag?.toUpperCase()} style={{ color: data.isCategory && "black", fontWeight: "bolder" }} dangerouslySetInnerHTML={{ __html: item?.title }}></span>
        </div>
      </Link>
    );
  });

  const CustomRightArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronRight />
    </div>
  );

  const CustomLeftArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronLeft />
    </div>
  );

  const settings = {
    infinite: data?.charactersData?.length > 7 ? true : false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: <CustomLeftArrow className="slick-prev" />,
    nextArrow: <CustomRightArrow className="slick-next" />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 685,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      }
    ],
  };

  return (
    <Suspense fallback={null}>
      <div className='Character__section sec__container mb2'>
        <div className="Character__content">
          <div className='Character__title' style={{ color: data.isCategory && "black", fontWeight: "bolder" }}>
            <span dangerouslySetInnerHTML={{ __html: data?.charactersContent?.title }}></span>
          </div>
          <div className='Character__text' dangerouslySetInnerHTML={{ __html: data?.charactersContent?.content }} />
        </div>

        {data?.charactersData?.length > 0 ? <div className="Character__slider">
          <Slider {...settings} className="text-center">
            {sliderData}
          </Slider>
        </div> :
          <div className='imagesUploadSoon'>
            Character Images Will Upload Soon
          </div>}

      </div>
    </Suspense>
  )
}

export default CharacterSliderBlock;