import React, { Suspense } from 'react';
import SeoContent from '../../../Components/SeoContent/SeoContent';
import LangSwitchUrl from '../../../Components/SeoContent/LangSwitchUrl';
import Slider from 'react-slick';
import BrandBlock from '../BrandBlock/BrandBlock';
import FullBannerwithConent from '../../CategoryPage/FullWithBanner/FullBannerwithConent';
import BestSeller from '../../Home/BestSeller/BestSeller';
import DoubleBanner from '../DoubleBanner/DoubleBanner';
import CategoryContent from '../../CategoryPage/CategoryContent/CategoryContent';
import BrandSupplier from '../../CategoryPage/BrandSupplier/BrandSupplier';
import { Link } from 'react-router-dom';
import "../Brands/Brands.scss";
import RegisterAndDiscover from '../../Home/RegisterAndDiscover/RegisterAndDiscover';

function ForeverNinette({ seoContent, getApiData, alterNative, getBrandPageData, bannerContent, settings, params }) {

    const firstBanner = getApiData?.banner_1?.[0];
    const secondBanner = getApiData?.banner_2?.[0];
    const ThirdBanner = getApiData?.banner_3?.[0];
    const FourthBanner = getApiData?.banner_4?.[0];

    return (
        <>
            <Suspense fallback={null}>
                {seoContent &&
                    <>
                        <SeoContent seoContent={getApiData?.seo_tag_content} seoDetails={seoContent} alterNative={alterNative} />
                        <LangSwitchUrl alterNative={alterNative} />
                    </>
                }

                <div className='Brand__container Main__section'>

                    {getApiData?.main_banner?.length > 0 ? <div className='categorypage_slider_wrapper'>
                        <Slider {...settings} className="text-center categorypage_slider">
                            {bannerContent}
                        </Slider>
                    </div> :
                        <div className='imagesUploadSoon'>
                            Images Will Upload Soon
                        </div>
                    }

                    <BrandBlock
                        BrandBlockData={getApiData?.brand_block_content}
                        BrandBlockImage={getApiData?.brand_block_image}
                    />

                    {getApiData?.banner_1?.length > 0 ? <div className='sec__container mb2'>
                        <FullBannerwithConent
                            bannerContent={getApiData?.event_block_1}
                            bannerData={firstBanner}
                            contentData='contentBanner'
                        />
                    </div> :
                        <div className='imagesUploadSoon'>
                            Banner Images Will Upload Soon
                        </div>}

                    <BestSeller
                        sellerContent={getApiData?.best_selling_product_block_content}
                        sellerData={getApiData?.best_selling_product_block}
                        isBrand={true}
                    />

                    {getApiData?.banner_2?.length && getApiData?.banner_3?.length > 0 ? <DoubleBanner
                        banner2={secondBanner}
                        banner3={ThirdBanner}
                    /> :
                        <div className='imagesUploadSoon'>
                            Banner Images Will Upload Soon
                        </div>}

                    {getApiData?.banner_4?.length > 0 ? <div className='banner4_content_head'>
                        <div className='banner4_content'>
                            <div className='banner4_content_title' dangerouslySetInnerHTML={{ __html: FourthBanner?.title }} ></div>
                            <div dangerouslySetInnerHTML={{ __html: FourthBanner?.description }} ></div>
                        </div>

                        <div style={{ width: "100%", paddingTop: "1rem" }}>
                            <Link to={FourthBanner?.url}>
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}/${FourthBanner?.banner_image}`}
                                    alt={`${FourthBanner?.image_alt}`}
                                    className=""
                                    title={FourthBanner?.image_title}
                                    width="100%"
                                />
                            </Link>
                        </div>
                    </div> :
                        <div className='imagesUploadSoon'>
                            Banner Images Will Upload Soon
                        </div>
                    }


                    <div className='sec__container mb2'>
                        <RegisterAndDiscover
                            advantage={getApiData?.advantage_block_content}
                            identifier={getApiData?.identifier_block}
                            isTitleShow={true}
                        />

                        <CategoryContent
                            contentLeft={getApiData?.content_block?.content_block_1}
                            contentRight={getApiData?.content_block?.content_block_2}
                        />
                    </div>

                    <BrandSupplier
                        pageDetails={getApiData?.brand_list}
                        pageForm="forever-ninette"
                    />
                </div>
            </Suspense >

        </>
    )
}
export default ForeverNinette;