import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLaunch } from '../../../redux/features/MyAccount/Action'
import './Launch.scss'

function Launch() {
  const dispatch = useDispatch()
  const mylaunch = useSelector(state => state?.mylaunch)

  useEffect(() => {
    // if(mylaunch?.release_content?.content) return
    dispatch(getLaunch())
  }, [])
  return (
    <div className='launch'>
      <div className='launch__container'
        dangerouslySetInnerHTML={{ __html: mylaunch?.release_content?.content }}
      ></div>
    </div>
  )
}

export default Launch