import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import BaseConfig from './StoreConfig/BaseConfig';
import './i18n/i18n';
import 'react-toastify/dist/ReactToastify.css';

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-PKG2X4F'
}
 
TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
  
root.render(
    <BaseConfig />
);

reportWebVitals();
