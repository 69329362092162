import React, { Suspense } from 'react'
import './BestSeller.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
const ProductGrid = React.lazy(() => import('../../../Components/ProductGrid/ProductGrid'));
const ProductGridSkeleton = React.lazy(() => import('../../../Components/ProductGrid/ProductSkeleton/ProductGridSkeleton'));

function BestSeller(data) {

  const productDtat = data?.sellerData?.map((value, i) => {
    return (
      <ProductGrid productData={value} key={i} productKey={i} />
    )
  });

  const CustomRightArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronRight />
    </div>
  );

  const CustomLeftArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronLeft />
    </div>
  );

  const settings = {
    infinite: data?.sellerData?.length > 4 ? true : false,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: <CustomLeftArrow className="slick-prev" />,
    nextArrow: <CustomRightArrow className="slick-next" />,
    responsive: [
      {
        breakpoint: 1610,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };

  return (
    <Suspense fallback={null}>
      {data?.sellerData?.length >= 0 && (
        <div className='BestSeller__section BestSeller__block'>
          {data?.sellerContent && (
            <div className="BestSeller__content mb2">
              <div className='BestSeller__title' style={{ color: data.isBrand && "black", fontWeight: "bold" }}>
                <span dangerouslySetInnerHTML={{ __html: data?.sellerContent?.title }}></span>
              </div>
              <div className='BestSeller__text' dangerouslySetInnerHTML={{ __html: data?.sellerContent?.content }} />
            </div>
          )}
          {data?.sellerData?.length > 0 ? <div className="BestSeller__products">
            {productDtat
              ? (
                <Slider {...settings} className="text-center">
                  {productDtat}
                </Slider>
              )
              : (
                <div className='products__skeleton'>
                  <ProductGridSkeleton />
                  <ProductGridSkeleton />
                  <ProductGridSkeleton />
                  <ProductGridSkeleton />
                </div>
              )
            }
          </div> :
            <div className='imagesUploadSoon'>
              Best Selling Products Will Upload Soon
            </div>
          }
        </div>
      )}
    </Suspense>
  )
}

export default BestSeller;