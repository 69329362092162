import React, { Suspense } from 'react'
import './ProductCategories.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

function ProductCategories(data) {
  const languageCode = localStorage.getItem('languageCode');
  const charLinks = data?.cactegoriesLink === 'Home' ? 'licenses' : data?.cactegoriesLink;

  function removeTags(input) {
    return input?.replace(/<[^>]*>/g, '');
  }

  const sliderData = data?.Data?.map((item, index) => {
    return (
      <div className='ProductCat__slider__item' key={index}>
        <Link to={`${data.isThisHome === true ? `licenses/product/${item?.slugname}` : item?.url}`} >
          <div className="Slider__image">
            <LazyLoad height={300}>
              <img className="ProductCat__image" src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`} title={removeTags(item?.image_title_tag?.toUpperCase())} alt={removeTags(item?.image_alt_tag?.toUpperCase())} width={300} height={270} />
            </LazyLoad>
          </div>
          <div className='Slider__content'>
            <span className='ProductCat__title text-uppercase' title={removeTags(item?.image_title_tag?.toUpperCase())} dangerouslySetInnerHTML={{ __html: item?.title }}></span>
          </div>
        </Link>
      </div>
    );
  });

  const CustomRightArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronRight />
    </div>
  );

  const CustomLeftArrow = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
      <BsChevronLeft />
    </div>
  );

  const settings = {
    infinite: data?.Data?.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomLeftArrow className="slick-prev" />,
    nextArrow: <CustomRightArrow className="slick-next" />,
    responsive: [
      {
        breakpoint: 1690,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };

  return (
    <Suspense fallback={null}>
      <div className='ProductCat__section ProductCat__block'>
        <div className="ProductCat__content mb-5">
          <div className='ProductCat__title'>
            <span style={{ color: data.isBrand && "black", fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: data?.Content?.title }}></span>
          </div>

          <div className='ProductCat__text' dangerouslySetInnerHTML={{ __html: data?.Content?.content }} />
        </div>

        {data?.Data?.length > 0 ? < div className="ProductCat__slider">
          <Slider {...settings} className="text-center">
            {sliderData}
          </Slider>
        </div> :
          <div className='imagesUploadSoon'>
            Product type Images Will Upload Soon
          </div>
        }
      </div>
    </Suspense >
  )
}

export default ProductCategories;