import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./Reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "persist-key",
  storage,
  blacklist: [
    'sideNav',
    'sideNavMainMenu',
    'sideNavBrandMenu',
    'sideNavProductMenu',
    'plpMobileFilter',
    'plpfilterdata',
    'plpproducttype',
    'plpcolors',
    'plplisting',
    'plpbannerimage',
    'plpheader',
    'plpmeta',
    'plptitle',
    'plpAltTag',
    'contactUsData',
    'cmsPageData',
    'addressList',
    'forgotpassword',
    'addressStatus',
    'aboutBannerData',
    'aboutContentData',
    'aboutGalleryData'
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(Store);

export { persistor };