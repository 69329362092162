import React, { useState } from 'react'
import './Payment.scss'
import MasterCard from '../../../assects/images/Payment/MasterCard.svg'
import Paypal from '../../../assects/images/Payment/Paypal.svg'
import Visa from '../../../assects/images/Payment/Visa.svg'
import VisaElectron from '../../../assects/images/Payment/VisaElectron.svg'
import PaypalMethod from '../PayPalMethod/PayPalMethod'
import { useSelector } from 'react-redux'
import RedsysMethod from './RedsysMethod/RedsysMethod'

function Payment({setCurrentPayment, currentPayment, totalPrice, PlaceOrderNav}) {
  const selectPayment = (e) => {
    setCurrentPayment(e.target.value)
  }
  const paymentselect = useSelector(state => state?.paymentselect)

  return (
    <div className='payment'>
      <label className='payment__option payment_option1'>
        <input type='radio' name="payment" value="redsys" onChange={e => selectPayment(e)} />
        <span>
          <img className={`${currentPayment === "redsys" && "payment__selected"}`} src={Visa} alt="" />
          <img className={`${currentPayment === "redsys" && "payment__selected"}`} src={VisaElectron} alt="" />
          <img className={`${currentPayment === "redsys" && "payment__selected"}`} src={MasterCard} alt="" />
        </span>
      </label>
      <label className='payment__option payment__option2'>
        <input type='radio' name="payment" value="paypal" onChange={e => selectPayment(e)} />
        <span>
          <img className={`${currentPayment === "paypal" && "payment__selected"}`} src={Paypal} alt="" />
        </span>
      </label>
      {currentPayment==="paypal" && paymentselect?.payment_method === 'paypal' && <PaypalMethod totalPrice={totalPrice} PlaceOrderNav={PlaceOrderNav} />}
      {currentPayment==="redsys" && paymentselect?.payment_method === 'redsys' && <RedsysMethod PlaceOrderNav={PlaceOrderNav} />}
    </div>
  )
}

export default Payment