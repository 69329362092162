import React, { Suspense, useEffect } from 'react';
import './SubMenu.scss';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Brand from '../../../Pages/Home/Brand/Brand';
import Skeleton from 'react-loading-skeleton';
import { getHomeMainBanner, getHomePage } from '../../../redux/features/Home/Action';

function SubMenu(data) {

  const dispatch = useDispatch();

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const getBrandData = useSelector(
    (state) => state?.homePageData?.brand_block
  );

  const getUserData = useSelector(
    (state) => state?.userdata
  );

  const getHomePageData = useSelector(
    (state) => state?.homePageData
  );

  useEffect(() => {
    if (getHomePageData?.length === 0) {
      dispatch(getHomeMainBanner());
      dispatch(getHomePage(getUserData, languageCode));
    }
  }, [languageCode, getUserData]);

  const menuData = data?.menuData;
  const slugName = data?.subMenu?.subMenuData?.category_details?.category_slugname;

  // check whther hovering element in the slugarray
  const handleMouseEnter = (e, name) => {
    const slugArray = ["HARRY POTTER", "MARVEL", "DISNEY", "WARNER"];
    if (slugArray.includes(name)) {
      e.target.style.pointerEvents = "all";
    } else {
      e.target.style.pointerEvents = "none";
    }
  };

  const submenuData = data?.subMenu?.subMenuData?.category_details.sub_menu?.map((value, i) => {
    const subcatMenu = value.brandtype?.map((subValue, k) => {
      return (
        <React.Fragment key={k}>
          <li className='Submenu__link' key={k}>
            <Link to={`/${languageCode}/${slugName}/${subValue?.slug_name}`} title={subValue?.brandname}>
              {subValue?.brandname}
            </Link>
          </li>
        </React.Fragment>
      )
    })

    return (
      <React.Fragment key={i}>
        <ul className='Submenu__list__block' key={i}>
          <li className='Submenu__list__head Submenu__link' onMouseEnter={(e) => handleMouseEnter(e, value?.subcatname)}>
            <Link to={`/${languageCode}/category/${`licenses`}/${value?.subcatslugname}`} title={value?.subcatname}>
              {/* <Link to={`/${languageCode}/category/${data?.menuData}/${value?.subcatslugname}`} title={value?.subcatname}> */}
              {value?.subcatname}
            </Link>
          </li>
          {subcatMenu}
        </ul>
      </React.Fragment>
    )
  })

  const subcatContent = data?.subMenu?.subMenuData?.category_details.product_type?.map((value, j) => {
    return (
      <React.Fragment key={j}>
        <li className='Submenu__link' kay={j}>
          <Link to={`/${languageCode}/${slugName}/product/${value?.slug_name}`} title={value?.productname}>
            {value?.productname}
          </Link>
        </li>
      </React.Fragment>
    )
  })

  return (
    <Suspense fallback={null}>
      <ul className="dropdown-menu megamenu Submenu__section">
        <li className='Submenu__block'>
          {menuData === 'brands'
            ? (
              <div className="Submenu__row">
                {getBrandData
                  ? (<Brand brandData={getBrandData} dataFrom="Header" />)
                  : (
                    <div className="Brand__section">
                      <div className="Brand__Block">
                        <div className="Brand__slider">
                          <div className="Brand__slider__block">
                            <div className="slider__block__col ourbrands">
                              <Skeleton variant="rectangular" animation="wave" width="100%" height={200} />
                            </div>
                            <div className="slider__block__col ourbrands">
                              <Skeleton variant="rectangular" animation="wave" width="100%" height={200} />
                            </div>
                            <div className="slider__block__col ourbrands">
                              <Skeleton variant="rectangular" animation="wave" width="100%" height={200} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            )
            : (
              <div className="Submenu__row">
                {data?.subMenu?.subMenuData?.category_details.sub_menu
                  ? (
                    <>
                      <div className='Submenu__list'>
                        {submenuData}
                      </div>
                      <div className='Submenu__products'>
                        <div className='Submenu__products__title'>Products</div>
                        <div className='Submenu__products__content'>
                          <ul className="Submenu__products__menu">
                            {subcatContent}
                          </ul>
                        </div>
                      </div>
                    </>
                  )
                  : (
                    <>
                      <div className="Submenu__list">
                        <ul className="Submenu__list__block">
                          <li className="Submenu__list__head Submenu__link" key='1'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='2'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='3'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='4'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='5'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                        </ul>
                        <ul className="Submenu__list__block">
                          <li className="Submenu__list__head Submenu__link" key='6'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='7'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='8'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key="9">
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key="10">
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                        </ul>
                        <ul className="Submenu__list__block">
                          <li className="Submenu__list__head Submenu__link" key='1'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='2'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='3'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='4'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='5'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                        </ul>
                        <ul className="Submenu__list__block">
                          <li className="Submenu__list__head Submenu__link" key='6'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='7'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='8'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key="9">
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key="10">
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                        </ul>
                        <ul className="Submenu__list__block">
                          <li className="Submenu__list__head Submenu__link" key='1'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='2'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='3'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='4'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                          <li className="Submenu__list__head Submenu__link" key='5'>
                            <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                          </li>
                        </ul>
                      </div>
                      <div className="Submenu__products">
                        <div className="Submenu__products__title">Products</div>
                        <div className="Submenu__products__content">
                          <ul className="Submenu__products__menu">
                            <li className="Submenu__link" key='1'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='2'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='3'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='4'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='5'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='6'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='7'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='8'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='9'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='10'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='11'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='12'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='13'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='14'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                            <li className="Submenu__link" key='15'>
                              <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )
                }
              </div>
            )
          }

        </li>
      </ul>
    </Suspense>
  )
}

export default SubMenu;