import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import './Brand.scss';
import LazyLoad from "react-lazyload";

function Brand(data) {
  const languageCode = localStorage.getItem('languageCode');

  return (
    <Suspense fallback={null}>
      <div className='Brand__section'>
        <div className='Brand__Block'>
          <div className="Brand__content mb2">
            <div className='Brand__title'>
              <span dangerouslySetInnerHTML={{ __html: data?.brandContent?.tittle }}></span>
            </div>
            <div className='Brand__text' dangerouslySetInnerHTML={{ __html: data?.brandContent?.content }} />
          </div>
          <div className="Brand__slider">
            { data?.dataFrom === "Header"
              ? (
                <div className="Brand__slider__block">
                  {data?.brandData?.map((data, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="slider__block__col ourbrands">
                          <Link to={`/${languageCode}/brand/${data?.slugname}`} title={data?.title} >
                            <div className="brand__parent">
                              <img src={`${process.env.REACT_APP_BASE_URL}/${data?.image}`} alt={data?.page_title} title={data?.title} width={528} height={528}/>
                              <div className="brand__child">
                                <p dangerouslySetInnerHTML={{__html: data?.description,}}></p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </React.Fragment> 
                    );
                  })}
                </div>
                )
              : (
                  <div className="Brand__slider__block">
                    {data?.brandData?.map((data, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="slider__block__col ourbrands">
                            <Link to={`/${languageCode}/brand/${data?.slugname}`} title={data?.title} >
                              <div className="brand__parent">
                                <LazyLoad offset={100}>
                                  <img src={`${process.env.REACT_APP_BASE_URL}/${data?.image}`} alt={data?.page_title} title={data?.title} />
                                </LazyLoad>
                                <div className="brand__child">
                                  <p dangerouslySetInnerHTML={{__html: data?.description,}}></p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </React.Fragment> 
                      );
                    })}
                  </div>
                )
            }
            
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default Brand
