import React from 'react';
import './Placeholder.scss';
import sklogo from './../../assects/logoplaceholder.svg';

function Placeholder() {
  return (
    <div className="placeholderImage">
      <div className='placeholderImage__img'>
          <img src={sklogo} alt='sklogo' width={22} height={22} />
      </div>
    </div>
  )
}

export default Placeholder