import React, { useEffect, useState } from 'react';
import Loader from '../../Components/Loader/Loader';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdminToken, getTokenAdmin } from '../../redux/features/Register/Action';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function AdminUserLogin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { lg, userId, userEmail }     = useParams();
    const [loader, setLoader] = useState(false);

    const userdata = useSelector(
        (state) => state?.userdata
    );

    useEffect(() => {
        setLoader(true);
        dispatch(deleteAdminToken());
        dispatch({ type: "CURRENT__SHIPPING", payload: '' });
        dispatch({ type: "CURRENT__BILLING", payload: '' });
        dispatch({ type: "CLEAR_CART" });
        dispatch(getTokenAdmin(lg, userId, userEmail, toast, t))
    }, [lg, userId, userEmail]);

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('userdata'));

        if(user === null) return

        if(user?.token){
            navigate(`/${lg}/myaccount`)
        }
      }, [userdata])


    return (
        <div className='Main__section'>
            {loader ? <Loader /> : <></> }
        </div>
    )
}

export default AdminUserLogin
