import React, { useEffect, useRef } from 'react'
import EmptyCart from './EmptyCart/EmptyCart'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import './CartListing.scss';
import { Link, useNavigate } from 'react-router-dom';
import { IoTrashOutline } from 'react-icons/io5';
import QuantityBox from '../../../Components/QuantityBox/QuantityBox';
import { addToCartAction, clearCartList, removeFromCart } from '../../../redux/features/QuantityBox/Action';
import { toast } from 'react-toastify';
import updateFile from '../../../assects/images/update.svg'
import { getCustomerInfo } from '../../../redux/features/MyAccount/Action';

function CartListing({ setActiveStep }) {
  const { t, i18n } = useTranslation();
  const dispatch    = useDispatch();
  const navigate    = useNavigate();
  const flyCartRef  = useRef([]);

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const cartDataList = useSelector(
    (state) => state?.cartData
  )

  // get User Data
  const userData = useSelector(
    (state) => state?.userdata
  )

  // Get User Info
  const customerinfo = useSelector(
    (state) => state?.customerinfo
  )

  const CustomerGroup = userData?.group ? userData?.group : "general";
  const linearDiscount = userData?.linear_discount ? userData?.linear_discount : 0;

  const quantityStore = useSelector(
    (state) => state?.quantityStore
  )

  const clearAllCartList = Object.keys(cartDataList)?.map(
    (e) => cartDataList[e]?.cart_id
  );

  const totalPrice = Object?.keys(cartDataList)?.reduce(
    (acc, item) => acc + cartDataList[item]?.qty * cartDataList[item]?.price,
    0
  ); 

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if(user?.userid && languageCode) {
      dispatch(getCustomerInfo(user?.userid, languageCode))
    }
    // Cleanup function...
    return () => {};
  }, [localStorage.getItem('userdata'), languageCode])

  const addToCartApi = ((product, minQty, keyValue, prodImg)=> {
    const productQty = quantityStore[product?.product_sku] ? quantityStore[product?.product_sku] : minQty;
    dispatch(addToCartAction(product, languageCode, productQty, linearDiscount, CustomerGroup, userData, toast, prodImg, flyCartRef.current[keyValue], true));
  })

  if (Object.keys(cartDataList)?.length === 0) {
    return (
      <EmptyCart />
    );
  }

  // price formate
  const priceFormate = (lang, value) => {
    return (
      <>
          { lang === "es" ? (
          <>
            {Number(value).toLocaleString("es-ES", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : lang === "fr" ? (
          <>
            {Number(value).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : (
          <> {Number(value).toFixed(2)}</>
        )}
        <span className="currency">€</span>
      </>
    );
  };

  const CartSummeryNavs = () => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    setActiveStep(1)
    if(user?.userid) {
      navigate(`/${languageCode}/checkout/addressinfo`);
    } else {
      navigate(`/${languageCode}/checkout/register`);
    }
  }

  const preSaleProductCount = Object.keys(cartDataList)?.filter((item, kay) => {
      return cartDataList[item]?.pre_sale == 1;
  })

  const normalProductCount = Object.keys(cartDataList)?.filter((item, kay) => {
    return cartDataList[item]?.pre_sale == 0;
  })


  return (
    <div className='CartListing__warp'>
      {(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") && 
        <div className='CartListing__customer__details'>
            <div className='customer__details_name'>
                <span className='myaccount__label'>{t("customer")}:</span>&nbsp;
                <span className='myaccount__value'>{customerinfo?.customer}</span>
            </div>

            <div className='customer__data__id'>
                <span className='myaccount__label'>{t("customer_id")}:</span>&nbsp;
                <span className='myaccount__value'>{customerinfo?.erp_customer_id}</span>
            </div>
        </div>
      }
      
      <div className='CartListing__title'>
          <h1>{t("shopping_cart")}</h1>
      </div>
      <div className='CartListing__section'>
        <div className='CartListing__list'>
          <div className='CartTable'>
            <div className='CartTable__head'>
              <div className='CartTable__head__title CartTable__prod'>
                <span>{t("product_name")}</span>
              </div>

              <div className='CartTable__head__title CartTable__price'>
                <span>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }</span>
              </div>

              <div className='CartTable__head__title CartTable__discount'>
                <span>{t("discount")}</span>
              </div>

              <div className='CartTable__head__title CartTable__price_discount'>
                <span>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }</span>
              </div>

              <div className='CartTable__head__title CartTable__client'>
                <span>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }</span>
              </div>

              <div className='CartTable__head__title CartTable__quan'>
                <span>{t("quantity")}</span>
              </div>

              <div className='CartTable__head__title CartTable__subtotal'>
                <span>{t("sub_total")}</span>
              </div>

              <div className='CartTable__head__title CartTable__action'>
                <span>{t("action")}</span>
              </div>
            </div>

            { normalProductCount?.length > 0 && 
              <div className='CartTable__row '>
                <div className='immediate_service'>
                  <span className='label'>{t("service_immediate_service")}</span>
                </div>
              </div>
            }
            

            {
              Object.keys(cartDataList)?.filter(item => cartDataList[item]?.pre_sale == 0)?.map((value, j) => {
                // min order quantity
                const minQty = CustomerGroup === "retailer" 
                  ? cartDataList[value]?.min_qty_retailer 
                  : CustomerGroup === "wholesaler"
                    ? cartDataList[value]?.min_qty_wholesaler 
                    : 1;

                // max order quantity
                const maxQty = (CustomerGroup === "retailer" || CustomerGroup === "wholesaler") 
                  ? cartDataList[value]?.b2b_qty 
                  : cartDataList[value]?.b2c_qty; 
                
                const subTotals= cartDataList[value]?.qty * cartDataList[value]?.price;

                return (
                  <React.Fragment key={j}>
                    <div className='CartTable__row'>
                      <div className='CartTable__row_col CartTable__prod'>
                        <div className='Product__Image'>
                          <Link
                            to={`/${languageCode}/ProductDetails/${cartDataList[value]?.slug}`}
                            title={cartDataList[value]?.format}
                          >
                            <img
                              src={`${process.env.REACT_APP_BASE_PRODUCT_URL}/${cartDataList[value]?.image}`}
                              alt={cartDataList[value]?.brand}
                              title={cartDataList[value]?.format}
                              width={270}
                              height={270}
                              ref={el => (flyCartRef.current[j] = el)}
                            />
                          </Link>
                        </div>

                        <div className='Product__Details'>
                          <span className='Product__Name'>{cartDataList[value]?.format}</span>
                          <span className='Product__Sku'>
                            <span className='label'>SKU: </span> 
                            <span className='value'>{cartDataList[value]?.product_sku}</span>
                          </span>

                          
                          <div className='mobile__res'>
                            <span className='Product__price'>
                              <span className='label'>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }: </span> 
                              <span className='value'>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                            </span>

                            <span className='Product__disc'>
                              <span className='label'>{t("discount")}: </span> 
                              <span className='value'>
                                {cartDataList[value]?.discount == "undefined" ? (
                                  <>0 %</>
                                ) : (
                                  <>{cartDataList[value]?.discount} %</>
                                )}  
                              </span>
                            </span>

                            <span className='Product__client_price'>
                              <span className='label'>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }: </span>
                              <span className='value'>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                            </span>
                          </div>
                        </div>

                        <div className='product_immediate_service'>
                          <span>*</span>  {t("Immediate_service")}: { cartDataList[value]?.qty } {t("QTY")} &nbsp; ({cartDataList[value]?.pre_order_desc})
                        </div>
                      </div>

                      <div className='CartTable__row_col CartTable__price'>
                        <span>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__discount'>
                        <span>
                          {cartDataList[value]?.discount == "undefined" ? (
                            <>0 %</>
                          ) : (
                            <>{cartDataList[value]?.discount} %</>
                          )}
                        </span>
                      </div>

                      <div className='CartTable__row_col CartTable__price_discount'>
                        <span><label>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }:&nbsp;</label>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                        <span><label>{t("discount")}:&nbsp;</label>
                          {cartDataList[value]?.discount == "undefined" ? (
                            <>0 %</>
                          ) : (
                            <>{cartDataList[value]?.discount} %</>
                          )}
                        </span>
                        <span className='CartTable__client_price'>
                          <label>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }:&nbsp;</label>
                          {priceFormate(languageCode, cartDataList[value]?.price)}
                        </span>
                      </div>

                      <div className='CartTable__row_col CartTable__client'>
                        <span>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__quan'>
                        <div className='CartQuantity'>
                          <QuantityBox CustomerGroup={CustomerGroup} sku={cartDataList[value]?.product_sku} minQty={minQty} maxQty={maxQty}/>
                        </div>
                      </div>

                      <div className='CartTable__row_col CartTable__subtotal'>
                        <span>{priceFormate(languageCode, subTotals)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__action'>
                        <span className='CartTable__action__delete' onClick={() => dispatch(removeFromCart(cartDataList[value]?.cart_id, cartDataList[value]?.product_sku, toast, t))} title={t("delete")}><IoTrashOutline /></span>
                        <span className='CartTable__action__update' onClick= {() => addToCartApi(cartDataList[value], minQty, j, `${process.env.REACT_APP_BASE_PRODUCT_URL}/${cartDataList[value]?.image}`)}>
                          <img src={updateFile} alt="update" width={31} height={31} title={t("update")}/>
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }

            { preSaleProductCount?.length > 0 && 
              <div className='CartTable__row '>
                <div className='pre_service'>
                  <span className='label'>{t("preOrder")}</span>
                </div>
              </div>
            }

            {
              Object.keys(cartDataList)?.filter(item => cartDataList[item]?.pre_sale == 1)?.map((value, j) => {
                // min order quantity
                const minQty = CustomerGroup === "retailer" 
                  ? cartDataList[value]?.min_qty_retailer 
                  : CustomerGroup === "wholesaler"
                    ? cartDataList[value]?.min_qty_wholesaler 
                    : 1;

                // max order quantity
                const maxQty = (CustomerGroup === "retailer" || CustomerGroup === "wholesaler") 
                  ? cartDataList[value]?.b2b_qty 
                  : cartDataList[value]?.b2c_qty; 
                
                const subTotals= cartDataList[value]?.qty * cartDataList[value]?.price;
                return (
                  <React.Fragment key={j}>
                    <div className='CartTable__row'>
                      <div className='CartTable__row_col CartTable__prod'>
                        <div className='Product__Image'>
                          <Link
                            to={`/${languageCode}/ProductDetails/${cartDataList[value]?.slug}`}
                            title={cartDataList[value]?.format}
                          >
                            <img
                              src={`${process.env.REACT_APP_BASE_PRODUCT_URL}/${cartDataList[value]?.image}`}
                              alt={cartDataList[value]?.brand}
                              title={cartDataList[value]?.format}
                              width={270}
                              height={270}
                              ref={el => (flyCartRef.current[j] = el)}
                            />
                          </Link>
                        </div>

                        <div className='Product__Details'>
                          <span className='Product__Name'>{cartDataList[value]?.format}</span>
                          <span className='Product__Sku'>
                            <span className='label'>SKU: </span> 
                            <span className='value'>{cartDataList[value]?.product_sku}</span>
                          </span>
                          <div className='mobile__res'>
                            <span className='Product__price'>
                              <span className='label'>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }: </span> 
                              <span className='value'>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                            </span>

                            <span className='Product__disc'>
                              <span className='label'>{t("discount")}: </span> 
                              <span className='value'>
                                {cartDataList[value]?.discount == "undefined" ? (
                                  <>0 %</>
                                ) : (
                                  <>{cartDataList[value]?.discount} %</>
                                )}  
                              </span>
                            </span>

                            <span className='Product__client_price'>
                              <span className='label'>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }: </span>
                              <span className='value'>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                            </span>
                          </div>
                        </div>

                        <div className='product_pre_service'>
                          <span>*</span>  {t("preOrder")}: { cartDataList[value]?.qty } {t("QTY")} &nbsp; ({cartDataList[value]?.pre_order_desc})
                        </div>

                      </div>

                      <div className='CartTable__row_col CartTable__price'>
                        <span>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__discount'>
                        <span>
                          {cartDataList[value]?.discount == "undefined" ? (
                            <>0 %</>
                          ) : (
                            <>{cartDataList[value]?.discount} %</>
                          )}
                        </span>
                      </div>

                      <div className='CartTable__row_col CartTable__price_discount'>
                        <span><label>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }:&nbsp;</label>{priceFormate(languageCode, cartDataList[value]?.price_before)}</span>
                        <span><label>{t("discount")}:&nbsp;</label>
                          {cartDataList[value]?.discount == "undefined" ? (
                            <>0 %</>
                          ) : (
                            <>{cartDataList[value]?.discount} %</>
                          )}
                        </span>
                        <span className='CartTable__client_price'>
                          <label>{(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) ?  <>{t("pdp_price")}</> : <>{t("price")}</> }:&nbsp;</label>
                          {priceFormate(languageCode, cartDataList[value]?.price)}
                        </span>
                      </div>

                      <div className='CartTable__row_col CartTable__client'>
                        <span>{priceFormate(languageCode, cartDataList[value]?.price)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__quan'>
                        <div className='CartQuantity'>
                          <QuantityBox CustomerGroup={CustomerGroup} sku={cartDataList[value]?.product_sku} minQty={minQty} maxQty={maxQty}/>
                        </div>
                      </div>

                      <div className='CartTable__row_col CartTable__subtotal'>
                        <span>{priceFormate(languageCode, subTotals)}</span>
                      </div>

                      <div className='CartTable__row_col CartTable__action'>
                        <span className='CartTable__action__delete' onClick={() => dispatch(removeFromCart(cartDataList[value]?.cart_id, cartDataList[value]?.product_sku, toast, t ))} title={t("delete")}><IoTrashOutline /></span>
                        <span className='CartTable__action__update' onClick= {() => addToCartApi(cartDataList[value], minQty, j, `${process.env.REACT_APP_BASE_PRODUCT_URL}/${cartDataList[value]?.image}`)}>
                          <img src={updateFile} alt="update" width={31} height={31} title={t("update")}/>
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }

            <div className='CartTable__footer'>
              <>
                <button type="button" title="Clear Shopping Cart" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" className="CartTable__footer__action">
                  {t("clear_shopping_cart")}
                </button>

                <div class="modal fade clearCartTable" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div className="clearAllBody">
                        {t("you_want_to_clear_products")}
                      </div>

                      <div class="modal-footer">
                        <button type="button" class="CartTable__modal__action" data-bs-dismiss="modal">
                          {t("no")}
                        </button>
                        
                        <button type="button" class="CartTable__modal__action"  data-bs-dismiss="modal"
                        onClick={() => dispatch(clearCartList(clearAllCartList, toast, t))}>
                          {t("yes")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <Link to={`/${languageCode}/`}>
                <button type="button" title={t("ContinueShopping")} className="CartTable__footer__action">
                  {t("ContinueShopping")}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className='CartListing__summery'>
          <div className="CartListing__summery__block">
            <div className="CartListing__summery__title">{t("in_your_cart")}</div>
            <div className='CartListing__summery__body'>
              <span className='label'>{t("sub_total")}</span>
              <span className="price">{priceFormate(languageCode, totalPrice)}</span>
            </div>
            <div className='CartListing__summery__footer'>
              <span className='label'>{t("total")}</span>
              <span className="price">{priceFormate(languageCode, totalPrice)}</span>
            </div>
          </div>

          <div className="CartListing__summery__nav">
            <button
              className="CartSummeryNav"
              title="Make an Order"
              onClick={() => CartSummeryNavs()}
            >
              {t("make_an_order")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartListing
