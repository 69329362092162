import React from 'react';
import './ProductBreadcrumbs.scss';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProductBreadcrumbs(data) {
    const languageCode = useSelector(
        (state) => state?.languageCode
    );

    const urlPath = data?.ProductDetailsData?.product_details?.brand_status === 1 ? "brands" : "licenses";

    return (
        <div className='ProductBreadcrumbs'>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    title='Go to Homepage'
                    underline="hover"
                    color="inherit"
                    to={`/${languageCode}`}
                >
                    karactermania
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    to={`/${languageCode}/${urlPath}/${data?.ProductDetailsData?.product_details?.brand_slug}`}
                >
                    {data?.ProductDetailsData?.product_details?.brand}
                </Link>
                <Typography color="text.primary">{data?.ProductDetailsData?.product_details?.meta_title}</Typography>
            </Breadcrumbs>
        </div>
    )
}

export default ProductBreadcrumbs
