import axios from "axios";

export const getFooterApi = (languageCode) => {
  return async (dispatch) => {
    try {
      const getFooterData = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_API_URL}/footer/${languageCode}`
      })

      dispatch({ type: "FOOTER__DATA", payload: getFooterData?.data?.footer });
    } catch (e) {
      console.log(e)
    }
  }
};
