import React, { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getLogoList } from '../../../redux/features/Header/Action';
import LogoIndicator from './LogoIndicator';
import './Logo.scss';

function Logo() {
  const dispatch = useDispatch();

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  // Get Logo
  const logoData = useSelector(
    (state) => state?.logoData
  );

  const getMiniSearchData = useSelector(
    (state) => state?.miniSearchData
  )

  // API Call
  useEffect(() => {
    dispatch(getLogoList());
  }, [])

  if (logoData?.image === null) {
    return <LogoIndicator />;
  }

  return (
    <Suspense fallback={null}>
      <Link className={`navbar-brand ${getMiniSearchData}`} to={`/${languageCode}`}>
        <img
          alt="Karactermania"
          src={`${process.env.REACT_APP_BASE_URL}/${logoData?.image}`}
          className="headerLogo"
          title="Karactermania"
          width={288}
          height={35}
        />
      </Link>
    </Suspense>
  )
}

export default Logo
