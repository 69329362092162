import React from 'react';
import { Helmet } from 'react-helmet';

function SeoContent(data) {

  const seoContents = data?.seoContent;

  const alterNativeurlData = data?.alterNative?.map((value, k) => {
    return (
      <link rel="alternate" hreflang={value?.lang} href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${value?.url}`} />
    )
  });

  return (
    <Helmet>
      {/* title Tag */}

      <title>{seoContents?.meta_title}</title>


      {/* Meta tag */}

      {seoContents?.language &&
        <meta name="language" content={seoContents?.language} />
      }

      {seoContents?.meta_title &&
        <meta name="title" content={seoContents?.meta_title} />
      }

      {seoContents?.meta_description &&
        <meta name="description" content={seoContents?.meta_description} />
      }

      {seoContents?.meta_keywords &&
        <meta name="keywords" content={seoContents?.meta_keywords} />
      }

      {seoContents?.robots &&
        <meta name="robots" content={seoContents?.robots} />
      }

      {seoContents?.author &&
        <meta name="author" content={seoContents?.author} />
      }

      {seoContents?.publisher &&
        <meta name="publisher" content={seoContents?.publisher} />
      }

      {/* Meta OG tag */}
      <meta property="og:site_name" content="KARACTERMANIA" />
      <meta property="og:type" content="website" />

      {seoContents?.meta_title &&
        <meta property="og:title" content={seoContents?.meta_title} />
      }

      {seoContents?.meta_description &&
        <meta property="og:description" content={seoContents?.meta_description} />
      }

      {data?.seoDetails?.image &&
        <meta name="og:image" content={`${process.env.REACT_APP_BASE_URL}/${data?.seoDetails?.image}`} />
      }

      {data?.seoDetails?.url &&
        <meta name="og:url" content={`${process.env.REACT_APP_BASE_URL}/${data?.seoDetails?.url}`} />
      }

      <meta property="og:image:width" content="265" />
      <meta property="og:image:height" content="265" />

      {/* canonical links */}
      {data?.seoDetails?.url &&
        <link rel="canonical" href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${data?.seoDetails?.url}`} />
      }

      {/* alternate links */}
      {data?.seoDetails?.url &&
        <link rel="alternate" hreflang="x-default" href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${data?.seoDetails?.url}`} />
      }

      {alterNativeurlData}
    </Helmet>
  )
}

export default SeoContent;