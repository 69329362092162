import React, { useState, useEffect } from 'react'
import './ShopRegister.scss';
import { b2bRegister, getCountryList, getRegisterContent } from '../../../redux/features/Register/Action';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useShopRegisterEvent } from '../../../GTM/useShopRegisterEvent';

function ShopRegister({ type }) {
  const [userValidation, setUserValidation] = useState({
    first: false,
    last: false,
    company: false,
    vat: false,
    phone: false,
    billing: false,
    country: false,
    state: false,
    city: false,
    zipcode: false,
    email: false,
    password: false,
    confirmpassword: false
  })
  const [userInfo, setUserInfo] = useState({
    firstname: '',
    lastname: '',
    company: '',
    website: '',
    vat: '',
    phone: '',
    billing: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
    email: '',
    password: '',
    confirmpassword: ''
  })
  const [textFirst, setTextFirst] = useState(false)
  const [textLast, setTextLast] = useState(false)
  const [textVat, setTextVat] = useState(false)
  const [terms, setTerms] = useState()
  const [countryText, setCountryText] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const { addShopRegisterEvent }= useShopRegisterEvent();

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };
  const dispatch = useDispatch();

  const countryList = useSelector((state) => state?.countryList);
  const registercontent = useSelector((state) => state?.registercontent)
  const { t, i18n } = useTranslation();

  // Getting language code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );
  
  const navigate = useNavigate()
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if(user?.userid) {
      navigate(`/${languageCode}/myaccount`)
    }
  }, [localStorage.getItem('userdata')])

  useEffect(() => {
    dispatch(getRegisterContent(type, languageCode))
    dispatch(getCountryList(languageCode))
  }, [languageCode, type])

  const createB2BUser = (e) => {
    e.preventDefault();

    setUserValidation({
      first: false,
      last: false,
      company: false,
      phone: false,
      billing: false,
      country: false,
      state: false,
      city: false,
      zipcode: false,
      email: false,
      password: false,
      confirmpassword: false
    })

    setUserInfo({
      firstname: '',
      lastname: '',
      company: '',
      website: '',
      vat: '',
      phone: '',
      billing: '',
      country: '',
      state: '',
      city: '',
      zipcode: '',
      email: '',
      password: '',
      confirmpassword: ''
    })
    setTextLast(false)
    setTextFirst(false)
    setTextVat(false)

    // EMAIL VALIDATION
    let validationCheck = e.target[11].value.match(/^(([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    if(validationCheck === null) {
      setUserValidation(prevState => ({
        ...prevState, email: validationCheck === null ? true : false
      }))
    } else {
      setUserInfo(prevState => ({ ...prevState, email:  e.target[11].value}))
    }

    setTerms(e.target[14]?.checked)

    // PASSWORD VALIDATION
    if(e.target[12].value?.length < 6) {
      setUserValidation(prevState => ({  ...prevState, password: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, password:  e.target[12].value}))
    }

    // PASSWORD, CONFIRM PASSWORD MATCHING VALIDATION
    if(e.target[12].value !== e.target[13].value && userInfo?.password !== "") {
      setUserValidation(prevState => ({ ...prevState, confirmpassword: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, confirmpassword: e.target[13].value }))
    }

    // COMPANY VALIDATION
    if(e.target[2].value?.length === 0) {
      setUserValidation(prevState => ({  ...prevState, company: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, company:  e.target[2].value}))
    }

    // PHONE NUMBER VALIDATION
    if(e.target[5].value?.length === 0) {
      setUserValidation(prevState => ({  ...prevState, phone: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, phone:  e.target[5].value}))
    }

    // BILLING ADDRESS VALIDATION
    if(e.target[6].value?.length === 0) {
      setUserValidation(prevState => ({  ...prevState, billing: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, billing:  e.target[6].value}))
    }

    // COUNTRY VALIDATION
    if(e.target[7].value === "") {
      setUserValidation(prevState => ({  ...prevState, country: true }))
    } else {
      const selectedOption = e.target[7].options[e.target[7].selectedIndex];
      const selectedText = selectedOption.text;
      setCountryText(selectedText);
      setUserInfo(prevState => ({ ...prevState, country:  e.target[7].value}))
    }

    // STATE VALIDATIONS
    if(e.target[8].value?.length === 0) {
      setUserValidation(prevState => ({  ...prevState, state: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, state:  e.target[8].value}))
    }

    // CITY VALIDATION
    if(e.target[9].value?.length === 0) {
      setUserValidation(prevState => ({  ...prevState, city: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, city:  e.target[9].value}))
    }

    // ZIPCODE VALIDATION
    if(e.target[10].value?.length === 0) {
      setUserValidation(prevState => ({  ...prevState, zipcode: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, zipcode:  e.target[10].value}))
    }
    
    // FIRSTNAME VALIDATION
    if(e.target[0].value?.length === 0) {
      setUserValidation(prevState => ({  ...prevState, first: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, firstname:  e.target[0].value}))
    }

    // LASTNAME VALIDATION
    if(e.target[1].value?.length === 0) {
      setUserValidation(prevState => ({  ...prevState, last: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, lastname:  e.target[1].value}))
    }
    // VAT VALIDATION
    if(e.target[4].value?.length === 0) {
      setUserValidation(prevState => ({  ...prevState, vat: true }))
    } else {
      setUserInfo(prevState => ({ ...prevState, vat:  e.target[4].value}))
    }

    let vat__number = e.target[4].value.match(("^[a-zA-Z0-9]*$"))
    if(vat__number === null) return setTextVat(true)
    let first__name = e.target[0].value.match(("^[a-zA-Z ]*$"))
    if(first__name === null) return setTextFirst(true)
    let last__name = e.target[1].value.match(("^[a-zA-Z ]*$"))
    if(last__name === null) return setTextLast(true)
  }
  

  useEffect(() => {
    let data = Object.values(userValidation)?.filter(obj => obj === true);
    if(data?.length === 0 && terms && !textFirst && !textLast && !textVat) {
      dispatch(b2bRegister(userInfo, type === "RETAILER" ? "retailer" : "wholesaler", languageCode, toast, navigate, countryText, addShopRegisterEvent))
    }
  }, [userInfo])


  return (
    <div className='shopregister Main__section'>
      <div className='shopregister__container'>
        <div className='shopregister__block'>
          <div className='shopregister__formContainer'>
            <h3>{type === "RETAILER" ? registercontent?.retailer_register_block?.tittle : registercontent?.wholesaler_register_block?.tittle}</h3>
            <p>{type === "RETAILER" ? registercontent?.retailer_register_block?.content : registercontent?.wholesaler_register_block?.content}</p>
            <span className='shopregister__validations'>{t("require_fields")} </span>
            <form className='shopregister__form' onSubmit={e => createB2BUser(e)}>
                <input type="text" placeholder={t("first_name_input")}/>
                {userValidation?.first && <p className='error'>{t("first_name_blank_validation")} </p>}
                {textFirst && <p className='error'>{t("first_name_formate_validation")}</p>}

                <input type="text" placeholder={t("last_name_input")}  />
                {userValidation?.last && <p className='error'>{t("last_name_blank_validation")} </p>}
                {textLast && <p className='error'>{t("last_name_formate_validation")} </p>}

                <input type="text" placeholder={t("company_input")} />
                {userValidation?.company && <p className='error'>{t("company_blank_validation")} </p>}
                
                <input type="text" placeholder={t("website")}  />

                <input type="text" placeholder={t("vat_input")}  />
                {userValidation?.vat && <p className='error'>{t("vat_blank_validation")} </p>}
                {textVat && <p className='error'> {t("vat_formate_validation")} </p>}

                <input type="number" placeholder={t("phone_input")} />
                {userValidation?.phone && <p className='error'>{t("phone_blank_validation")} </p>}

                <input type="text" placeholder={t("billing_address_input")}  />
                {userValidation?.billing && <p className='error'>{t("billing_add_blank_validation")}</p>}

                <select className="custom-select custom-select-lg mb-3">
                  <option value="">{t("select_country_input")} </option>
                  {countryList?.map(CL => <option value={CL?.country_id}>{CL?.country_name}</option>)}
                </select>
                {userValidation?.country && <p className='error'>{t("country_blank_validation")}</p>}

                <input type="text" placeholder={t("state_input")}  />
                {userValidation?.state && <p className='error'>{t("state_blank_validation")} </p>}

                <input type="text" placeholder= {t("city_input")}  />
                {userValidation?.city && <p className='error'> {t("city_blank_validation")}</p>}

                <input type="text" placeholder={t("zip_input")}  />
                {userValidation?.zipcode && <p className='error'>{t("zip_blank_validation")}</p>}
                
                <input type="text" placeholder= {t("email_address_input")}  />
                {userValidation?.email && <p className='error'>{t("email_blank_validation")} </p>}

                <input type="password" placeholder={t("password_input")}  />
                {userValidation?.password && <p className='error'>{t("password_formate_validation")} </p>}

                <input type="password" placeholder= {t("confirm_password_input")} />
                {userValidation?.confirmpassword && <p className='error'> {t("match_password_validation")} </p>}

                <label className='agree_terms'>
                  <input type="checkbox" />
                  <Link target="_blank" to={`/${languageCode}/legal-notice`}>{t("agree_terms_condition")}</Link>
                </label>

                {terms === false && <p className='error'> {t("agree_terms_validation")} </p>}

                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleRecaptchaChange} />
                <button className='shopregister__submit' aria-label='submit' type='submit' disabled={!recaptchaToken}>{t("create_account")} </button>
            </form>
          </div>

          <div className='shopregister__info'>
              <h2>{type === "RETAILER" ? registercontent?.retailer_validation_block?.tittle : registercontent?.wholesaler_validation_block?.tittle}</h2>
              <p dangerouslySetInnerHTML={{__html: type === "RETAILER" ? registercontent?.retailer_validation_block?.content : registercontent?.wholesaler_validation_block?.content}}></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopRegister