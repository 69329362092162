import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

export const usePlaceOrderEvent = props => {
  const addPlaceOrderEvent = useCallback(async placeOderData => {

    const preSaleProduct = Object.keys(placeOderData?.cartDataList)?.filter(item => placeOderData?.cartDataList[item]?.pre_sale == 1)?.map((value, j) => {
      const subTotals= placeOderData?.cartDataList[value]?.qty * placeOderData?.cartDataList[value]?.price;

      const priceFormate = (lang, value) => {
        let priceValue =  lang === "es" 
                            ? Number(value).toLocaleString("es-ES", { minimumFractionDigits: 2 }) + " €"
                            : lang === "fr"
                              ? Number(value).toLocaleString("fr-FR", { minimumFractionDigits: 2 }) + " €"
                              : Number(value).toFixed(2) + " €"
        return priceValue;
      };
      
      let listProduct ={
        sku           : placeOderData?.cartDataList[value]?.product_sku,
        name          : placeOderData?.cartDataList[value]?.format,
        slug          : placeOderData?.cartDataList[value]?.slug,
        price         : priceFormate(placeOderData?.languageCode, placeOderData?.cartDataList[value]?.price_before), 
        discount      : placeOderData?.cartDataList[value]?.discount == "undefined" ? 0+"%" : placeOderData?.cartDataList[value]?.discount+"%",
        msrp          : priceFormate(placeOderData.languageCode, placeOderData?.cartDataList[value]?.price),
        qty           : placeOderData?.cartDataList[value]?.qty,
        subTotal      : priceFormate(placeOderData.languageCode, subTotals)    
      }

      return listProduct;
    })

    const immediateProduct = Object.keys(placeOderData?.cartDataList)?.filter(item => placeOderData?.cartDataList[item]?.pre_sale == 0)?.map((value, j) => {
      const subTotals= placeOderData?.cartDataList[value]?.qty * placeOderData?.cartDataList[value]?.price;

      const priceFormate = (lang, value) => {
        let priceValue =  lang === "es" 
                            ? Number(value).toLocaleString("es-ES", { minimumFractionDigits: 2 }) + " €"
                            : lang === "fr"
                              ? Number(value).toLocaleString("fr-FR", { minimumFractionDigits: 2 }) + " €"
                              : Number(value).toFixed(2) + " €"
        return priceValue;
      };
      
      let listProduct ={
        sku           : placeOderData?.cartDataList[value]?.product_sku,
        name          : placeOderData?.cartDataList[value]?.format,
        slug          : placeOderData?.cartDataList[value]?.slug,
        price         : priceFormate(placeOderData?.languageCode, placeOderData?.cartDataList[value]?.price_before), 
        discount      : placeOderData?.cartDataList[value]?.discount == "undefined" ? 0+"%" : placeOderData?.cartDataList[value]?.discount+"%",
        msrp          : priceFormate(placeOderData.languageCode, placeOderData?.cartDataList[value]?.price),
        qty           : placeOderData?.cartDataList[value]?.qty,
        subTotal      : priceFormate(placeOderData.languageCode, subTotals)    
      }

      return listProduct;
    })

    let cartItems = {
      immediateService: immediateProduct,
      preOrder  : preSaleProduct
    };

    
    let data = {
      dataLayer: {
        event           : 'place_order',
        registerType    : placeOderData?.customerGroup,
        orderId         : placeOderData?.orderData?.message?.order_id,
        paymentType     : placeOderData?.paymentMethod, 
        userId          : placeOderData?.userData.userid,
        email           : placeOderData?.userData.email,
        priceList       : placeOderData?.userData.price_list,
        linearDiscount  : placeOderData?.userData.linear_discount,
        totalPrice      : placeOderData?.totalPrice,
        couponCode      : placeOderData?.couponCode,
        discount        : placeOderData?.discount,
        cartitems       : cartItems,
        Language        : placeOderData?.languageCode,
      }
    };

    TagManager.dataLayer(data);

    console.log('placeOderData', placeOderData?.orderData);
    console.log('GTM_EVENT place0rder',data);
  });

  return {
    addPlaceOrderEvent
  };
};
