import "../BrandBlock/BrandBlock.scss";

const BrandBlock = ({ BrandBlockData, BrandBlockImage }) => {

    const Image1 = BrandBlockImage?.[0];
    const Image2 = BrandBlockImage?.[1];

    return (
        <>
            <div className="BrandBlockWrapper">
                <div>
                    <img
                        src={`${process.env.REACT_APP_BASE_URL}/${Image1?.image}`}
                        alt={Image1?.image_alt_tag}
                        title={Image1?.image_title_tag}
                        className="bannerimg"
                    />
                </div>

                <div className="contentWrapper">
                    <div className="contentWrapperTitle" dangerouslySetInnerHTML={{ __html: BrandBlockData?.title }}></div>
                    <div dangerouslySetInnerHTML={{ __html: BrandBlockData?.content }}></div>
                </div>

                <div>
                    <img
                        src={`${process.env.REACT_APP_BASE_URL}/${Image2?.image}`}
                        alt={Image2?.image_alt_tag}
                        title={Image2?.image_title_tag}
                        className="bannerimg"
                    />
                </div>
            </div>
        </>
    );
}

export default BrandBlock;