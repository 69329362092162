import React from 'react'
import './CartSummary.scss'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function CartSummary({ activeStep, setActiveStep, currentShipping, currentBilling }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const cartDataList = useSelector(
    (state) => state?.cartData
  )

  const currentbilling = useSelector(state => state?.currentbilling)
  const currentshipping = useSelector(state => state?.currentshipping)

  const totalPrice = Object?.keys(cartDataList)?.reduce(
    (acc, item) => acc + cartDataList[item]?.qty * cartDataList[item]?.price,
    0
  );
  
  // price formate
  const priceFormate = (lang, value) => {
    return (
      <>
          { lang === "es" ? (
          <>
            {Number(value).toLocaleString("es-ES", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : lang === "fr" ? (
          <>
            {Number(value).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : (
          <> {Number(value).toFixed(2)}</>
        )}
        <span className="currency">€</span>
      </>
    );
  }; 

  const goNext = () => {
    if(!currentbilling?.address_id && !currentshipping?.address_id) {
      return toast.warning(t("plz_sel_billing_shipping_add"));
    }
    if(!currentbilling?.address_id || currentbilling?.address_id === undefined) {
      return toast.warning(t("plz_sel_billing_add"));
    }
    if(!currentshipping?.address_id || currentshipping?.address_id === undefined) {
      return toast.warning(t("plz_sel_shipping_add"));
    }
    setActiveStep(activeStep + 1)
    navigate(`/${languageCode}/checkout/ordersummery`)
  }

  return (
    <div className='CartSummary'>
      <div className="CartSummary__block">
        <div className="CartSummary__title">{t("in_your_cart")}</div>
        
        <div className='CartSummary__product'>
          {
            Object.keys(cartDataList)?.map((value, j) => {
              return (
                <React.Fragment key={j}>
                  <div className='CartSummary__Product__Block'>
                    <div className='CartSummary__Product__Image'>
                      <Link
                        to={`/${languageCode}/ProductDetails/${cartDataList[value]?.slug}`}
                        title={cartDataList[value]?.format}
                      >
                        <img
                          src={`${process.env.REACT_APP_BASE_PRODUCT_URL}/${cartDataList[value]?.image}`}
                          alt={cartDataList[value]?.brand}
                          title={cartDataList[value]?.format}
                          width={135}
                          height={135}
                        />
                      </Link>
                    </div>
                    <div className='CartSummary__Product__Details'>
                        <div className='CartSummary__Product__row'>
                          <span className='CartSummary__Product__Name'>
                            {cartDataList[value]?.format}
                          </span>
                        </div>
                        <div className='CartSummary__Product__row'>
                          <div>
                            <span className='CartSummary__Product__Qty'>
                              {t("QTY")}: {cartDataList[value]?.qty}
                            </span><br />
                            <span className='CartSummary__Product__Price'>
                              {t("price")}: {priceFormate(languageCode, cartDataList[value]?.price)}
                            </span>
                          </div>
                        </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>

        <div className='CartSummary__body'>
          <span className='label'>{t("sub_total")}</span>
          <span className="price">{priceFormate(languageCode, totalPrice)}</span>
        </div>
        
        <div className='CartSummary__footer'>
          <span className='label'>{t("total")}</span>
          <span className="price">{priceFormate(languageCode, totalPrice)}</span>
        </div>
      </div>

      <div className="CartSummary__next">
        <button onClick={goNext}>{t("Next")}</button>
      </div>
    </div>
  )
}

export default CartSummary