import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getNewSubMenuList, getSubMenuList, sideNavBrandMenu, sideNavCategoryMenu, sideNavMainMenu, sideNavProductMenu, sidenavClose, sidenavOpen } from '../../../redux/features/Header/Action';
import './MobileMenu.scss'
import { VscChromeClose } from "react-icons/vsc";
import { setToken } from '../../../redux/features/Register/Action';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { FaChevronRight, FaArrowLeft } from 'react-icons/fa';
import { getHomePage } from '../../../redux/features/Home/Action';
import Brand from '../../../Pages/Home/Brand/Brand';
import { useTranslation } from 'react-i18next';

function MobileMenu() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [menuList, setMenuList] = useState(1);

  // side nav open / colse status
  const getSideNavData = useSelector(
    (state) => state?.sideNav
  )

  // get Lang code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  // get user Data
  const getUserData = useSelector(
    (state) => state?.userdata
  )

  // get Main menu data
  const getMenuData = useSelector(
    (state) => state?.megaMenu?.mega_menu
  )

  // get Homepage menu data for brand menu
  const getHomePageData = useSelector(
    (state) => state?.homePageData
  )

  const getSubMenuData = useSelector(
    (state) => state?.subMenu
  )

  const getBrandData = useSelector(
    (state) => state?.homePageData?.brand_block
  )

  const getMainMenu = useSelector(
    (state) => state?.sideNavMainMenu
  )

  const getBrandMenu = useSelector(
    (state) => state?.sideNavBrandMenu
  )

  const getProductMenu = useSelector(
    (state) => state?.sideNavProductMenu
  )

  const getCategoryMenu = useSelector(
    (state) => state?.sideNavcategoryMenu
  )

  useEffect(() => {
    if(getHomePageData?.length === 0) {
      dispatch(getHomePage(getUserData, languageCode));
    }
  },[languageCode, getUserData])

  useEffect(() => {
    let userDatas = JSON.parse(localStorage.getItem('userdata'));

    if(!getUserData?.success && userDatas?.success) {
      dispatch(setToken(userDatas));
    }

    // dispatch(getSubMenuList(getMenuData, languageCode, getUserData?.group));
  }, [getMenuData, getUserData])

  useEffect(()=> {
    if(menuList === 2) {
      if(getSubMenuData[getMainMenu?.slug_name]) {

        dispatch(sideNavBrandMenu(getSubMenuData[getMainMenu?.slug_name]?.subMenuData?.category_details?.sub_menu));
        dispatch(sideNavProductMenu(getSubMenuData[getMainMenu?.slug_name]?.subMenuData?.category_details?.product_type));
      }
    }
  }, [getSubMenuData, getMainMenu]);

  const mainMenuNave = (data) => {
    setMenuList(2);
    dispatch(sideNavMainMenu(data));
    dispatch(getNewSubMenuList(data?.id, data?.slug_name, languageCode, getUserData?.group));
  };

  const brandMenuNave = (data) => {
    setMenuList(3);
    dispatch(sideNavCategoryMenu(data));
  }

  const gotoMainMenu = () => {
    setMenuList(1);
    dispatch(sideNavMainMenu(''));
    dispatch(sideNavBrandMenu(''));
    dispatch(sideNavProductMenu(''));
  };

  const gotoBrandMenu = () => {
    setMenuList(2);
    dispatch(sideNavCategoryMenu(''));
  };


  return (
    <>
      <div className={`sideNav__section ${getSideNavData}`}>
        <div className='sideNav__block'>
          <div className='sideNav__Header'>
            <div className='sideNav__Header__title'>
              <span>{t("categories")}</span>
            </div>
            <div className='sideNav__Header__action'>
              <button className="button__close" aria-label="button__close" onClick={() => dispatch(sidenavClose())}>
                  <VscChromeClose />            
              </button>
            </div>
          </div>
            {menuList === 1 && 
              <div className='sideNav__Body'>
                <ul className="sideNav__Menu">
                  { getMenuData ? 
                    (
                      <>
                        {getMenuData?.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <li className="sideNav__Main__Menu">
                                <Link
                                  to={`/${languageCode}/${data?.slug_name}`}
                                  className="text-decoration-none sideNav__Main__Menu__Link"
                                  aria-expanded="false"
                                >
                                  {data?.page_title}
                                </Link>
                                <button className="sideNav__Main__Menu__Nav" type="button" aria-label='prev' onClick={() => mainMenuNave(data)}>
                                  <FaChevronRight />
                                </button>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                        </li>
                      </>
                    )
                  }
                </ul>
              </div>
            }

            {menuList === 2 &&
              <>
                {getMainMenu?.slug_name === 'brands' 
                  ? (
                      <div className='sideNav__Body'>
                        <div className='BrandMenu__Header'  onClick={() => gotoMainMenu()}>
                          <div className='BrandMenu__Header__action'>
                            <button className="BrandMenu__Button__Close" aria-label="button__close">
                                <FaArrowLeft />            
                            </button>
                          </div>
                          <div className='BrandMenu__Header__title'>
                            <span>{getMainMenu?.page_title}</span>
                          </div>
                        </div>
                        <div className='BrandMenu__Body'>
                          <Brand brandData={getBrandData} dataFrom="Header" />
                        </div>
                      </div>
                    )
                  : (
                      <div className='sideNav__Body'>
                        <div className='BrandMenu__Header'  onClick={() => gotoMainMenu()}>
                          <div className='BrandMenu__Header__action'>
                            <button className="BrandMenu__Button__Close" aria-label="button__close">
                                <FaArrowLeft />            
                            </button>
                          </div>
                          <div className='BrandMenu__Header__title'>
                            <span>{getMainMenu?.page_title}</span>
                          </div>
                        </div>
                        <div className='BrandMenu__Body'>
                          <ul className="sideNav__Menu">
                            { getBrandMenu?.length > 0 ? 
                              (
                                <>
                                  {getBrandMenu?.map((data, i) => {
                                    return (
                                      <React.Fragment key={i}>
                                        <li className="sideNav__Main__Menu">
                                          <Link
                                            to={`/${languageCode}/category/licenses/${data?.subcatslugname}`}
                                            className="text-decoration-none sideNav__Main__Menu__Link"
                                            title={data?.subcatslugname}
                                          >
                                            {data?.subcatname}
                                          </Link>
                                          <button className="sideNav__Main__Menu__Nav" type="button" aria-label='prev' onClick={() => brandMenuNave(data)}>
                                            <FaChevronRight />
                                          </button>
                                        </li>
                                      </React.Fragment>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                </>
                              )
                            }
                          </ul>
                        </div>
                        <div className='ProductMenu__Body'>
                          <div className='BrandMenu__Header'  onClick={() => gotoMainMenu()}>
                            <div className='BrandMenu__Header__title'>
                              <span>{t("products")}</span>
                            </div>
                          </div>
                          <ul className="sideNav__Menu">
                            { getProductMenu ? 
                              (
                                <>
                                  {getProductMenu?.map((data, i) => {
                                    return (
                                      <React.Fragment key={i}>
                                        <li className="sideNav__Main__Menu">
                                          <Link
                                            to={`/${languageCode}/${getMainMenu?.slug_name}/product/${data?.slug_name}`}
                                            className="text-decoration-none sideNav__Main__Menu__Link"
                                            title={data?.slug_name}
                                          >
                                            {data?.productname}
                                          </Link>
                                        </li>
                                      </React.Fragment>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                  <li className="nav-item skeleton__li">
                                    <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                                  </li>
                                </>
                              )
                            }
                          </ul>
                        </div>
                      </div>
                    )
                }
              </>
            }

            {menuList === 3 &&
              <div className='sideNav__Body'>
                <div className='BrandMenu__Header'  onClick={() => gotoBrandMenu()}>
                  <div className='BrandMenu__Header__action'>
                    <button className="BrandMenu__Button__Close" aria-label="button__close">
                        <FaArrowLeft />            
                    </button>
                  </div>
                  <div className='BrandMenu__Header__title'>
                  <span>{getMainMenu?.page_title}</span>
                  <span>/</span>
                  <span>{getCategoryMenu?.subcatname}</span>
                  </div>
                </div>
                <div className='BrandMenu__Body'>
                  <ul className="sideNav__Menu">
                    { getCategoryMenu ? 
                      (
                        <>
                          {getCategoryMenu?.brandtype?.map((data, i) => {
                            return (
                              <React.Fragment key={i}>
                                <li className="sideNav__Main__Menu">
                                  <Link
                                    to={`/${languageCode}/${getMainMenu?.slug_name}/${data?.slug_name}`}
                                    className="text-decoration-none sideNav__Main__Menu__Link"
                                    title={data?.slug_name}
                                  >
                                    {data?.brandname}
                                  </Link>
                                </li>
                              </React.Fragment>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                        </>
                      )
                    }
                  </ul>
                </div>
                <div className='ProductMenu__Body'>
                  <div className='BrandMenu__Header'  onClick={() => gotoMainMenu()}>
                    <div className='BrandMenu__Header__title'>
                      <span>{t("products")}</span>
                    </div>
                  </div>
                  <ul className="sideNav__Menu">
                    { getProductMenu ? 
                      (
                        <>
                          {getProductMenu?.map((data, i) => {
                            return (
                              <React.Fragment key={i}>
                                <li className="sideNav__Main__Menu">
                                  <Link
                                    to={`/${languageCode}/${getMainMenu?.slug_name}/${data?.slug_name}`}
                                    className="text-decoration-none sideNav__Main__Menu__Link"
                                    title={data?.slug_name}
                                  >
                                    {data?.productname}
                                  </Link>
                                </li>
                              </React.Fragment>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                          <li className="nav-item skeleton__li">
                            <Skeleton variant="rectangular" animation="wave" width="80%" height={30} />
                          </li>
                        </>
                      )
                    }
                  </ul>
                </div>
              </div>
            }
        </div>
      </div>
      <div className={`sideNav__back__section ${getSideNavData}`} onClick={() => dispatch(sidenavClose())}>
      </div>
    </>
  )
}

export default MobileMenu
