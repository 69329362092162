import React, { Suspense, useRef, useState } from 'react'
import './ProductList.scss'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HiShoppingCart } from "react-icons/hi";
import QuantityBox from '../QuantityBox/QuantityBox';
import LazyLoad from "react-lazyload";
import Placeholder from '../Placeholder/Placeholder';
import { toast } from 'react-toastify';
import { addToCartAction } from '../../redux/features/QuantityBox/Action';
import { useTranslation } from 'react-i18next';

function ProductList(data) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(0)
  const flyCartRef = useRef([]);

  // get language code 
  const languageCode = useSelector(
    (state) => state?.languageCode
  );
  
  // get User Data
  const userData = useSelector(
    (state) => state?.userdata
  )

  const quantityStore = useSelector(
    (state) => state?.quantityStore
  )
  
  const linearDiscount = userData?.linear_discount ? userData?.linear_discount : 0;

  // customer group
  const CustomerGroup = userData?.group ? userData?.group : "general";

  // price formate
  const priceFormate = (lang, value) => {
    return (
      <>
          { lang === "es" ? (
          <>
            {Number(value).toLocaleString("es-ES", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : lang === "fr" ? (
          <>
            {Number(value).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : (
          <> {Number(value).toFixed(2)}</>
        )}
        <span className="currency">€</span>
      </>
    );
  };

  // discout calculation
  const discount = ((price, outletDiscount, linearDiscount)=> {
    const discounts = parseInt(outletDiscount) + parseInt(linearDiscount);
    const value = (price - ((price / 100) * discounts)).toFixed(2);
    return priceFormate(languageCode ,value);
  })

  // pre sale tag
  const preSaleBlock = (
    <>
      {data?.productData?.pre_sale == 1 && ( <span className="pre__sale">{t("presale")}</span> )}

      {(data?.productData?.pre_sale == 0 && CustomerGroup === "retailer" && data?.productData?.min_qty_retailer > data?.productData?.b2b_qty) &&
        (<span className="pre__sale">{t("presale")}</span>)
      }

      {(data?.productData?.pre_sale == 0 && CustomerGroup === "wholesaler" && data?.productData?.min_qty_wholesaler > data?.productData?.b2b_qty) &&
        (<span className="pre__sale">{t("presale")}</span>)
      }
    </>
  );

  // offer tag
  const offerBlock = (
    <>
      {data?.productData?.outlet_status == 1 && (
        <span className="offer__label">{t("offer")}</span>
      )}
    </>
  );

  // add to cart lable
  const cartLable =  (data?.productData?.pre_sale == 1 
        || (data?.productData?.pre_sale == 0 && CustomerGroup === "retailer" && data?.productData?.min_qty_retailer > data?.productData?.b2b_qty)
        || (data?.productData?.pre_sale == 0 && CustomerGroup === "wholesaler" && data?.productData?.min_qty_wholesaler > data?.productData?.b2b_qty))
          ? t("PreSale")
          : t("AddToCart");

  // min order quantity
  const minQty = CustomerGroup === "retailer" 
                  ? data?.productData?.min_qty_retailer 
                  : CustomerGroup === "wholesaler"
                    ? data?.productData?.min_qty_wholesaler 
                    : 1;

  // max order quantity
  const maxQty = (CustomerGroup === "retailer" || CustomerGroup === "wholesaler") 
                  ? parseInt(data?.productData?.b2b_qty) + parseInt(data?.productData?.pre_order_qty)
                  : data?.productData?.b2c_qty;

  // gust or b2c customer discount
  const discountBlock = (
    <>
      {(CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) && 
        <>
          <span className='ProductList__price'>
            {priceFormate(languageCode, data?.productData?.b2c_price)}
          </span>
          <span className='ProductList__discount'></span>
        </>
      }

      {(CustomerGroup !== "retailer" && CustomerGroup !== "wholesaler") && 
        <>
          {(data?.productData?.outlet_status == 0 && linearDiscount == 0) && (
            <>
              <span className='ProductList__price'>
                {priceFormate(languageCode, data?.productData?.b2c_price)} 
              </span>
              <span className='ProductList__discount'>
                {data?.productData?.price_before > data?.productData?.b2c_price 
                  ? priceFormate(languageCode, data?.productData?.price_before) 
                  : <></>
                }
              </span>
            </>
          )}

          {(data?.productData?.outlet_status == 1 && linearDiscount == 0)  && (
            <>
              <span className='ProductList__price'>
                {discount(data?.productData?.b2c_price, 20, linearDiscount)}
              </span>
              <span className='ProductList__discount'>
                {priceFormate(languageCode, data?.productData?.b2c_price)}
              </span>
            </>
          )}

          {(data?.productData?.outlet_status == 0 && linearDiscount > 0)  && (
            <>
              <span className='ProductList__price'>
                {discount(data?.productData?.b2c_price, 0, linearDiscount)}
              </span>
              <span className='ProductList__discount'>
                {priceFormate(languageCode, data?.productData?.b2c_price)}
              </span>
            </>
          )}

          {(data?.productData?.outlet_status == 1 && linearDiscount > 0) && (
            <>
              <span className='ProductList__price'>
                {discount(data?.productData?.b2c_price, 20, linearDiscount)}
              </span>
              <span className='ProductList__discount'>
                {priceFormate(languageCode, data?.productData?.b2c_price)}
              </span>
            </>
          )}
        </>
      }
    </>
  );

  
  // retailer or wholesaler price block
  const distributorBlock = ((CustomerGroup === "retailer" || CustomerGroup === "wholesaler" ) && 
    <>
      <span className='distributor__start'></span>
      <span className='distributor__price'>
      {t("DistributorPrice")}
      </span> 

      {(data?.productData?.outlet_status == 0 && linearDiscount == 0) && (
        <>
          <span className='ProductList__price'>
            { CustomerGroup === "retailer" && priceFormate(languageCode, data?.productData?.retailer_price)}
            { CustomerGroup === "wholesaler" && priceFormate(languageCode, data?.productData?.wholesaler_price)}
          </span>
          <span className='ProductList__discount'>
            { CustomerGroup === "retailer" && data?.productData?.price_before > data?.productData?.retailer_price
              &&  priceFormate(languageCode, data?.productData?.price_before)
            }
            {CustomerGroup === "wholesaler" && data?.productData?.price_before > data?.productData?.wholesaler_price
              && priceFormate(languageCode, data?.productData?.price_before)
            }
          </span>
        </>
      )}

      {(data?.productData?.outlet_status == 1 && linearDiscount == 0)  && (
        <>
          <span className='ProductList__price'>
            { CustomerGroup === "retailer" && discount(data?.productData?.retailer_price, data?.productData?.discount_retailer, 0)}
            { CustomerGroup === "wholesaler" && discount(data?.productData?.wholesaler_price, data?.productData?.discount_wholesaler, 0)}
          </span>
          <span className='ProductList__discount'> 
            { CustomerGroup === "retailer" && priceFormate(languageCode, data?.productData?.retailer_price)}
            { CustomerGroup === "wholesaler" && priceFormate(languageCode, data?.productData?.wholesaler_price)}
          </span>
        </>
      )}

      {(data?.productData?.outlet_status == 0 && linearDiscount > 0)  && (
        <>
          <span className='ProductList__price'>
            { CustomerGroup === "retailer" && discount(data?.productData?.retailer_price, 0, linearDiscount)}
            { CustomerGroup === "wholesaler" && discount(data?.productData?.wholesaler_price, 0, linearDiscount)}
          </span>
          <span className='ProductList__discount'> 
            { CustomerGroup === "retailer" && priceFormate(languageCode, data?.productData?.retailer_price)}
            { CustomerGroup === "wholesaler" && priceFormate(languageCode, data?.productData?.wholesaler_price)}
          </span>
        </>
      )}

      {(data?.productData?.outlet_status == 1 && linearDiscount > 0) && (
        <>
          <span className='ProductList__price'>
            { CustomerGroup === "retailer" && discount(data?.productData?.retailer_price, data?.productData?.discount_retailer, linearDiscount)}
            { CustomerGroup === "wholesaler" && discount(data?.productData?.wholesaler_price, data?.productData?.discount_wholesaler, linearDiscount)}
          </span>
          <span className='ProductList__discount'> 
            { CustomerGroup === "retailer" && priceFormate(languageCode, data?.productData?.retailer_price)}
            { CustomerGroup === "wholesaler" && priceFormate(languageCode, data?.productData?.wholesaler_price)}
          </span>
        </>
      )}

    </>   
  );

  const addToCartApi = ((product, keyValue, prodImg)=> {
    const productQty = quantityStore[product?.sku] ? quantityStore[product?.sku] : minQty;
    dispatch(addToCartAction(product, languageCode, productQty, linearDiscount, CustomerGroup, userData, toast, prodImg, flyCartRef.current[keyValue], false));
  })

  const checkImageExists = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setCurrentImage(1);
    };

    img.onerror = () => {
      setCurrentImage(0);
    };
  };

  return (
    <Suspense fallback={null}>
      <div className='ProductList__block' 
        onMouseEnter={data?.productData?.image?.length > 1 ? () => checkImageExists(`${process.env.REACT_APP_BASE_PRODUCT_URL}/${data?.productData?.image[1]}`) : () => setCurrentImage(0)} 
        onMouseLeave={() => setCurrentImage(0)}
      >
        <div className='ProductList__img__block'>
          <Link className='ProductList__link' to={`/${languageCode}/ProductDetails/${data?.productData?.slug}`} title={data?.productData?.format}>
            <LazyLoad height={200} offset={300} placeholder={<Placeholder />} debounce={500}>
              <img className='ProductList__image' 
                src={`${process.env.REACT_APP_BASE_PRODUCT_URL}/${data?.productData?.image[currentImage]}`} 
                alt={data?.productData?.format} 
                ref={el => (flyCartRef.current[data?.productKey] = el)} 
              />
            </LazyLoad>
          </Link>
          <div className='ProductList__lable'>
            {preSaleBlock}
            {offerBlock}
          </div>
        </div>

        <div className='ProductList__desc__block'>
          <div className='ProductList__title__block'>
            <span className='ProductList__sku'>
              {data?.productData?.sku}
            </span>
            <span className='ProductList__name'>
                <Link to={`/${languageCode}/ProductDetails/${data?.productData?.slug}`}
                  className="text-decoration-none text-dark" title={data?.productData?.format}
                >
                  {data?.productData?.format}
                </Link>
            </span>
            <span className='ProductList__brand' title={data?.productData?.brand}>
              {data?.productData?.brand}
            </span>
          </div>

          <div className='ProductList__price__block'>
            <span className='ProductList__row'>
              {discountBlock}
            </span>

            <span className='ProductList__row distributor__row'>
                {distributorBlock}
            </span>
          </div>

          <div className='ProductList__description__block'>
            {data?.productData?.description}
          </div>

          <div className='ProductList__cart__block'>
            <div className='cart__action'>
              <button className="cart__btn btn__addtocart" id="btn__addtocart" aria-label='addtocart' title={cartLable} onClick= {() => addToCartApi(data?.productData, data?.productKey, `${process.env.REACT_APP_BASE_PRODUCT_URL}/${data?.productData?.image[0]}`)}>
                <HiShoppingCart className="cart__icon"/>
                {" "} {cartLable}
              </button>
            </div>
            
            <div className='cart__input__block'>
              <QuantityBox CustomerGroup={CustomerGroup} sku={data?.productData?.sku} minQty={minQty} maxQty={maxQty}/>
            </div>
          </div>
        </div>        
      </div>
    </Suspense>
  )
}

export default ProductList
