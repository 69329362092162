import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './Register.scss'
import { getToken, forgotPassword, getLoginContent } from '../../redux/features/Register/Action'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

function Register({ setActiveStep }) {
  const [forgot, setForgot] = useState(false)
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [emailValidation, setEmailValidation] = useState(false)
  const [passwordValidation, setPasswodValidation] = useState(false)
  const selectedLanguage = useSelector(
    (state) => state?.selectedLanguage
  );
  const [loginValidation, setLoginValidateion] = useState({})

  // Getting language code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const cartDataList = useSelector(
    (state) => state?.cartData
  )

  const allCartList = Object.keys(cartDataList)?.map(
    (e) => cartDataList[e]?.cart_id
  );

  const userdata = useSelector((state) => state?.userdata)
  const forgotpasswordStatus = useSelector((state) => state?.forgotpasswordStatus)
  const logincontent = useSelector(state => state.logincontent)

  useEffect(() => {
    if (forgotpasswordStatus?.data?.success === 'true') {
      toast.success(forgotpasswordStatus?.data?.message)
      dispatch({ type: "FORGOT__PASSWORD", payload: '' })
    }
    if (forgotpasswordStatus?.data?.success === 'false') {
      toast.error(forgotpasswordStatus?.data?.message)
      dispatch({ type: "FORGOT__PASSWORD", payload: '' })
    }
  }, [forgotpasswordStatus])
  // Login Validation and API Call
  const SubmitHandler = (e) => {
    e.preventDefault();
    setLoginValidateion({})
    if (languageCode === undefined) return
    let validationCheck = e.target[0].value.match(/^(([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    setEmailValidation(validationCheck === null ? true : false)
    if (e.target[1].value?.length === 0) {
      return setPasswodValidation(true)
    } else {
      setPasswodValidation(false)
    }
    if (validationCheck === null) return
    dispatch(getToken(e.target[0].value, e.target[1].value, languageCode, allCartList, toast, t))
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if (user === null) return
    if (user?.token && window.location.pathname.slice(-5) === 'login') {
      navigate(`/${languageCode}/myaccount`)
      dispatch({ type: "CURRENT__SHIPPING", payload: '' });
      dispatch({ type: "CURRENT__BILLING", payload: '' });
      dispatch({ type: "GUEST__ADDRESS__LIST", payload: '' })
    } else if (user?.token) {
      navigate(`/${selectedLanguage?.code}/checkout/addressinfo`)
      setLoginValidateion(user)
    }
  }, [userdata])

  useEffect(() => {
    dispatch(getLoginContent(languageCode))
  }, [languageCode])

  const [forgotEmailValidation, setForgotEmailValidation] = useState(false)

  const guestlogin = () => {
    setActiveStep(2)
    navigate(`/${selectedLanguage?.code}/checkout/addressinfo`)
  }

  // Forgot Password API Call
  const forgotHandler = (e) => {
    e.preventDefault();
    setPasswodValidation(false)
    setEmailValidation(false)
    let validationCheck = e.target[0].value.match(/^(([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    setForgotEmailValidation(validationCheck === null ? true : false)
    if (validationCheck === null) return
    dispatch(forgotPassword(e.target[0].value, languageCode, toast))
  }

  return (
    <div className='register Main__section'>
      <div className='register__container'>
        <div className='register__content'>

          <div className='content__B2C'>
            <h2 className='B2C__title'>{logincontent?.login_page_content_1?.tittle}</h2>
            <p className='B2C__description'>{logincontent?.login_page_content_1?.content}</p>
            <h2 className='B2C__title'>{logincontent?.login_page_content_2?.tittle}:</h2>
            <Link to={`/${selectedLanguage?.code}/individualregister`} className='B2C__selection'>
              <img src={`${process.env.REACT_APP_BASE_URL}/${logincontent?.login_page_content_2?.image}`} alt="" />
              <span>{logincontent?.login_page_content_2?.content}</span>
            </Link>
            <div onClick={guestlogin} className='Continue__guest'>
              <img src="https://karactermania.com/kmania/images/BTC.png" alt="" />
              {t("ContinueGuest")}
            </div>
          </div>

          <div className='content__B2B'>

            <h2 className='B2B__title'>{logincontent?.login_page_content_3?.tittle}</h2>
            <p className='B2B__description'>{logincontent?.login_page_content_3?.content}
            </p>
            <h2 className='B2B__title'>{logincontent?.login_page_content_4?.tittle}:</h2>
            <div className='B2B__selection'>
              <Link to={`/${selectedLanguage?.code}/shopRegister`} className='B2B__shop'>
                <img src={`${process.env.REACT_APP_BASE_URL}/${logincontent?.login_page_content_4?.image}`} alt="" />
                <span>{logincontent?.login_page_content_4?.content}</span>
              </Link>
              <Link to={`/${selectedLanguage?.code}/wholeregister`} className='B2B__wholesaler'>
                <img src={`${process.env.REACT_APP_BASE_URL}/${logincontent?.login_page_content_5?.image}`} alt="" />
                <span> {logincontent?.login_page_content_5?.content}</span>
              </Link>
            </div>
          </div>
        </div>

        <div className='register__form'>
          <h2>{t("already_register")}</h2>
          <h3>{t("create_account_personal")}</h3>
          <p>{t("account_login")}</p>

          <form className='register__login' onSubmit={(e) => SubmitHandler(e)}>
            <input type="text" placeholder={t("email_address_input")} />
            {emailValidation && <p className='error'>{t("email_blank_validation")}</p>}
            <input type="password" placeholder={t("password_input")} />
            {passwordValidation && <p className='error'>{t("password_blank_validation")}</p>}
            {loginValidation?.success === "false" && <p className='error custom_error'>{loginValidation?.message}</p>}
            <p className='register__button'>
              <button type='submit' aria-label='submit'>{t("sing_in")} </button>
              <label>
                <input type="checkbox" onClick={e => setForgot(e.target.checked)} />
                {t("forgot_password")}
              </label>
            </p>
          </form>

          <form onSubmit={e => forgotHandler(e)} className={`${!forgot ? "register__hideforgot" : "register__forgotpass"}`}>
            <p>  {t("forgot_password_label")} </p>
            <div className='forgotpass__actions'>
              <input type="text" placeholder={t("email_address_input")} />
              {forgotEmailValidation && <p className='error'>{t("email_blank_validation")}</p>}
              <button type="submit" aria-label='submit'> {t("submit")} </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Register