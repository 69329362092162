import React, { Suspense } from 'react';
import './RegisterAndDiscover.scss';

function RegisterAndDiscover(data) {

  const isTrue = data.isTitleShow !== true || data.isTitleShow == undefined;

  return (
    <Suspense fallback={null}>
      <div className="Regdis__section">
        <div className="Regdis__container">
          <div className="Regdis__row">
            <div className="Regdis__advantage">
              {isTrue && <div className='advantage__block' dangerouslySetInnerHTML={{
                __html: data?.advantage?.title,
              }}
              ></div>}
              <div className='advantage__block' dangerouslySetInnerHTML={{
                __html: data?.advantage?.content,
              }}
              ></div>
            </div>
            <div className="Regdis__identifier">
              <div className="identifier__block" dangerouslySetInnerHTML={{
                __html: data?.identifier?.content,
              }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default RegisterAndDiscover;
