import React, { Suspense } from 'react';
import './CategoryContent.scss';

function CategoryContent(data) {
  return (
    <Suspense fallback={null}>
      <div className='CategoryContent__Section'>
        <div className='CategoryContent_row'>
          <div className='CategoryContent_left CategoryContent_col'>
            <div dangerouslySetInnerHTML={{ __html: data?.contentLeft }}></div>
          </div>
          <div className='CategoryContent_right CategoryContent_col'>
            <div dangerouslySetInnerHTML={{ __html: data?.contentRight }}></div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default CategoryContent;