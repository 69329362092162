import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { contactFormAction, contactResetAction, getContactUs } from '../../../redux/features/CMS/Action';
import { Formik, useFormik } from 'formik';
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import './ContactUs.scss'
import SeoContent from '../../../Components/SeoContent/SeoContent';

function ContactUs() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const alterNative = [];

  const [seoData, setSeoData] = useState();
  const [contactData, setContactData] = useState();
  const [displayError, setDisplayError] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const getContactUsData = useSelector(
    (state) => state?.contactUsData
  );

  const { successMsg, errorInfo } = useSelector(
    (state) => state?.contactFormData
  );

  useEffect(() => {
    dispatch(getContactUs(languageCode));
  }, [languageCode])

  useEffect(() => {
    if (getContactUsData?.details?.length > 0) {
      setContactData(...getContactUsData?.details);

      const newData = {
        current_url: "canonical",
        lang: languageCode,
        meta_description: getContactUsData?.details[0]?.meta_description,
        meta_keyword: getContactUsData?.details[0]?.meta_keywords,
        meta_title: getContactUsData?.details[0]?.meta_title,
        slug: "batman-black-mask-backpack-batman-knight-05639",
        url: `${languageCode}/${getContactUsData?.details[0]?.slug}`
      }
      setSeoData(newData);
    }
  }, [getContactUsData])


  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  useEffect(() => {
    if (errorInfo) {
      setDisplayError(true);

      setTimeout(() => {
        dispatch(contactResetAction());
        setDisplayError('');
      }, 10000);
    }

    if (successMsg) {
      setDisplayError(false);
      setTimeout(() => {
        dispatch(contactResetAction());
        setDisplayError('');
      }, 10000);
    }
  }, [successMsg, errorInfo]);


  const SuccessAlert = () => {
    return (
      <div className="alert alert-success d-flex align-items-center p-3 mt-4" role="alert" >
        <i className="fa fa-check text-success fw-bold me-2 ms-3" aria-hidden="true" ></i>
        <div className="register-error-success">{successMsg}</div>
      </div>
    );
  };

  const FailureAlert = () => {
    return (
      <div className="alert alert-danger d-flex align-items-center p-3 mt-4" role="alert" >
        <i className="fa fa-exclamation-circle me-2 ms-3 text-danger" aria-hidden="true" ></i>
        <div className="register-error-message">{successMsg}</div>
      </div>
    );
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      comment: "",
      phone: "",
    },

    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = t("name_blank_validation");
      } else if (!/^[a-zA-Z ]*$/.test(values.name)) {
        errors.name = t("name_formate_validation");
      }

      if (!values.phone) {
        errors.phone = t("phone_blank_validation");
      } else if (!/^[0-9]*$/.test(values.phone)) {
        errors.phone = t("phone_formate_validation");
      }

      if (!values.email) {
        errors.email = t("email_blank_validation");
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = t("email_formate_validation");
      }

      if (!values.comment) {
        errors.comment = t("comment_blank_validation");
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      dispatch(contactFormAction(values, languageCode, { resetForm }));
    },
  });

  return (
    <div className='Main__section contactUs__section'>
      {seoData &&
        <>
          <SeoContent seoDetails={seoData} alterNative={alterNative} />
        </>
      }

      <div className="contactUs__block">
        <div className='contactUs__content' dangerouslySetInnerHTML={{ __html: contactData?.content }}></div>
        <div className='contactUs__form'>
          <Formik>
            <form onSubmit={formik.handleSubmit}>
              <div className="fieldset mb-3">
                <h2 class="legend large">{t("contact_info")}</h2>

                <div className="row">
                  {/* Success & error msgs */}
                  {successMsg && !displayError && <SuccessAlert />}
                  {errorInfo && displayError && <FailureAlert />}

                  <div class="col-6 mb-3 mt-3">
                    <label htmlFor="name" class="form-label">
                      {t("Name")} <span className="rf">*</span>
                    </label>

                    <input className="form-control" title={t("Name")} type="text" name="name"
                      placeholder={t("name_input")} onChange={formik.handleChange} value={formik.values.name}
                      autoComplete="off" id="name"
                    />

                    <p className="register-error">
                      {formik.errors.name && formik.touched.name && formik.errors.name}
                    </p>
                  </div>

                  <div class="col-6 mb-3 mt-3">
                    <label htmlFor="last" class="form-label">
                      {t("Email")} <span className="rf">*</span>
                    </label>

                    <input name="email" id="email" type="text"
                      className="form-control" autoComplete="off" title={t("Email")}
                      placeholder={t("email_address_input")} onChange={formik.handleChange} value={formik.values.email}
                    />

                    <p className="register-error">
                      {formik.errors.email && formik.touched.email && formik.errors.email}
                    </p>
                  </div>

                  <div class="col-6 mb-3">
                    <label htmlFor="phone" class="form-label">
                      {t("phoneNumber")} <span className="rf">*</span>
                    </label>

                    <input name="phone" id="phone" type="text" className="form-control" title={t("phoneNumber")}
                      placeholder={t("phone_input")} autoComplete="off" onChange={formik.handleChange} value={formik.values.phone}
                    />

                    <p className="register-error">
                      {formik.errors.phone &&
                        formik.touched.phone &&
                        formik.errors.phone}
                    </p>
                  </div>

                  <div class="col-12 field left">
                    <label htmlFor="phone" class="form-label">
                      {t("comment")} <span className="rf">*</span>
                    </label>

                    <textarea id="comment" class="form-control" name="comment"
                      placeholder={t("comment_input")} rows="3" cols="5"
                      onChange={formik.handleChange} value={formik.values.comment}
                    ></textarea>

                    <p className="register-error">
                      {formik.errors.comment && formik.touched.comment && formik.errors.comment}
                    </p>
                  </div>

                  <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleRecaptchaChange} />

                  <div class="col-12 formSubmit">
                    <button type="submit" class="submit__btn" title={t("submit")} style={{ cursor: !recaptchaToken ? "not-allowed" : "pointer" }} disabled={!recaptchaToken}>
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
