import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './Footer.scss';
import { getFooterApi } from "../../redux/features/Footer/Action";
import { startTransition } from 'react';
import { useNavigate, useParams } from "react-router";
import authgif from './../../assects/images/Footer/auth.gif';
import { IoBagHandleSharp, IoStorefront } from "react-icons/io5";
import { FaTimes, FaTruckMoving } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CookieConsent, { Cookies } from "react-cookie-consent";

function Footer() {
  const dispatch = useDispatch();
  const { lg } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSuspended, setIsSuspended] = useState(false);

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const userData = useSelector(
    (state) => state?.userdata
  );

  const footerData = useSelector(
    (state) => state?.FooterData
  );

  // API Call for Footer
  useEffect(() => {
    if (languageCode) {
      startTransition(() => {
        const langCode = languageCode ? languageCode : lg;
        dispatch(getFooterApi(langCode))
      });
    }
  }, [languageCode])

  useEffect(() => {
    startTransition(() => setIsSuspended(true));
  }, [languageCode])

  if (footerData?.length === 0) {
    return <></>;
  }

  return (
    <>
      <Suspense fallback={null}>
        <div className="Footer__container">
          <div className="Footer__content">
            {footerData && (
              <div dangerouslySetInnerHTML={{ __html: footerData?.content }} />
            )}
          </div>
          <div className="Footer__Empty"></div>
        </div>

        {!userData?.userid &&
          <div className="WelcomeModel__Section">
            <div className="WelcomeModel__block" id="icon-batman" data-bs-toggle="modal" data-bs-target="#welcomeModal">
              <img src={authgif} alt="Icono de Batman" className="WelcomeModel__img" />
              <p class="WelcomeModel__Tooltip">{t("register")}</p>
            </div>

            <div className="modal fade WelcomeModel__popup" id="welcomeModal" tabindex="1">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div class="modalLeft">
                    <div class="modalClose">
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <FaTimes />
                      </button>
                    </div>

                    <p className="modelTitle">{t("WELCOME")}!</p>
                    <p className="modelPara">{t("create_account_mgs")}</p>
                  </div>

                  <div class="modalRight">
                    <div class="modalClose">
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {/* <BsPersonFill />  */}
                    <div class="modalRow">
                      <Link to={`/${languageCode}/individualregister`} className='B2B__shop'>
                        <button className="modalRow-btn" data-bs-dismiss="modal" aria-label="Close">
                          <IoBagHandleSharp /> {t("Customer")}
                        </button>
                      </Link>
                    </div>

                    <div class="modalRow">
                      <Link to={`/${languageCode}/shopRegister`} className='B2B__shop'>
                        <button className="modalRow-btn" data-bs-dismiss="modal" aria-label="Close">
                          <IoStorefront /> {t("Store")}
                        </button>
                      </Link>
                    </div>

                    <div class="modalRow">
                      <Link to={`/${languageCode}/wholeregister`} className='B2B__shop'>
                        <button className="modalRow-btn" data-bs-dismiss="modal" aria-label="Close">
                          <FaTruckMoving /> {t("Wholesaler")}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }


      </Suspense>

      <Suspense fallback={null}>
        <CookieConsent
          location="bottom"
          buttonText={t("cookie_accept")}
          cookieName="kmaniaCookie2"
          style={
            {
              background: "#ffffffd1",
              position: "fixed",
              left: "0",
              bottom: "0",
              zIndex: "999999",
              color: "#000",
              width: "100%",
              padding: "1rem 0px",
              borderTop: "1px solid #e1e0e3",
              fontSize: "16px"
            }
          }
          buttonStyle={{ color: "#FFF", background: "#ff6600", fontSize: "16px", margin: "15px 0" }}
          expires={150}
        >
          {t("cookie_consent")}{" "}
          <Link to={`/${languageCode}/cookies`} style={{ color: "#fb5913" }}>({t("cookie_consent_path")})</Link>.
        </CookieConsent>
      </Suspense>
    </>
  )
}

export default Footer
