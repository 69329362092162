import React, { Suspense } from 'react';
import './BrandAndDiscover.scss';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function BrandAndDiscover(data) {

  const { slugName } = useParams();

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const isContain = ["oh-my-pop", "prodg"];

  return (
    <Suspense fallback={null}>
      <div className="BrandAndDis__section">
        <div className="BrandAndDis__container">
          <div className="BrandAndDis__row">
            {isContain.includes(slugName) ? <div className="brand-left-img">
              <img style={{ width: "100%", objectFit: "cover" }} src={`${process.env.REACT_APP_BASE_URL}/${data?.leftbanner?.image}`} alt={data?.leftbanner?.image_alt} title={data?.leftbanner?.image_title} />
            </div> : <div className="BrandAndDis__advantage">
              <img style={{ width: "100%" }} src={`${process.env.REACT_APP_BASE_URL}/${data?.advantage?.image}`} alt={data?.advantage?.image_alt} title={data?.advantage?.image_title} />
              <div className="BrandAndDis__content">
                <h2>{data?.advantage?.tittle}</h2>
                <p className="BrandAndDis__content__des">
                  <Link to={`/${languageCode}/${slugName}`} title={data?.advantage?.tittle}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data?.advantage?.content,
                      }}
                    ></span>
                  </Link>
                </p>
              </div>
            </div>
            }

            {isContain.includes(slugName) ? <div className="BrandAndDis__identifier">
              <div className="identifier__block"
                dangerouslySetInnerHTML={{
                  __html: data?.leftbanner?.content,
                }}
              ></div>
            </div> :
              <div className="BrandAndDis__identifier">
                <div className="identifier__block" dangerouslySetInnerHTML={{
                  __html: data?.identifier?.content,
                }}
                ></div>
              </div>}

          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default BrandAndDiscover;