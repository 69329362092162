import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetails, getOrders, getOrderSearch } from '../../../redux/features/MyAccount/Action';
import './Orders.scss'
import { useTranslation } from "react-i18next";
import Skeleton from 'react-loading-skeleton'
import Loader from '../../Loader/Loader.js'
import Form from 'react-bootstrap/Form';

function Orders() {
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchID, setSearchID] = useState();
  const [detailsLoader, setDetailsLoader] = useState(false);

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );
  const myorders = useSelector(state => state?.myorders)
  const orderdetails = useSelector(state => state?.orderdetails)
  const ordersearch = useSelector(state => state?.ordersearch)

  useEffect(() => {
    setDetailsLoader(false)
    setLoader(false)
  }, [orderdetails, ordersearch])

  useEffect(() => {
    setLoader(false)
    dispatch({ type: "ORDER__SEARCH", payload: "" });
  }, [myorders])

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    dispatch(getOrders(user?.userid, languageCode))
    setLoader(true)
  }, [])

  const [selectedOrder, setSelectedOrder] = useState()

  const currentOrder = (value) => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    setDetailsLoader(true)
    setSelectedOrder(value)
    dispatch(getOrderDetails(value, user?.userid))
    setSortDeliveryDate(null)
  }

  useEffect(() => {
    if (myorders === {} || myorders === undefined || myorders?.my_order_list?.order?.length === undefined) return
    if (myorders?.my_order_list?.order.length === 0) return
    currentOrder(myorders?.my_order_list?.order[0]?.order_id)
  }, [myorders])

  const handleEnter = (e) => {
    if (e.code === "Enter") {
      if (searchID == "") {
        let user = JSON.parse(localStorage.getItem('userdata'))
        dispatch(getOrders(user?.userid, languageCode))
        setLoader(true)
      } else {
        setDetailsLoader(true)
        dispatch(getOrderSearch(searchID))
      }
    }
  }

  const searchOrder = () => {
    if (searchID == "") {
      let user = JSON.parse(localStorage.getItem('userdata'))
      dispatch(getOrders(user?.userid, languageCode))
      setLoader(true)
    } else {
      setDetailsLoader(true)
      dispatch(getOrderSearch(searchID))
    }
  }

  useEffect(() => {
    if (searchID == "") {
      let user = JSON.parse(localStorage.getItem('userdata'))
      dispatch(getOrders(user?.userid, languageCode))
      setLoader(true)
    }
  }, [searchID])

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedOrdersDetails, setSortedOrdersDetails] = useState();
  const [sortDeliveryDate, setSortDeliveryDate] = useState();

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  console.log(orderdetails, 'orderdetails')

  const [allSortedOrder, setAllSortedOrders] = useState([])

  useEffect(() => {
    setAllSortedOrders([])
    if (ordersearch?.my_order_search_list === undefined || (ordersearch?.my_order_search_list?.order?.length === 0 && ordersearch?.my_order_search_list?.cabe_pedi?.length === 0) || searchID == "") {
      myorders?.my_order_list?.order?.filter(OR => setAllSortedOrders(prevState => [...prevState, OR]))
      myorders?.my_order_list?.cabe_pedi?.filter(CP => setAllSortedOrders(prevState => [...prevState, CP]))
    } else {
      ordersearch?.my_order_search_list?.order?.filter(OR => setAllSortedOrders(prevState => [...prevState, OR]))
      ordersearch?.my_order_search_list?.cabe_pedi?.filter(CP => setAllSortedOrders(prevState => [...prevState, CP]))
    }
  }, [myorders, ordersearch])

  const sortedOrders = allSortedOrder?.sort((a, b) => {
    if (sortBy) {
      if (a[sortBy] < b[sortBy]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  const [sortByDetails, setSortByDetails] = useState(null);
  const [sortOrderDetails, setSortOrderDetails] = useState('asc');

  const handleSortDetails = (field) => {
    if (sortByDetails === field) {
      setSortOrderDetails(sortOrderDetails === 'asc' ? 'desc' : 'asc');
    } else {
      setSortByDetails(field);
      setSortOrderDetails('asc');
    }
  }

  useEffect(() => {
    let data = []
    if (orderdetails?.my_order_details_list?.line_pedi?.length > 0) {
      orderdetails?.my_order_details_list?.line_pedi?.filter(D => data.push(D))
    } else {
      orderdetails?.my_order_details_list?.order_item?.filter(D => data.push(D))
    }
    const ordersSortedDetails = data?.sort((a, b) => {
      if (sortByDetails) {
        if (a[sortByDetails] < b[sortByDetails]) {
          return sortOrderDetails === 'asc' ? -1 : 1;
        }
        if (a[sortByDetails] > b[sortByDetails]) {
          return sortOrderDetails === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });
    setSortedOrdersDetails(ordersSortedDetails);
  }, [orderdetails, sortByDetails, sortOrderDetails, sortDeliveryDate])

  useEffect(() => {

    const ordersSortedDetails = orderdetails?.myorderdetails?.filter(item => item?.estimated_delivery === sortDeliveryDate)?.sort((a, b) => {
      if (sortByDetails) {
        if (a[sortByDetails] < b[sortByDetails]) {
          return sortOrderDetails === 'asc' ? -1 : 1;
        }
        if (a[sortByDetails] > b[sortByDetails]) {
          return sortOrderDetails === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });

    if (ordersSortedDetails?.length > 0) {
      setSortedOrdersDetails(ordersSortedDetails);
    }
  }, [sortByDetails, sortOrderDetails, sortDeliveryDate])

  return (
    <div className='orders'>
      {loader ? <Loader /> :
        <>
          <div className='orders__heading' dangerouslySetInnerHTML={{ __html: myorders?.my_order_heading?.content }} />
          <label>
            <input type="search" placeholder='Search Order' onChange={e => setSearchID(e.target.value)} onKeyDown={handleEnter} />
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-search" viewBox="0 0 16 16" onClick={searchOrder}>
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </span>
          </label>
          <div className='orders__container'>
            <div className='orders__list'>
              <table className='orders__table'>
                <thead>
                  <tr>
                    <td onClick={() => handleSort('order_id')}>
                      {t('order')}
                      <span className={sortBy === 'order_id' && sortOrder === 'asc' && 'asc'}>▲</span>
                      <span className={sortBy === 'order_id' && sortOrder === 'desc' && 'desc'}>▼</span>
                    </td>
                    <td onClick={() => handleSort('date_order')}>
                      {t('order_date')}
                      <span className={sortBy === 'date_order' && sortOrder === 'asc' && 'asc'}>▲</span>
                      <span className={sortBy === 'date_order' && sortOrder === 'desc' && 'desc'}>▼</span>
                    </td>
                    <td onClick={() => handleSort('order_total')}>
                      {t('order_amount')}
                      <span className={sortBy === 'order_total' && sortOrder === 'asc' && 'asc'}>▲</span>
                      <span className={sortBy === 'order_total' && sortOrder === 'desc' && 'desc'}>▼</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {sortedOrders?.length === 0 ? (
                    <tr>
                      <td colSpan="3">No data available in table</td>
                    </tr>
                  ) : (
                    sortedOrders?.length && sortedOrders?.map((MO) => (
                      <tr key={MO?.order_id} onClick={() => currentOrder(MO?.order_id)} className={`${selectedOrder == MO?.order_id && "order__selected"}`}>
                        <td>
                          <span className="MobileView">{t('order')}</span>
                          {MO?.order_id}
                        </td>
                        <td>
                          <span className="MobileView">{t('order_date')}</span>
                          {MO?.date_order}
                        </td>
                        <td>
                          <span className="MobileView">{t('order_amount')}</span>
                          {MO?.order_total}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            {/* <div>estimated_delivery</div> */}

            <div className='orders_delivary_dates'>
              <table className='details__table'>
                <thead>
                  <tr>
                    <td>
                      {t("delivery_Group")}
                    </td>
                  </tr>
                </thead>
                {detailsLoader
                  ?
                  <tbody>
                    <td>
                      <Skeleton className='plp__filter__skeleton' count="4" variant="rectangular" animation="wave" height={36} />
                    </td>
                  </tbody>
                  : orderdetails?.my_order_details_list?.order_item_estimated_delivery_groups?.length > 0
                    ?
                    <>
                      <tbody>
                        {orderdetails?.my_order_details_list?.order_item_estimated_delivery_groups?.length && orderdetails?.my_order_details_list?.order_item_estimated_delivery_groups?.map(OD =>
                          <tr className='estimated_delivery_row' key={OD?.item_ref} onClick={() => setSortDeliveryDate(OD?.estimated_delivery)}>
                            <td>
                              <span className='MobileView'>{t("delivery_Group")}</span>
                              {OD?.estimated_delivery}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </>
                    :
                    <tbody>
                      <tr>
                        <td>
                          {t("no_data")}
                        </td>
                      </tr>
                    </tbody>
                }
              </table>
            </div>

            <div className='orders__details'>
              <table className='details__table'>
                <thead>
                  <tr>
                    <td onClick={() => handleSortDetails('item_ref')}>
                      {t("item_ref")}
                      <span className={sortByDetails === 'item_ref' && sortOrderDetails === 'asc' && 'asc'}>▲</span>
                      <span className={sortByDetails === 'item_ref' && sortOrderDetails === 'desc' && 'desc'}>▼</span>
                    </td>
                    <td>{t("pic")}</td>
                    <td>{t("item_description")}</td>
                    <td onClick={() => handleSortDetails('units')}>
                      {t("units")}
                      <span className={sortByDetails === 'units' && sortOrderDetails === 'asc' && 'asc'}>▲</span>
                      <span className={sortByDetails === 'units' && sortOrderDetails === 'desc' && 'desc'}>▼</span>
                    </td>
                    <td onClick={() => handleSortDetails('item_price')}>
                      {t("item_price")}
                      <span className={sortByDetails === 'item_price' && sortOrderDetails === 'asc' && 'asc'}>▲</span>
                      <span className={sortByDetails === 'item_price' && sortOrderDetails === 'desc' && 'desc'}>▼</span>
                    </td>
                    <td onClick={() => handleSortDetails('discount')}>
                      {t("discount")}
                      <span className={sortByDetails === 'discount' && sortOrderDetails === 'asc' && 'asc'}>▲</span>
                      <span className={sortByDetails === 'discount' && sortOrderDetails === 'desc' && 'desc'}>▼</span>
                    </td>
                    <td onClick={() => handleSortDetails('amount')}>
                      {t("amount")}
                      <span className={sortByDetails === 'amount' && sortOrderDetails === 'asc' && 'asc'}>▲</span>
                      <span className={sortByDetails === 'amount' && sortOrderDetails === 'desc' && 'desc'}>▼</span>
                    </td>
                    <td onClick={() => handleSortDetails('estimated_delivery')}>
                      {t("estimated_delivery")}
                      <span className={sortByDetails === 'estimated_delivery' && sortOrderDetails === 'asc' && 'asc'}>▲</span>
                      <span className={sortByDetails === 'estimated_delivery' && sortOrderDetails === 'desc' && 'desc'}>▼</span>
                    </td>
                    <td onClick={() => handleSortDetails('status')}>
                      {t("status")}
                      <span className={sortByDetails === 'status' && sortOrderDetails === 'asc' && 'asc'}>▲</span>
                      <span className={sortByDetails === 'status' && sortOrderDetails === 'desc' && 'desc'}>▼</span>
                    </td>
                  </tr>
                </thead>
                {detailsLoader ? <tbody>
                  <td colspan="9">
                    <Skeleton className='plp__filter__skeleton' count="4" variant="rectangular" animation="wave" height={36} />
                  </td>
                </tbody>
                  :
                  sortedOrdersDetails?.length > 0 ?
                    <tbody>
                      {sortedOrdersDetails?.length && sortedOrdersDetails?.map(OD =>
                        <tr key={OD?.item_ref}>
                          <td><span className='MobileView'>{t("item_ref")}</span>{OD?.item_ref}</td>
                          <td><span className='MobileView'>{t("pic")}</span><img src={`${process.env.REACT_APP_BASE_PRODUCT_URL}/${OD?.pic}`} /></td>
                          <td><span className='MobileView'>{t("item_description")}</span>{OD?.item_description}</td>
                          <td><span className='MobileView'>{t("units")}</span>{OD?.units}</td>
                          <td><span className='MobileView'>{t("item_price")}</span>{OD?.item_price} €</td>
                          <td><span className='MobileView'>{t("discount")}</span>{OD?.discount_1} %</td>
                          <td><span className='MobileView'>{t("amount")}</span>{OD?.amount} €</td>
                          <td><span className='MobileView'>{t("estimated_delivery")}</span>{OD?.estimated_delivery}</td>
                          <td><span className='MobileView'>{t("status")}</span>{OD?.status}</td>
                        </tr>
                      )}
                    </tbody>
                    : <tbody>
                      <tr>
                        <td colspan="9">
                          {t("no_data")}
                        </td>
                      </tr>
                    </tbody>
                }
              </table>
              <div className='table__address'>
                {orderdetails?.my_order_details_list?.order_delivery_address[0]?.address_1 &&
                  <div className='table__delivery'>
                    <h4>{t("shipping_address").toUpperCase()}</h4>
                    <p>{orderdetails?.my_order_details_list.order_delivery_address[0]?.first_name} {orderdetails?.my_order_details_list.order_delivery_address[0]?.last_name}</p>
                    <p>{orderdetails?.my_order_details_list.order_delivery_address[0]?.address_1}</p>
                    <p>{orderdetails?.my_order_details_list.order_delivery_address[0]?.city}</p>
                    <p>{orderdetails?.my_order_details_list.order_delivery_address[0]?.state} - {orderdetails?.my_order_details_list.order_delivery_address[0]?.postal_code}</p>
                    <p>{orderdetails?.my_order_details_list.order_delivery_address[0]?.phone}</p>
                    <p>{orderdetails?.my_order_details_list.order_delivery_address[0]?.email}</p>
                  </div>
                }
                {orderdetails?.my_order_details_list?.order_billing_address[0]?.address_1 &&
                  <div className='table__billing'>
                    <h4>{t("billing_address").toUpperCase()}</h4>
                    <p>{orderdetails?.my_order_details_list.order_billing_address[0]?.first_name} {orderdetails?.my_order_details_list.order_billing_address[0]?.last_name}</p>
                    <p>{orderdetails?.my_order_details_list.order_billing_address[0]?.address_1}</p>
                    <p>{orderdetails?.my_order_details_list.order_billing_address[0]?.city}</p>
                    <p>{orderdetails?.my_order_details_list.order_billing_address[0]?.state} - {orderdetails?.my_order_details_list.order_billing_address[0]?.postal_code}</p>
                    <p>{orderdetails?.my_order_details_list.order_billing_address[0]?.phone}</p>
                    <p>{orderdetails?.my_order_details_list.order_billing_address[0]?.email}</p>
                  </div>
                }
              </div>
              {/* purposely commented  */}

              {/* <Form.Range disabled defaultValue={0} />

              <div className='progress_status'>
                <p><span>Ordered</span>
                  <span>{orderdetails?.my_order_details_list?.order_cabe_pedi_trackig_date[0]?.date_order ? 
                      orderdetails?.my_order_details_list?.order_cabe_pedi_trackig_date[0]?.date_order 
                    : orderdetails?.my_order_details_list?.order_trackig_date[0]?.date_order}
                  </span></p>
                <p><span>In Process</span></p>
                <p><span>Shipped</span></p>
              </div> */}
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Orders