import React, { Suspense } from 'react';
import './FullWithBanner.scss';
import { Link } from 'react-router-dom';

function FullBannerwithConent(data) {

  function removeTags(input) {
    return input?.replace(/<[^>]*>/g, '');
  }

  return (
    <Suspense fallback={null}>
      <div className='FullBannerConent__section'>
        {data?.contentData === 'contentBanner'
          ? (
            <div className='contentBanner__block'>
              <div className="contentBanner__content mb2">
                <div className='contentBanner__title' style={{ color: data.isBrand && "black", fontWeight: "bold" }}>
                  <span dangerouslySetInnerHTML={{ __html: data?.bannerContent?.title }}></span>
                </div>
                <div className='contentBanner__text'>
                  <div dangerouslySetInnerHTML={{ __html: data?.bannerContent?.content }} />
                </div>
              </div>
              {data?.bannerData === undefined ? <div className='imagesUploadSoon'>image will upload soon</div> : <div className="contentBanner__gallery">
                <Link to={data?.bannerData?.url}><img src={`${process.env.REACT_APP_BASE_URL}/${data?.bannerData?.banner_image}`} alt={data?.bannerData?.image_alt} title={data?.bannerData?.image_title} /></Link>
                <><div className={` ${data.prodg === true ? "prodg__Gallery__content" : "contentBanner__gallery__content"}`}>
                  <h2 className='Gallery__title' title={removeTags(data?.bannerData?.title)} dangerouslySetInnerHTML={{ __html: data?.bannerData?.title }}></h2>
                  <p className='Gallery__desc'>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data?.bannerData?.description,
                      }}
                    ></span>
                  </p>
                </div>
                </>
              </div>}
            </div>
          )
          : (
            <div className="Gallery__block Gallery__block_banner3 ">
              <div className="Gallery__content" style={{ width: data.isBrand ? "40%" : "50%" }}>
                <div className='Gallery__content_left'>
                  <h2 className='Gallery__title' style={{ maxWidth: "fitContent", color: data?.isBrand && "black" }} dangerouslySetInnerHTML={{ __html: data?.bannerData?.title }}></h2>
                  <p className='Gallery__desc'>
                    <span dangerouslySetInnerHTML={{ __html: data?.bannerData?.description }} ></span>
                  </p>
                </div>
              </div>
              <div className="Gallery__image" style={{ width: data.isBrand ? "60%" : "50%" }}>
                <Link to={data?.bannerData?.url}><img style={{ objectFit: "cover" }} src={`${process.env.REACT_APP_BASE_URL}/${data?.bannerData?.banner_image}`} alt={data?.bannerData?.image_alt} title={data?.bannerData?.image_title}
                  width={915} height={514} /> </Link>
              </div>
            </div>
          )
        }
      </div>
    </Suspense>
  )
}

export default FullBannerwithConent;