import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import './SearchPage.scss';
import ProductGridSkeleton from '../../Components/ProductGrid/ProductSkeleton/ProductGridSkeleton';
import ProductListSkeleton from '../../Components/ProductList/ProductListSkeleton/ProductListSkeleton';
import { useParams } from 'react-router';
import ProductGrid from '../../Components/ProductGrid/ProductGrid';
import ProductList from '../../Components/ProductList/ProductList';
import { searchPageAction } from '../../redux/features/Header/Action';
import { VscSearchStop } from 'react-icons/vsc';
import { useTranslation } from 'react-i18next';

function SearchPage() {
  const { searchInput } = useParams();
  const { t, i18n }     = useTranslation();
  const dispatch        = useDispatch();

  const [isLoader, setIsLoader] = useState(false);
  const [searchView, setSearchView] = useState("grid");


  const languageCode = useSelector(
    (state) => state?.languageCode
  )

  const userData = useSelector(
    (state) => state?.userdata
  )

  const searchPageData = useSelector(
    (state) => state?.searchData
  )

  useEffect(() => {
    setIsLoader(true);
    if(searchInput){
      dispatch(searchPageAction(languageCode, searchInput, userData));
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  },[languageCode, userData, searchInput]);


  return (
    <div className='SearchPage__Section Main__section'>
      {isLoader 
        ? (
            <div className='SearchPage__Skeleton'>
              <div className='SearchPage__head'>
                <div className='SearchPage__title'>
                  <Skeleton variant="rectangular" animation="wave" width={300} height={30} />
                </div>
              </div>
              <div className='SearchPage__body'>
                {[...Array(20)].map((_, index) => ( 
                  searchView === "grid" ? <ProductGridSkeleton /> : <ProductListSkeleton />
                ))}
              </div>
            </div>
          )
        : (
            <div className='SearchPage__block'>
              {searchPageData?.Total_items === 0 
                ? 
                  <>
                    <div className='SearchPage__head'>
                      <div className='SearchPage__title'>
                        <h1>{t('Search_results_for')}: {searchInput}</h1>
                      </div>
                    </div>
                    <div className='SearchPage__body'>
                      <div className='empty__Search'>
                        <p className='icon'><VscSearchStop /></p>
                        <p>{t('Product_not_found_search')}</p>
                      </div>
                    </div>
                  </>
                : <>
                    <div className='SearchPage__head'>
                      <div className='SearchPage__title'>
                        <h1>{t('Search_results_for')}: {searchInput}</h1>
                      </div>
                      <div className='SearchPage__item'>
                        {searchPageData?.Total_items} {" "}
                        {searchPageData?.Total_items > 1 ? t('search_items') : t('search_item')}
                      </div>
                      <div className='SearchPage__view'>
                        <span className={`${searchView === 'grid' && "selected"}`} >
                          <svg onClick={() => setSearchView("grid")} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-3x3" viewBox="0 0 16 16">
                            <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13zM1.5 1a.5.5 0 0 0-.5.5V5h4V1H1.5zM5 6H1v4h4V6zm1 4h4V6H6v4zm-1 1H1v3.5a.5.5 0 0 0 .5.5H5v-4zm1 0v4h4v-4H6zm5 0v4h3.5a.5.5 0 0 0 .5-.5V11h-4zm0-1h4V6h-4v4zm0-5h4V1.5a.5.5 0 0 0-.5-.5H11v4zm-1 0V1H6v4h4z"/>
                          </svg>
                        </span>
                        <span className={`${searchView === 'list' && "selected"}`} >
                          <svg onClick={() => setSearchView("list")} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                    
                    <div className='SearchPage__body'> 
                      {searchPageData?.product_list?.length > 0 && searchView === "grid" 
                        ? searchPageData?.product_list?.map((d, i) => <ProductGrid productData={d} key={i} productKey={i} />) 
                        : searchPageData?.product_list?.map((d, i) =>  <ProductList productData={d} key={i} />)
                      }
                    </div>
                  </>
              }
            </div>
          ) 
      }
    </div>
  )
}

export default SearchPage
