import axios from "axios";


export const miniNavOpen = () => {
  return async(dispatch) => {
    dispatch({ type: "MININAV__OPEN"});
  }
};

export const miniNavClose = () => {
  return async(dispatch) => {
    dispatch({ type: "MININAV__CLOSE"});
  }
};

export const sidenavOpen = () => {
  return async(dispatch) => {
    dispatch({ type: "SIDENAV__OPEN"});
  }
};

export const sidenavClose = () => {
  return async(dispatch) => {
    dispatch({ type: "SIDENAV__CLOSE"});
  }
};

export const miniSearchOpen = () => {
  return async(dispatch) => {
    dispatch({ type: "MINISEARCH__OPEN"});
  }
};

export const miniSearchClose = () => {
  return async(dispatch) => {
    dispatch({ type: "MINISEARCH__CLOSE"});
  }
};

export const sideNavMainMenu = (mainMenu) => {
  return async(dispatch) => {
    dispatch({ type: "SIDENAV__MAIN__MENU", payload: mainMenu});
  }
};


export const sideNavBrandMenu = (brandMenu) => {
  return async(dispatch) => {
    dispatch({ type: "SIDENAV__BRAND__MENU", payload: brandMenu});
  }
};

export const sideNavProductMenu = (ProductMenu) => {
  return async(dispatch) => {
    dispatch({ type: "SIDENAV__PRODUCT__MENU", payload: ProductMenu});
  }
};

export const sideNavCategoryMenu = (categoryMenu) => {
  return async(dispatch) => {
    dispatch({ type: "SIDENAV__CATEGORY__MENU", payload: categoryMenu});
  }
};

export const getLogoList = () => {
  return async(dispatch) => {
    try {
      const getLogoData = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_API_URL}/listlogo`
      })

      dispatch({ type: "LOGO__API", payload: getLogoData?.data?.list_logo });
    } catch(e) {
      console.log(e)
    }
  }
};

export const getLanguageList = () => {
  return async(dispatch) => {
    try {
      const getLanguageData = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_API_URL}/listlang`
      })

      dispatch({ type: "LANGUAGE__API", payload: getLanguageData?.data });
    } catch(e) {
      console.log(e)
    }
  }
};

export const getMegaMenuList = (languageCode, getUserData) => {
  return async(dispatch) => {
    try {
      const userid = getUserData?.userid ? getUserData?.userid : '';
      const price_list = getUserData?.price_list ? getUserData?.price_list : '';
      const group = getUserData?.group ? getUserData?.group : '';
      const linear_discount = getUserData?.linear_discount ? getUserData?.linear_discount : '';

      const data = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_API_URL}/megamenu/${languageCode}?user_id=${userid}&price_list=${price_list}&group=${group}&linear_discount=${linear_discount}`
      })

      dispatch({ type: "MEGAMENU__DATA", payload: data?.data });
    } catch(e) {
      console.log(e)
    }
  }
};

export const getSubMenuList = (megaMenu, languageCode, userGroup) => {
  return (dispatch) => {
    const getSubMenu = megaMenu?.map(async(value,i)=> {
      try {
        // let setUserGroup = userGroup ? userGroup : 'general';
        // const data = await axios({
        //   method: 'get',
        //   url: `${process.env.REACT_APP_BASE_API_URL}/getCategories/${languageCode}?group=${setUserGroup}&id=${value?.id}&slug_name=${value?.slug_name}`
        // })

        // let subMenuData = data?.data;
        // let slugName    = value?.slug_name;
        // dispatch({ type: "SUBMENU__DATA", payload: {subMenuData, slugName, languageCode} });

      } catch(e) {
        console.log(e)
      }
    })
  }
};

export const getNewSubMenuList = (id, slug, languageCode, userGroup) => {
  return async(dispatch) => {
    try {
      let setUserGroup = userGroup ? userGroup : 'general';
      const data = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_API_URL}/getCategories/${languageCode}?group=${setUserGroup}&id=${id}&slug_name=${slug}`
      })

      let subMenuData = data?.data;
      let slugName    = slug;
      dispatch({ type: "SUBMENU__DATA", payload: {subMenuData, slugName, languageCode} });
    } catch(e) {
      console.log(e)
    }
  }
};

export const setCurrentLanguage = (data) => {
  return async(dispatch) => {
    dispatch({ type: "LANGUAGE__CODE", payload: data });
  }
}

export const setSelectedLanguage = (data) => {
  return async(dispatch) => {
    dispatch({ type: "SELECTED__LANGUAGE", payload: data });
  }
}


export const searchAction = (languageCode, values, userData, navigate) => {
  return async(dispatch) => {
    try {
      navigate(`/${languageCode}/search/${values}`);
    } catch(e) {
      console.log(e)
    }
  }
};

export const languageNav = (newLang, slugs, pageType, navigate) => {
  return async(dispatch) => {
    try {

      // console.log('languageNav newLang',newLang);
      // console.log('languageNav pageType',pageType);
      // console.log('languageNav oldLang',slugs.lg);
      // console.log('languageNav clusterid',slugs.clusterid);
      // console.log('languageNav name',slugs.name);
      // console.log('languageNav product',slugs.product);
      // console.log('languageNav Pname',slugs.Pname);
      // console.log('languageNav slugName',slugs.slugName);
      // navigate(`/${languageCode}/search/${values}`);
    } catch(e) {
      console.log(e)
    }
  }
};

export const searchPageAction = (languageCode, values, userData) => {
  return async(dispatch) => {
    try {
      dispatch({ type: "SEARCH__DATA", payload: '' });
      const priceList = userData?.price_list ? userData?.price_list : '';
      const groupId   = userData?.group ? userData?.group : '';
      const linearDiscount = userData?.linear_discount ? userData?.linear_discount : '';
      
      const data = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_BASE_API_URL}/search/${values}/${languageCode}?price_list=${priceList}&group=${groupId}&linear_discount=${linearDiscount}`
      })

      if(data?.status === 200){
        dispatch({ type: "SEARCH__DATA", payload: data?.data });
      }else {
        console.log('search error');
      }
    } catch(e) {
      console.log(e)
    }
  }
};

export const setLangSwitchUrl = (values) => {
  return async(dispatch) => {
    try {
      if(values?.length > 0) {
        dispatch({ type: "LANGSWITCH__URL", payload: [] });
        values?.map((value, key) => {
          let lang = value?.lang;
          let url = value?.url;
          dispatch({ type: "LANGSWITCH__URL", payload:{ lang, url}});
        })
      }
    } catch(e) {
      console.log(e)
    }
  }
};