import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './B2CRegister.scss'
import { b2cRegister, getB2CRegisterContent } from '../../../redux/features/Register/Action';
import { Link, useNavigate, useParams } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCustomerRegisterEvent } from '../../../GTM/useCustomerRegisterEvent';

function B2CRegister() {
  const navigate = useNavigate()
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const { t, i18n } = useTranslation();

  const { addCustomerRegisterEvent } = useCustomerRegisterEvent();

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  // Getting language code
  const languageCode = useSelector((state) => state?.languageCode);
  const b2cregistercontent = useSelector(state => state?.b2cregistercontent)

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'));
    if (user?.userid) {
      navigate(`/${languageCode}/myaccount`)
    }
  }, [localStorage.getItem('userdata')])

  const dispatch = useDispatch();
  const [agree, setAgree] = useState();
  const [first, firstValidation] = useState(false)
  const [textFirst, setTextFirst] = useState(false)
  const [textLast, setTextLast] = useState(false)
  const [last, lastValidation] = useState(false)
  const [emailValidation, setEmailValidation] = useState(false)
  const [noEmail, setNoEmail] = useState(false)
  const [passwordValidation, setPasswordValidation] = useState(false)
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState(false)

  const submitHandler = (e) => {
    e.preventDefault();
    setPasswordValidation(false)
    setConfirmPasswordValidation(false)
    setEmailValidation(false)
    setNoEmail(false)
    firstValidation()
    lastValidation()
    setTextFirst(false)
    setTextLast(false)

    let validationCheck = e.target[2].value.match(/^(([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    let first__name = e.target[0].value.match(("^[a-zA-Z ]*$"))
    if (first__name === null) return setTextFirst(true)
    let last__name = e.target[1].value.match(("^[a-zA-Z ]*$"))
    if (last__name === null) return setTextLast(true)

    setAgree(e.target[5]?.checked)

    if (e.target[2].value?.length === 0) {
      setNoEmail(true)
    } else setEmailValidation(validationCheck === null ? true : false);

    if (e.target[3].value?.length === 0 || e.target[3]?.value?.length < 6) {
      setPasswordValidation(true)
    }
    else if (e.target[3].value != e.target[4].value) {
      setConfirmPasswordValidation(true)
    }

    if (e.target[0].value?.length === 0) {
      firstValidation(true)
    }

    if (e.target[1].value?.length === 0) {
      lastValidation(true)
    }

    if (e.target[5]?.checked && e.target[3].value == e.target[4].value) {
      dispatch(b2cRegister(e.target[0].value, e.target[1].value, e.target[2].value, e.target[3].value, e.target[4].value, languageCode, toast, navigate, addCustomerRegisterEvent))
    }
  }

  useEffect(() => {
    dispatch(getB2CRegisterContent(languageCode))
  }, [languageCode])

  return (
    <div className='b2cregister Main__section'>
      <div className='b2cregister__container'>
        <div className='b2cregister__block'>
          <h3>{b2cregistercontent?.individual_register_?.tittle}</h3>
          <p>{b2cregistercontent?.individual_register_?.content}</p>
          <span className='b2cregister__validations'> {t("require_fields")} </span>
          <form onSubmit={e => submitHandler(e)} className="b2cregister__form">
            <input id="first" type="text" placeholder={t("first_name_input")} />
            {first && <p className='b2cregister__error'> {t("first_name_blank_validation")} </p>}
            {textFirst && <p className='b2cregister__error'> {t("first_name_formate_validation")} </p>}

            <input id="last" type="text" placeholder={t("last_name_input")} />
            {last && <p className='b2cregister__error'> {t("last_name_blank_validation")} </p>}
            {textLast && <p className='b2cregister__error'>{t("last_name_formate_validation")} </p>}

            <input type="text" placeholder={t("email_address_input")} />
            {noEmail && <p className='b2cregister__error'> {t("email_blank_validation")} </p>}
            {emailValidation && <p className='b2cregister__error'>{t("email_formate_validation")}  </p>}

            <input type="password" placeholder={t("password_input")} />
            {passwordValidation && <p className='b2cregister__error'>{t("password_formate_validation")} </p>}

            <input type="password" placeholder={t("confirm_password_input")} />
            {confirmPasswordValidation && <p className='b2cregister__error'>{t("match_password_validation")} </p>}

            <label>
              <input onClick={e => setAgree(e.target.checked)} type="checkbox" value={agree} />
              <Link target="_blank" to={`/${languageCode}/legal-notice`}>{t("agree_terms_condition")} <span style={{ color: 'red' }}>*</span></Link>
            </label>

            {agree === false && <p className='b2cregister__error'>{t("agree_terms_validation")}</p>}
            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleRecaptchaChange} />
            <button className='b2cregister__create' aria-label='submit' disabled={!recaptchaToken} type='submit'>{t("create_account")} </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default B2CRegister