import axios from "axios";

export const categoryPageApiData = (languageCode, category, slugName) => {
    return async (dispatch) => {
        if (languageCode) {
            try {
                const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/clppage/${languageCode}/${category}/${slugName}`;
                const response = await axios.get(apiUrl);
                dispatch({ type: "CATEGORYPAGE_APIDATA", payload: response?.data });
            } catch (error) {
                return error;
            }
        }
    }
}
