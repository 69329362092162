import axios from "axios";

export const getCustomerInfo = (id, languageCode) => {
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/customerarea/${languageCode ? languageCode : "en"}/${id}`,
            })
            dispatch({ type: "CUSTOMER__INFO", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}

export const getAccountInfo = (id, languageCode) => {
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/accountinfo/${languageCode ? languageCode : 'en'}/${id}`,
            })
            dispatch({ type: "ACCOUNT__INFO", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}


export const changePassword = (userid, oldpassword, newpassword, confirmpassword, languageCode) => {
    return async(dispatch) => {
        dispatch({ type: "LOADER__TRIGGER", payload: true});
        try {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_API_URL}/changePassword/${languageCode}?id=${userid}&old_password=${oldpassword}&new_password=${newpassword}&confirm_password=${confirmpassword}`,
            })
            dispatch({ type: "CHANGE__PASSWORD", payload: data?.data });
            dispatch({ type: "LOADER__TRIGGER", payload: false});
        } catch(e) {
            dispatch({ type: "LOADER__TRIGGER", payload: false});
            console.log(e)
        }
    }
}

export const getOrders = (userid, languageCode) => {
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/myorder/${languageCode}/${userid}`,
            })
            dispatch({ type: "MY__ORDERS", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}

export const getOrderDetails = (orderid, userid) => {
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/myorderdetails/${userid}/${orderid}`
            })
            dispatch({ type: "ORDER__DETAILS", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}

export const getOrderSearch = (orderid) => {
    if(orderid === "") return
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/myorder_search/${orderid}`
            })
            dispatch({ type: "ORDER__SEARCH", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}


export const getInvoices = (lg, userid) => {
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/myinvoice/${lg}/${userid}`
            })
            dispatch({ type: "MY__INVOICES", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}

export const getInvoiceSearch = (lg, userid, searchid) => {
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/myinvoice_search/${userid}/${searchid}`
            })
            dispatch({ type: "MY__INVOICES__SEARCH", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}

export const getCatalog = () => {
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/catalogcontent`
            })
            dispatch({ type: "MY__CATALOGS", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}

export const getLaunch = () => {
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/releasecontent`
            })
            dispatch({ type: "MY__LAUNCH", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}

export const getAddressList = ( userid, lg ) => {
    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/myaddresses/${lg}/${userid}`
            })
            dispatch({ type: "ADDRESS__LIST", payload: data?.data });
        } catch(e) {
            console.log(e)
        }
    }
}

export const createAddress = ( addressdata, userid ) => {
    return async(dispatch) => {
        dispatch({ type: "LOADER__TRIGGER", payload: true});
        try {  
            const data = await axios({
                method: `${addressdata?.address_id ? "put" : 'post'}`,
                url: `${process.env.REACT_APP_BASE_API_URL}/${addressdata?.address_id ? "updateaddress" : "newaddress"}/${userid}`,
                data: {
                    first_name: addressdata?.first_name,
                    last_name: addressdata?.last_name,
                    email: addressdata?.email,
                    address_1: addressdata?.address_1,
                    telephone: addressdata?.telephone,
                    country_id: addressdata?.country_id,
                    state: addressdata?.state,
                    city: addressdata?.city,
                    postal_code: addressdata?.postal_code,
                    customer_id: userid,
                    default_shipping_address: addressdata?.default_shipping_address ? addressdata?.default_shipping_address : 0,
                    default_billing_address: addressdata?.default_billing_address ? addressdata?.default_billing_address : 0,
                    address_id: addressdata?.address_id ? addressdata?.address_id : ""
                }
            })
            dispatch({ type: "ADDRESS__STATUS", payload: data?.data })
            dispatch({ type: "LOADER__TRIGGER", payload: false});
        } catch(e) {
            dispatch({ type: "LOADER__TRIGGER", payload: false});
            console.log(e)
        }
    }
}

export const deleteAddress = ( address_id, customer_id ) => {
    return async(dispatch) => {
        dispatch({ type: "LOADER__TRIGGER", payload: true});
        try {
            const data = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_API_URL}/deleteaddress/${customer_id}/${address_id}`
            })
            dispatch({ type: "ADDRESS__STATUS", payload: data?.data })
            dispatch({ type: "LOADER__TRIGGER", payload: false});
        } catch(e) {
            dispatch({ type: "LOADER__TRIGGER", payload: false});
            console.log(e)
        }
    }
}

export const resetPasswords = ( values, token, navigate, languageCode, toast) => {

    return async(dispatch) => {
        try {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/resetPassword?password=${values.password}&confirm_password=${values.confirm}&token=${token}`,
            })

            if(data?.data?.success === "true") {
                toast.success(data?.data?.message);
                navigate(`/${languageCode}/login`);
            }
        } catch(e) {
            console.log(e)
        }
    }
};
