import React from 'react';
import './FullWithBanner.scss';
import { Link } from 'react-router-dom';

function FullWithBanner(data) {

  function removeTags(input) {
    return input?.replace(/<[^>]*>/g, '');
  }

  return (
    <>
      {data?.bannerData
        ? (
          <div className='FullWithBanner__section'>
            <div className="Gallery__block">

              <Link to={data?.bannerData?.url}> <img src={`${process.env.REACT_APP_BASE_URL}/${data?.bannerData?.banner_image}`} alt={data?.bannerData?.image_alt} title={data?.bannerData?.image_title} width={1830} height={150} /></Link>
              {data.isContentCenter === true ? <div className={`${"prdog__Gallery__content"}`}>

                <h2 className='Gallery__title' title={removeTags(data?.bannerData?.title)} dangerouslySetInnerHTML={{__html:data?.bannerData?.title}}></h2>
                <p className='Gallery__desc' title={removeTags(data?.bannerData?.description)}>
                  <span dangerouslySetInnerHTML={{ __html: data?.bannerData?.description }} ></span>
                </p>
              </div>
                : <div className={`${data.OhMyPop === true ? "ohmypop__Gallery__content" : "Gallery__content"}`}>

                  <h2 className='Gallery__title' title={removeTags(data?.bannerData?.title)} dangerouslySetInnerHTML={{ __html: data?.bannerData?.title }}></h2>
                  <p className='Gallery__desc' title={removeTags(data?.bannerData?.description)}>
                    <span dangerouslySetInnerHTML={{ __html: data?.bannerData?.description }} ></span>
                  </p>
                </div>
              }
            </div>
          </div>
        )
        : (
          <></>
        )
      }
    </>
  )
}

export default FullWithBanner;