import React, { useEffect, useState } from 'react'
import './AddressBook.scss'
import PopupAddressModal from '../../PopupAddressModal/PopupAddressModal.js';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAddress, getAddressList } from '../../../redux/features/MyAccount/Action';
import { useParams } from 'react-router';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import Loader from '../../Loader/Loader.js'
import { useTranslation } from 'react-i18next';

function AddressBook({ selectedTab, type }) {
  const { t } = useTranslation();

  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const { lg } = useParams()
  const [list, setList] = useState([]); // array list of 20 objects
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;

  const addressList = useSelector((state) => state?.addressList);
  useEffect(() => {
    setLoader(false)
  }, [addressList])

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setPageNumber(selectedPage);
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if (lg === undefined || user?.userid === undefined) return
    setLoader(true)
    dispatch(getAddressList(user?.userid, lg))
  }, [dispatch, lg])

  const [defaultShipping, setDefaultShipping] = useState({})
  const [defaultBilling, setDefaultBilling] = useState({})
  const [editAddress, setEditAddress] = useState({})

  useEffect(() => {
    const displayedList = addressList.slice(
      pageNumber * itemsPerPage,
      (pageNumber + 1) * itemsPerPage
    );
    setList(displayedList)
    let dBill = addressList?.find(AL => AL?.default_billing_address === 1)
    let dShip = addressList?.find(AL => AL?.default_shipping_address === 1)
    setDefaultShipping(dShip)
    setDefaultBilling(dBill)
  }, [addressList, pageNumber])


  const editHandler = (value) => {
    setEditAddress(value)
  }
  // Get Customer Info
  const customerinfo = useSelector((state) => state?.customerinfo)

  const deleteHandler = (value) => {
    dispatch(deleteAddress(value?.address_id, customerinfo?.customer_id))
    dispatch({ type: "CURRENT__SHIPPING", payload: '' });
    dispatch({ type: "CURRENT__BILLING", payload: '' });
  }
  const editShipping = () => {
    let dShip = addressList?.find(AL => AL?.default_shipping_address === 1)
    setEditAddress(dShip)
  }
  const editBilling = () => {
    let dBill = addressList?.find(AL => AL?.default_billing_address === 1)
    setEditAddress(dBill)
  }
  const createAddress = () => {
    setEditAddress({})
  }

  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (showPopup) {
      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
    }
  }, [showPopup]);

  // Get Address Status
  const addressStatus = useSelector(state => state.addressStatus)

  useEffect(() => {
    if (addressStatus?.success === 'true') {
      toast.success(addressStatus?.message)
      // setShowPopup(addressStatus?.message)
      var addressFormButton = document.getElementById("addressForm");
      addressFormButton.setAttribute("data-bs-dismiss", "modal");
      addressFormButton.click();
      addressFormButton.removeAttribute("data-bs-dismiss");
      dispatch({ type: "ADDRESS__STATUS", payload: '' })
      let user = JSON.parse(localStorage.getItem('userdata'))
      dispatch(getAddressList(user?.userid, lg))
      dispatch({ type: "CURRENT__SHIPPING", payload: '' });
      dispatch({ type: "CURRENT__BILLING", payload: '' });
    }
  }, [addressStatus])

  const handleCancel = (e) => {
    e.preventDefault()
    e.stopPropagation()
    var addressFormButton = document.getElementById("addressForm");
    addressFormButton.setAttribute("data-bs-dismiss", "modal");
    addressFormButton.click();
    addressFormButton.removeAttribute("data-bs-dismiss");
  }

  console.log('defaultBilling', type)

  return (
    <div className='addressbook'>
      {loader ? <Loader /> :
        <>
          <div className="addressbook__myaddress">
            <div className='addressbook__title'>
              <h3>{t("address_book")}</h3>
              <button className='add__address' onClick={createAddress} data-bs-toggle="modal" data-bs-target="#addressForm">{t("create_address")}</button>
            </div>
            <div className="addressbook__default">
              <div className='addressbook__billing'>
                <h4>{t("default_billing_address")}</h4>
                <p>{defaultBilling?.first_name} {defaultBilling?.last_name}</p>
                <p>{defaultBilling?.address_1}{defaultBilling?.city}</p>
                <p>{defaultBilling?.country_name}</p>
                <p>{defaultBilling?.postal_code}</p>
                <p>{defaultBilling?.address_2}</p>
                <p>{defaultBilling?.telephone}</p>
                <p>{defaultBilling?.email}</p>
                {defaultBilling?.address_id ?
                  type == "general" ?
                    <p className='change__address' onClick={editBilling} data-bs-toggle="modal" data-bs-target="#addressForm">{t("edit_billing_address")}</p> :
                    ""
                  :
                  <p>{t("no_default_billing_address")}</p>
                }
              </div>
              <div className='addressbook__shipping'>
                <h4>{t("default_shipping_address")}</h4>
                <p>{defaultShipping?.first_name} {defaultShipping?.last_name}</p>
                <p>{defaultShipping?.address_1}{defaultShipping?.city}</p>
                <p>{defaultShipping?.country_name}</p>
                <p>{defaultShipping?.postal_code}</p>
                <p>{defaultShipping?.address_2}</p>
                <p>{defaultShipping?.telephone}</p>
                <p>{defaultShipping?.email}</p>
                {type !== "general" && defaultShipping?.address_id && defaultShipping?.address_id !== defaultBilling?.address_id ?
                  <p className='change__address' onClick={editShipping} data-bs-toggle="modal" data-bs-target="#addressForm">{t("edit_shipping_address")}</p>
                  :
                  type !== "general" && defaultShipping?.address_id ? "" : defaultShipping?.address_id ? "" : <p>{t("no_default_shipping_address")}</p>
                }
                {defaultShipping?.address_id && type == "general" && <p className='change__address' onClick={editShipping} data-bs-toggle="modal" data-bs-target="#addressForm">{t("edit_shipping_address")}</p>}
              </div>
            </div>
            <div class="modal fade" id="addressForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
              <PopupAddressModal editAddress={editAddress} handleCancel={handleCancel} selectedTab={selectedTab} userInfo={JSON.parse(localStorage.getItem('userdata'))} />
            </div>
          </div>
          <div className='addressbook__otheraddress'>
            <div className='addressbook__pagination'>
              <h5 className='addressbook__tabletitle'>{t("other_addresses")}</h5>
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(addressList.length / itemsPerPage)}
                onPageChange={handlePageClick}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
            {showPopup && <p className='address__success'>{showPopup}</p>}
            <table className='addressbook__table'>
              <thead>
                <tr>
                  <td>{t("first_name")}</td>
                  <td>{t("last_name")}</td>
                  <td>{t("street_address")}</td>
                  <td>{t("city")}</td>
                  <td>{t("country")}</td>
                  <td>{t("state")}</td>
                  <td>{t("postal_code")}</td>
                  <td>{t("phoneNumber")}</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {list?.map(L =>
                  <tr key={L?.id}>
                    <td><span className='AB__MobileView'>{t("first_name")}</span>{L?.first_name}</td>
                    <td><span className='AB__MobileView'>{t("last_name")}</span>{L?.last_name}</td>
                    <td><span className='AB__MobileView'>{t("street_address")}</span>{L?.address_1}</td>
                    <td><span className='AB__MobileView'>{t("city")}</span>{L?.city}</td>
                    <td><span className='AB__MobileView'>{t("country")}</span>{L?.country_name}</td>
                    <td><span className='AB__MobileView'>{t("state")}</span>{L?.state}</td>
                    <td><span className='AB__MobileView'>{t("postal_code")}</span>{L?.postal_code}</td>
                    <td><span className='AB__MobileView'>{t("phoneNumber")}</span>{L?.telephone}</td>
                    <td className='addressbook__table__buttons'>
                      <span className='addressbook__edit' onClick={() => editHandler(L)} data-bs-toggle="modal" data-bs-target="#addressForm">{t("edit")}</span>
                      {
                        L?.default_billing_address !== 1 ? <span className='addressbook__delete' onClick={() => deleteHandler(L)}>{t("delete")}</span>
                          : ""
                      }
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      }
    </div>
  )
}

export default AddressBook