import React, { Suspense } from 'react'
import './ProductListSkeleton.scss'
import { Link } from 'react-router-dom';
import LazyLoad from "react-lazyload";
import Placeholder from '../../Placeholder/Placeholder';
import Skeleton from 'react-loading-skeleton';

function ProductListSkeleton(data) {

  return (
    <Suspense fallback={null}>
      <div className='ProductListSkeleton'>
        <div className='ProductList__img__block'>
          <Link className='ProductList__link'>
            <LazyLoad height={200} offset={300} placeholder={<Placeholder />} debounce={500}>
            <Skeleton count="1" variant="rectangular" animation="wave" width={320} height={300} />
            </LazyLoad>
          </Link>
        </div>

        <div className='ProductList__desc__block1'>
          <div className='ProductList__title__block1'>
            <span className='ProductList__sku1'>
              <Skeleton count="1" variant="rectangular" animation="wave" height={72} />
            </span>
          </div>

          <div className='ProductList__description__block1'>
            <Skeleton count="1" variant="rectangular" animation="wave" height={36} />
          </div>

          <div className='ProductList__title__block1'>
            <span className='ProductList__sku1'>
              <Skeleton count="1" variant="rectangular" animation="wave" height={72} />
            </span>
          </div>
        </div>        
      </div>
    </Suspense>
  )
}

export default ProductListSkeleton
