import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Accounts from '../../Components/MyAccount/Accounts/Accounts'
import Launch from '../../Components/MyAccount/Launch/Launch'
import Orders from '../../Components/MyAccount/Orders/Orders'
import Catalogs from '../../Components/MyAccount/Catalogs/Catalogs'
import Invoices from '../../Components/MyAccount/Invoices/Invoices'
import './MyAccount.scss'
import { useSelector, useDispatch } from 'react-redux'
import { getCustomerInfo } from '../../redux/features/MyAccount/Action'
import { useTranslation } from "react-i18next";
import AddressBook from '../../Components/MyAccount/AddressBook/AddressBook'

function MyAccount() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lg } = useParams()
  const Menu = [
    `${t("my_account")}`, `${t("address_book")}`, `${t("orders")}`, `${t("invoices")}`, `${t("catalogs")}`, `${t("launch")}`
  ]

  const generalMenu = [
    `${t("my_account")}`, `${t("address_book")}`, `${t("orders")}`
  ]

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  // Get User Info
  const customerinfo = useSelector(
    (state) => state?.customerinfo
  )

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if (user?.userid && languageCode) {
      dispatch(getCustomerInfo(user?.userid, languageCode))
    }
    // Cleanup function...
    return () => { };
  }, [localStorage.getItem('userdata'), languageCode])

  const [selectedTab, setSelectedTab] = useState(t("my_account"))

  const selectTab = (value) => {
    setSelectedTab(value)
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if (user === null) {
      navigate('/es')
    }
    // Cleanup function...
    return () => { };
  }, [])

  useEffect(() => {
    selectTab(t("my_account"))
  }, [lg, t])

  const tabRenderMemo = useMemo(() => {
    return <>
      {selectedTab === t('my_account') && <Accounts />}
      {selectedTab === t("address_book") && <AddressBook selectedTab={selectedTab} type={customerinfo?.account_type} />}
      {selectedTab === t("orders") && <Orders />}
      {selectedTab === t("invoices") && <Invoices />}
      {selectedTab === t("catalogs") && <Catalogs />}
      {selectedTab === t("launch") && <Launch />}
    </>
  }, [selectedTab, lg, t])


  const customerTableMemo = useMemo(() => {
    return <table>
      <tbody>
        <tr>
          <td>
            <p>
              <span className='myaccount__label'>{t("customer")}:</span>
              <span className='myaccount__value'>{customerinfo?.customer}</span>
            </p>
          </td>
          <td>
            <p>
              <span className='myaccount__label'>{t("account_type")}:</span>
              <span className='myaccount__value'>{customerinfo?.account_type}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span className='myaccount__label'>{t("customer_id")}:</span>
              <span className='myaccount__value'>{customerinfo?.erp_customer_id}</span>
            </p>
          </td>
          <td>
            <p>
              <span className='myaccount__label'>{t("default_address")}:</span>
              <span className='myaccount__value'>
                {customerinfo?.default_address?.address_1} {customerinfo?.default_address?.city} {customerinfo?.default_address?.state} {customerinfo?.default_address?.postal_code}
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span className='myaccount__label'>{t("customer_discount")}:</span>
              <span className='myaccount__value'>{customerinfo?.customer_discount}</span>
            </p>
          </td>
          <td>
            <p>
              <span className='myaccount__label'>{t("payment_method")}:</span>
              <span className='myaccount__value'>{customerinfo?.payment_method}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <span className='myaccount__label'>{t("equal_charge")}:</span>
              <span className='myaccount__value'>{customerinfo?.equal_charge}</span>
            </p>
          </td>
          <td>
            <p>
              <span className='myaccount__label'>{t("vat")}:</span>
              <span className='myaccount__value'>{customerinfo?.vat}</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  }, [customerinfo, languageCode])

  return (
    <div className='myaccount Main__section'>
      <div className='myaccount__container'>
        <img src="https://uat-react.karactermania.com/images/account/my-account-banner.jpg" alt="" />
        <div className='myaccount__breadcrumbs'>
          <Link to={`/${languageCode}/`}><span className='myaccount__home'>Karactermania</span></Link> / <span>{t("my_account")}</span>
        </div>
        <h3 className='myaccount__title'> {t("customer_area")}</h3>
        <div className='myaccount__customerarea'>
          {customerTableMemo}
        </div>

        <div className='myaccount__tabs desktopView'>
          <ul>
            {customerinfo?.account_type !== "general" ? Menu?.map(M =>
              <li className={`${selectedTab === M ? "li__selected" : "li__notselected"}`} onClick={() => selectTab(M)} key={M}>{M}</li>
            ) :
              generalMenu?.map(M =>
                <li className={`${selectedTab === M ? "li__selected" : "li__notselected"}`} onClick={() => selectTab(M)} key={M}>{M}</li>
              )
            }
          </ul>
        </div>

        <div className="myaccount__tabs mobileView">
          <div className="accordion" id="accordionColor">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button" type="button" aria-label='accordionbtn' data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  {selectedTab}
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionColor">
                <div className="accordion-body plp__colors__mobile">
                  <ul>
                    {Menu?.map(M =>
                      <li className={`${selectedTab === M ? "li__selected" : "li__notselected"}`} onClick={() => selectTab(M)} key={M}>{M}</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='myaccount__tabContent'>
          {tabRenderMemo}
        </div>
      </div>
    </div>
  )
}

export default MyAccount 