import React, { useEffect, useState } from 'react'
import './Checkout.scss'
import ProgressBar from '../../Components/Checkout/ProgressBar/ProgressBar'
import BillingAddressInfo from '../../Components/Checkout/BillingAddressInfo/BillingAddressInfo'
import { useParams } from 'react-router'
import CartSummary from '../../Components/Checkout/CartSummary/CartSummary'
import CartListing from './CartListing/CartListing'
import Register from '../Register/Register'
import OrderSummery from './OrderSummery/OrderSummery'
import { useDispatch, useSelector } from 'react-redux'

function Checkout() {
  const { checkoutType } = useParams()
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(0);
  const [currentBilling, setCurrentBilling] = useState({});
  const [currentShipping, setCurrentShipping] = useState({})
  const [steps, setSteps] = useState([])

  // const currentbilling = useSelector(state => state?.currentbilling)
  // const currentshipping = useSelector(state => state?.currentshipping)

  const nonloginsteps = [
    { title: 'ShoppingCart', type: 'cartlist' },
    { title: 'Registration', type: 'register' },
    { title: 'AddressInfo', type: 'addressinfo' },
    { title: 'OrderReview', type: 'ordersummery' },
  ];

  const loginsteps = [
    { title: 'ShoppingCart', type: 'cartlist' },
    { title: 'AddressInfo', type: 'addressinfo' },
    { title: 'OrderReview', type: 'ordersummery' },
  ];

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if(user?.userid) {
      setSteps(loginsteps)
    } else {
      setSteps(nonloginsteps)
    }
  }, [localStorage.getItem('userdata')])

  useEffect(() => {
    if(!currentBilling?.address_id) return
    dispatch({ type: "CURRENT__BILLING", payload: currentBilling });
  }, [currentBilling])

  useEffect(() => {
    if(!currentShipping?.address_id) return
    dispatch({ type: "CURRENT__SHIPPING", payload: currentShipping });
  }, [currentShipping])


  return (
    <div className='Main__section'>
      <div className='checkout__progressbar'>
        <ProgressBar steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
      </div>
      <div className='checkout'>
        <div className='checkout__content'>
          {checkoutType === "cartlist" && 
            <CartListing steps={steps} setActiveStep={setActiveStep} />
          }

          {checkoutType === "register" && 
            <Register setActiveStep={setActiveStep} />
          }
          
          {checkoutType === "addressinfo" && 
            <BillingAddressInfo currentBilling={currentBilling} setCurrentBilling={setCurrentBilling} currentShipping={currentShipping} 
              setCurrentShipping={setCurrentShipping}
            />
          }

          {checkoutType === "ordersummery" && 
            <OrderSummery setActiveStep={setActiveStep} />
          }

          {checkoutType !== "cartlist" && checkoutType !== "register" && checkoutType !== "ordersummery" && 
            <CartSummary activeStep={activeStep} setActiveStep={setActiveStep} currentBilling={currentBilling} currentShipping={currentShipping} />
          }

        </div>
      </div>
    </div>
  )
}

export default Checkout