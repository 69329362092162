import React, { useEffect, useState } from 'react';
import './CmsPages.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCMSCookiesData, getCMSLegalNotice, getCMSSecurePayemtData, getCMSShippingData, getCmsPageData } from '../../../redux/features/CMS/Action';
import SeoContent from '../../../Components/SeoContent/SeoContent';

function CmsPages() {
  const dispatch = useDispatch();
  const { lg, pageslug } = useParams();
  const [pageData, setPageData] = useState();
  const [seoData, setSeoData] = useState();

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const SecurePayemtData = useSelector((state) => state?.SecurePayemtData);

  const CMSSShippingData = useSelector((state) => state?.CMSSShippingData);

  const CMSLegalNoticeData = useSelector((state) => state?.CMSLegalNoticeData);

  const CMSCookiesData = useSelector((state) => state?.CMSCookiesData);

  const cmsPageData = useSelector(
    (state) => state?.cmsPageData
  );

  const securePayemtArr = ["/en/secure-payment", "/es/secure-payment", "/fr/secure-payment", "/it/secure-payment"]

  const shippingReturnsArr = ["/en/shipping-returns", "/es/shipping-returns", "/fr/shipping-returns", "/it/shipping-returns"]

  const legalNoticeArr = ["/en/legal-notice", "/es/legal-notice", "/fr/legal-notice", "/it/legal-notice"]

  const cookiesArr = ["/en/cookies", "/es/cookies", "/fr/cookies", "/it/cookies"]

  const alterNative = [];

  useEffect(() => {
    securePayemtArr.includes(window.location.pathname) && dispatch(getCMSSecurePayemtData(languageCode, "secure-payment"));
    shippingReturnsArr.includes(window.location.pathname) && dispatch(getCMSShippingData(languageCode, "shipping-returns"))
    legalNoticeArr.includes(window.location.pathname) && dispatch(getCMSLegalNotice(languageCode, "legal-notice"));
    cookiesArr.includes(window.location.pathname) && dispatch(getCMSCookiesData(languageCode, "cookies"));
  }, [languageCode]);

  useEffect(() => {
    if (cmsPageData?.details?.length > 0) {
      setPageData(...cmsPageData?.details);

      const newData = {
        current_url: "canonical",
        lang: languageCode,
        meta_description: cmsPageData?.details[0]?.meta_description,
        meta_keyword: cmsPageData?.details[0]?.meta_keywords,
        meta_title: cmsPageData?.details[0]?.meta_title,
        slug: "batman-black-mask-backpack-batman-knight-05639",
        url: `${languageCode}/static/${cmsPageData?.details[0]?.slug}`
      }
      setSeoData(newData);
    }
  }, [cmsPageData]);

  return (
    <div className={`Main__section cmsPages__Section ${pageslug}`}>
      {seoData &&
        <>
          <SeoContent seoDetails={seoData} alterNative={alterNative} />
        </>
      }

      {securePayemtArr.includes(window.location.pathname) ?
        <div className='cmsPages__content' dangerouslySetInnerHTML={{
          __html: SecurePayemtData?.cms_pages_details?.content
        }}></div> : null
      }

      {shippingReturnsArr.includes(window.location.pathname) ?
        <div className='cmsPages__content' dangerouslySetInnerHTML={{
          __html: CMSSShippingData?.cms_pages_details?.content
        }}></div> : null
      }

      {legalNoticeArr.includes(window.location.pathname) ?
        <div className='cmsPages__content' dangerouslySetInnerHTML={{
          __html: CMSLegalNoticeData?.cms_pages_details?.content
        }}></div> : null
      }

      {cookiesArr.includes(window.location.pathname) ?
        <div className='cmsPages__content' dangerouslySetInnerHTML={{
          __html: CMSCookiesData?.cms_pages_details?.content
        }}></div> : ""
      }

    </div>
  )
}

export default CmsPages;