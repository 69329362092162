import React from 'react'
import Skeleton from 'react-loading-skeleton'

function LogoIndicator() {
  return (
    <div>
      <Skeleton variant="rectangular" animation="wave" width={288} height={36} />
    </div>
  )
}

export default LogoIndicator
