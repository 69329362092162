const initialState = {
  miniCartNav: 'miniCart__close',
  sideNav: 'sideNav__section__close',
  miniSearchData: 'mobile_search_close',
  plpMobileFilter: 'plp__mobile__filter__close',
  sideNavMainMenu: [],
  sideNavBrandMenu: [],
  sideNavProductMenu: [],
  sideNavcategoryMenu: [],
  logoData: [],
  languageList: [],
  langSwitchUrl: [],
  languageCode: '',
  selectedLanguage: '',
  FooterData: [],
  megaMenu: [],
  subMenu: [],
  homePageData: [],
  homeMainBanner: [],
  categoryPageData: [],
  brandPageData: [],
  productDetailsData: [],
  userdata: [],
  countryList: [],
  quantityStore: {},
  menuData: {},
  customerinfo: {},
  changepasswordstatus: '',
  breadcrumbs: {},
  plpfilterdata: [],
  plpproducttype: [],
  plpcolors: [],
  plpsubbrands: [],
  plpfooter: [],
  plplisting: {},
  forgotpasswordStatus: '',
  plpbannerimage: '',
  plptitle: '',
  plpAltTag: '',
  plpheader: '',
  plpmeta: {},
  seodatas: {},
  registercontent: {},
  accountinfo: {},
  myorders: {},
  orderdetails: {},
  myinvoices: {},
  invoicesearch: {},
  mycatalog: {},
  mylaunch: {},
  contactUsData: {},
  contactFormData: { errorInfo: "", successMsg: "" },
  cmsPageData: {},
  addressList: [],
  addressStatus: {},
  cartData: {},
  searchData: [],
  promoCodeData: { applyCodeRes: "", errorInfo: "", successMsg: "" },
  placeOrderData: [],
  currentbilling: {},
  currentshipping: {},
  aboutBannerData: [],
  aboutContentData: [],
  aboutGalleryData: [],
  loadertrigger: false,
  paymentselect: {},
  guestlist: [],
  ordersearch: [],
  logincontent: {},
  b2cregistercontent: {},
  homeSeoData: {},
  categoryPageApiData: {},
  brandPageApiData: {},
  SecurePayemtData: {},
  CMSSShippingData: {},
  CMSLegalNoticeData: {},
  CMSCookiesData: {}
};

export const rootReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "MININAV__OPEN":
      return { ...state, miniCartNav: 'miniCart__open' };

    case "MININAV__CLOSE":
      return { ...state, miniCartNav: 'miniCart__close' };

    case "SIDENAV__OPEN":
      return { ...state, sideNav: 'sideNav__section__open' };

    case "SIDENAV__CLOSE":
      return { ...state, sideNav: 'sideNav__section__close' };

    case "MINISEARCH__OPEN":
      return { ...state, miniSearchData: 'mobile_search_open' };

    case "MINISEARCH__CLOSE":
      return { ...state, miniSearchData: 'mobile_search_close' };

    case "PLP__MOBILEFILTER__OPEN":
      return { ...state, plpMobileFilter: 'plp__mobile__filter__open' };

    case "PLP__MOBILEFILTER__CLOSE":
      return { ...state, plpMobileFilter: 'plp__mobile__filter__close' };

    case "SIDENAV__MAIN__MENU":
      return { ...state, sideNavMainMenu: payload };

    case "SIDENAV__BRAND__MENU":
      return { ...state, sideNavBrandMenu: payload };

    case "SIDENAV__PRODUCT__MENU":
      return { ...state, sideNavProductMenu: payload };

    case "SIDENAV__CATEGORY__MENU":
      return { ...state, sideNavcategoryMenu: payload };

    case "LOGO__API":
      return { ...state, logoData: payload };

    case "LANGUAGE__API":
      return { ...state, languageList: payload };

    case "LANGUAGE__CODE":
      return { ...state, languageCode: payload };

    case "SELECTED__LANGUAGE":
      return { ...state, selectedLanguage: payload };

    case "FOOTER__DATA":
      return { ...state, FooterData: payload };

    case "MEGAMENU__DATA":
      return { ...state, megaMenu: payload };

    case "SUBMENU__DATA":
      return {
        ...state,
        subMenu: {
          ...state?.subMenu,
          [payload?.slugName]: {
            subMenuData: payload?.subMenuData,
            languageCode: payload?.languageCode
          }
        }
      };

    case "HOMEPAGE__DATA":
      return { ...state, homePageData: payload };

    case "HOME__MAIN__BANNER":
      return { ...state, homeMainBanner: payload };

    case "HOME__SEO__DATA":
      return { ...state, homeSeoData: payload };

    case "CATEGORYPAGE__DATA":
      return { ...state, categoryPageData: payload };

    case "BRANDPAGE_DATA":
      return { ...state, brandPageData: payload };

    case "PRODUCTDETAILS_DATA":
      return { ...state, productDetailsData: payload };

    case "LOGIN__API":
      return { ...state, userdata: payload };

    case "LOGIN__CONTENT":
      return { ...state, logincontent: payload }

    case "B2BREGISTER__CONTENT":
      return { ...state, b2cregistercontent: payload }

    case "COUNTRYLIST__API":
      return { ...state, countryList: payload };

    case "QUANTITY__INCR__STORE":
      const newInQty = parseInt(payload?.qtyVal) + parseInt(payload?.minQty);

      if (payload?.maxQty >= newInQty) {
        return {
          ...state,
          quantityStore: {
            ...state?.quantityStore,
            [payload?.sku]: newInQty,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case "QUANTITY__SET__STORE":
      const baseInQty = parseInt(payload?.qtyVal);

      if (baseInQty) {
        return {
          ...state,
          quantityStore: {
            ...state?.quantityStore,
            [payload?.sku]: baseInQty,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case "QUANTITY__DECR__STORE":
      const newDeQty = payload?.qtyVal - payload?.minQty;

      if (payload?.minQty <= newDeQty) {
        return {
          ...state,
          quantityStore: {
            ...state?.quantityStore,
            [payload?.sku]: newDeQty,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case "QUANTITY__CLEAR__STORE":
      return {
        ...state,
        quantityStore: [],
      };

    case "CUSTOMER__INFO":
      return { ...state, customerinfo: payload };

    case "CHANGE__PASSWORD":
      return { ...state, changepasswordstatus: payload }

    case "PLP__BREADCRUMBS":
      return { ...state, breadcrumbs: payload }

    case "PLP__FILTER__DATA":
      return { ...state, plpfilterdata: payload }

    case "PLP__PRODUCT__TYPE":
      return { ...state, plpproducttype: payload }

    case "PLP__COLORS":
      return { ...state, plpcolors: payload }

    case "PLP_SUB_BRANDS":
      return { ...state, plpsubbrands: payload }

    case "PLP_FOOTER":
      return { ...state, plpfooter: payload }

    case "PLP__LISTING":
      return { ...state, plplisting: payload }

    case "FORGOT__PASSWORD":
      return { ...state, forgotpasswordStatus: payload }

    case "PLP_BANNER__IMAGE":
      return { ...state, plpbannerimage: payload }

    case "PLP_TITLE":
      return { ...state, plptitle: payload }

    case "PLP_ALTTAG":
      return { ...state, plpAltTag: payload }

    case "PLP_HEADER":
      return { ...state, plpheader: payload }

    case "PLP_META":
      return { ...state, plpmeta: payload }

    case "PLP_SEODATAS":
      return { ...state, seodatas: payload }

    case "REGISTER__CONTENT":
      return { ...state, registercontent: payload }

    case "ACCOUNT__INFO":
      return { ...state, accountinfo: payload }

    case "MY__ORDERS":
      return { ...state, myorders: payload }

    case "ORDER__DETAILS":
      return { ...state, orderdetails: payload }

    case "ORDER__SEARCH":
      return { ...state, ordersearch: payload }

    case "MY__INVOICES":
      return { ...state, myinvoices: payload }

    case "MY__INVOICES__SEARCH":
      return { ...state, invoicesearch: payload }

    case "MY__CATALOGS":
      return { ...state, mycatalog: payload }

    case "MY__LAUNCH":
      return { ...state, mylaunch: payload }

    case "CONTACTUS__DATA":
      return { ...state, contactUsData: payload }

    case "CMSPAGE__DATA":
      return { ...state, cmsPageData: payload }

    case "ABOUTBANNER__DATA":
      return { ...state, aboutBannerData: payload };

    case "ABOUTCONTENT__DATA":
      return { ...state, aboutContentData: payload };

    case "ABOUTGALLERY__DATA":
      return { ...state, aboutGalleryData: payload };

    case "CONTACT_FORM_MESSAGE":
      return {
        ...state,
        contactFormData: {
          ...state?.contactFormData,
          errorInfo: "",
          successMsg: payload?.message,
        },
      };

    case "CONTACT_FORM_RESET":
      return {
        ...state,
        contactFormData: {
          ...state?.contactFormData,
          errorInfo: "",
          successMsg: "",
        },
      };

    case "ADDRESS__LIST":
      return { ...state, addressList: payload }

    case "CARTDATA__LIST":
      return {
        ...state,
        cartData: {
          ...state?.cartData,
          [payload?.cart_data[0]?.product_sku]: payload?.cart_data[0],
        },
      };

    case "LANGSWITCH__URL":
      if (payload?.lang) {
        return {
          ...state,
          langSwitchUrl: {
            ...state?.langSwitchUrl,
            [payload?.lang]: payload?.url,
          },
        };
      } else {
        return {
          ...state,
        };
      }


    case "REMOVE_FROM_CART":
      const deleteCart = state?.cartData;

      delete deleteCart[payload];
      return {
        ...state,
        cartData: { ...deleteCart },
      };

    case "CLEAR_CART":
      return {
        ...state,
        cartData: [],
      };

    case "ADDRESS__STATUS":
      return { ...state, addressStatus: payload }

    case "CURRENT__BILLING":
      return { ...state, currentbilling: payload }

    case "CURRENT__SHIPPING":
      return { ...state, currentshipping: payload }

    case "SEARCH__DATA":
      return { ...state, searchData: payload }

    case "COUPON_CODE_SUCCESS":
      return {
        ...state,
        promoCodeData: {
          ...state?.promoCodeData,
          successMsg: payload,
          errorInfo: ""
        },
      };

    case "COUPON_CODE_FAILD":
      return {
        ...state,
        promoCodeData: {
          ...state?.promoCodeData,
          successMsg: "",
          errorInfo: payload
        },
      };

    case "COUPON_CODE_RESET":
      return {
        ...state,
        promoCodeData: {
          ...state?.promoCodeData,
          successMsg: "",
          errorInfo: ""
        },
      };

    case "PLACE_ORDER":
      return {
        ...state,
        placeOrderData: payload,
        cartData: [],
      };

    case "LOADER__TRIGGER":
      return {
        ...state,
        loadertrigger: payload
      }

    case "PAYMENT__SELECT":
      return {
        ...state,
        paymentselect: payload
      }

    case "GUEST__ADDRESS__LIST":
      return {
        ...state,
        guestlist: payload
      }

    case "CATEGORYPAGE_APIDATA":
      return {
        ...state,
        categoryPageApiData: payload
      }

    case "BRANDPAGE_APIDATA":
      return {
        ...state,
        brandPageApiData: payload
      }

    case "CMSSecurePayemt__DATA":
      return {
        ...state,
        SecurePayemtData: payload
      }

    case "CMSSShipping__DATA":
      return {
        ...state,
        CMSSShippingData: payload
      }

    case "CMSLegalNotice__DATA":
      return {
        ...state,
        CMSLegalNoticeData: payload
      }

    case "CMSCookies__DATA":
      return {
        ...state,
        CMSCookiesData: payload
      }

    default:
      return state;
  }
};
