import axios from "axios";

export const applyCouponCode = (couponCode, totalPrice, languageCode) => {
    try {
        return async (dispatch) => {

            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/apply_promocode/${languageCode}?coupon_code=${couponCode}&sub_total=${totalPrice}`
            })

            if (data?.data?.success) {
                dispatch({ type: "COUPON_CODE_SUCCESS", payload: data?.data?.message });
            } else {
                dispatch({ type: "COUPON_CODE_FAILD", payload: data?.data?.message });
            }
        };
    } catch (error) {
        console.log(`Error has occurred!`);
    }
};

export const couponMgsReset = () => {
    return {
        type: "COUPON_CODE_RESET",
    };
};

export const placeOrderAction = (CustomerGroup, userData, languageCode, cartDataList, cartIdList, subTotalPrice, totalPrice, discount, couponCode, paymentMethod, currentshipping, currentbilling, navigate, addPlaceOrderEvent) => {
    return async (dispatch) => {
        if (languageCode) {
            try {
                const priceList = userData?.price_list ? userData?.price_list : '';
                const data = await axios({
                    method: 'post',
                    url: `${process.env.REACT_APP_BASE_API_URL}/place_order`,
                    data: {
                        lang: languageCode,
                        user_id: userData?.userid != undefined ? userData?.userid : "",
                        cart_id: cartIdList,
                        user_type: CustomerGroup,
                        price_list: priceList,
                        sub_total: subTotalPrice,
                        price: totalPrice,
                        discount: discount,
                        apply_code: couponCode,
                        payment_method: paymentMethod,
                        payment_status: "success",
                        billing_first_name: currentbilling?.first_name,
                        billing_last_name: currentbilling?.last_name,
                        billing_email: currentbilling?.email,
                        billing_telephone: currentbilling?.telephone,
                        billing_address_1: currentbilling?.address_1,
                        billing_city: currentbilling?.city,
                        billing_state: currentbilling?.state,
                        billing_country_id: currentbilling?.country_id,
                        billing_postal_code: currentbilling?.postal_code,
                        shipping_first_name: currentshipping?.first_name,
                        shipping_last_name: currentshipping?.last_name,
                        shipping_email: currentshipping?.email,
                        shipping_telephone: currentshipping?.telephone,
                        shipping_address_1: currentshipping?.address_1,
                        shipping_city: currentshipping?.city,
                        shipping_state: currentshipping?.state,
                        shipping_country_id: currentshipping?.country_id,
                        shipping_postal_code: currentshipping?.postal_code,
                        billing_address_id: currentbilling?.address_id,
                        shipping_address_id: currentshipping?.address_id
                    }
                })

                if (data?.data?.success === true) {

                    let placeOrderData = {
                        customerGroup: CustomerGroup,
                        userData: userData,
                        cartDataList: cartDataList,
                        subTotalPrice: subTotalPrice,
                        totalPrice: totalPrice,
                        discount: discount,
                        couponCode: couponCode,
                        paymentMethod: paymentMethod,
                        currentshipping: currentshipping,
                        currentbilling: currentbilling,
                        languageCode: languageCode,
                        orderData: data?.data
                    }

                    addPlaceOrderEvent(placeOrderData);

                    dispatch({ type: "PLACE_ORDER", payload: data?.data });
                    navigate(`/${languageCode}/thankyou`);
                }
                // }else {

                // }
            } catch (e) {
                console.log(e);
            }
        }
    }
};

export const PaymentSelection = (currentPayment) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/fetchpaymentdetails/${currentPayment}`
            })
            dispatch({ type: "PAYMENT__SELECT", payload: data?.data?.payment_method });
        } catch (e) {
            console.log(e)
        }
    }
}

