import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword, getAccountInfo } from '../../../redux/features/MyAccount/Action'
import './Accounts.scss'
import { useTranslation } from "react-i18next";
import Loader from '../../Loader/Loader.js'
import { toast } from 'react-toastify';

function Accounts() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [passwordValidation, setPasswordValidation] = useState(false)
  const [formValidation, setFormValidation] = useState({
    old: false,
    new: false,
    confirm: false
  })

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  // Change password request Status
  const changepasswordstatus = useSelector((state) => state?.changepasswordstatus)
  // Account Info data
  const accountinfo = useSelector((state) => state?.accountinfo)

  useEffect(() => {
    toast.info(changepasswordstatus?.message)
  }, [changepasswordstatus])

  useEffect(() => {
    setLoader(false)
  }, [accountinfo])

  const submitHandler = e => {
    e.preventDefault()
    let user = JSON.parse(localStorage.getItem('userdata'))
    setFormValidation({
      old: false,
      new: false,
      confirm: false
    })
    
    if(e.target[0].value?.length === 0) setFormValidation(prevState => ({ ...prevState, old: true }))
    if(e.target[1].value?.length === 0) setFormValidation(prevState => ({ ...prevState, new: true }))
    if(e.target[2].value?.length === 0) setFormValidation(prevState => ({ ...prevState, confirm: true }))
    if(e.target[1].value === "") {
      return toast.info(t("password_blank_validation"));
    } else if(e.target[1].value !== e.target[2].value) {
      return toast.info(t("match_password_validation"));
      setPasswordValidation(true)
    } else {
      setPasswordValidation(false)
      dispatch(changePassword(user?.userid, e.target[0].value, e.target[1].value, e.target[1].value, languageCode))
    }
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    dispatch(getAccountInfo(user?.userid, languageCode))
    setLoader(true)
  }, [])

  return (
    <div className="accounts">
      {loader ? <Loader /> :
        <>
          <div className='accounts__heading' dangerouslySetInnerHTML={{ __html: accountinfo?.my_account_heading?.content }} />
            <div className="accounts__container">
              <table className="accounts__info">
                <thead>
                  <tr>{t("account_info")}</tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <span className="accounts__label">{t("company_name")}:</span>
                        <span className="accounts__value">{accountinfo?.my_account?.company}</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <span className="accounts__label">{t("company_id")}:</span>
                        <span className="accounts__value">{accountinfo?.my_account?.erp_customer_id}</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <span className="accounts__label">{t("default_language")}:</span>
                        <span className="accounts__value">{accountinfo?.my_account?.default_language}</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <span className="accounts__Info">{t("contact_person")}</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <span className="accounts__label">{t("Name")}:</span>
                        <span className="accounts__value">{accountinfo?.my_account?.first_name}</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <span className="accounts__label">{t("surname")}:</span>
                        <span className="accounts__value">{accountinfo?.my_account?.last_name}</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <span className="accounts__label">{t("Email")}:</span>
                        <span className="accounts__value">{accountinfo?.my_account?.email}</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <span className="accounts__label">{t("phoneNumber")}:</span>
                        <span className="accounts__value">{accountinfo?.my_account?.telephone}</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <span className="accounts__label">{t("password")}:</span>
                        <span className="accounts__value">******</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              
              <div className="accounts__changepassword">
                <h5>{t("change_password")}</h5>
                <form className="accounts__changepassword__form" onSubmit={e => submitHandler(e)}>
                  <input placeholder={`${t("current_password_input")}`} type="password" />
                  {formValidation?.old && <p className="error">{t("current_password_validation")}</p>}

                  <input placeholder={`${t("reset_password_input")}`} type="password"/>
                  {formValidation?.new && <p className="error">{t("new_password_validation")}</p>}

                  <input placeholder={`${t("confirm_reset_password_input")}`} type="password"/>
                  {formValidation?.confirm && <p className="error">{t("confirm_blank_validation")}</p>}
                  <button type="submit" aria-label='submit'>{t("confirm")}</button>
                </form>
              </div>
            </div> 
        </>
      }
    </div>
  )
}

export default Accounts