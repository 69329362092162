import React, { useEffect, useMemo, useState } from 'react'
import { getInvoices, getInvoiceSearch } from '../../../redux/features/MyAccount/Action'
import './Invoices.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch, useParams } from 'react-router'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from "react-i18next";
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';

function Invoices() {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [searchID, setSearchID] = useState()
  const [invoiceLoader, setInvoiceLoader] = useState(false)
  const { lg } = useParams()
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    dispatch({ type: "MY__INVOICES__SEARCH", payload: '' });
    dispatch(getInvoices(lg, user?.userid))
  }, [])

  const myinvoices = useSelector(state => state?.myinvoices)
  const invoicesearch = useSelector(state => state.invoicesearch)

  useEffect(() => {
    setInvoiceLoader(false)
  }, [myinvoices?.my_invoice, invoicesearch?.myinvoice_search])

  // Pagination logic
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = invoicesearch?.myinvoice_search?.slice(indexOfFirstItem, indexOfLastItem) || myinvoices?.my_invoice?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const pageCount = Math.ceil((invoicesearch?.myinvoice_search?.length || myinvoices?.my_invoice?.length) / itemsPerPage);

  // invoice search api call on Key Enter
  const handleEnter = (e) => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    if (e.code === "Enter") {
      setInvoiceLoader(true)
      if (searchID === "" || searchID === undefined) {
        dispatch({ type: "MY__INVOICES__SEARCH", payload: '' });
        dispatch(getInvoices(lg, user?.userid))
      } else {
        dispatch(getInvoiceSearch(lg, user?.userid, searchID))
      }
    }
  }

  // invoice search api call when search button clicked
  const searchInvoice = () => {
    let user = JSON.parse(localStorage.getItem('userdata'))
    setInvoiceLoader(true)
    if (searchID === "" || searchID === undefined) {
      dispatch({ type: "MY__INVOICES__SEARCH", payload: '' });
      dispatch(getInvoices(lg, user?.userid))
    } else {
      dispatch(getInvoiceSearch(lg, user?.userid, searchID))
    }
  }

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const sortedOrders = currentItems?.sort((a, b) => {
    if (sortBy) {
      if (a[sortBy] < b[sortBy]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  // Use Memo for Invoice Table
  const invMemo = useMemo(() => {
    return invoiceLoader ? (
      <tbody>
        <tr>
          <td colSpan="6">
            <Skeleton className='plp__filter__skeleton' count={4} variant="rectangular" animation="wave" height={36} />
          </td>
        </tr>
      </tbody>
    ) : (
      <tbody>
        {sortedOrders?.length === 0 && !invoiceLoader ? (
          <tr>
            <td colSpan="6">{t('no_data_available')}</td>
          </tr>
        ) : (
          sortedOrders?.map(inv => (
            <tr key={inv.invoice_number}>
              <td>{inv.invoice_number}</td>
              <td>{inv.invoice_date}</td>
              <td className='invoices__pdf'>
                <a target="_blank" href={inv?.download}><BsFillFileEarmarkPdfFill /></a>
              </td>

              {/* <td>{inv.downloaded_date}</td> */}
              <td>{inv.status}</td>
              <td>{inv.amount}</td>
            </tr>
          ))
        )}
      </tbody>
    );
  }, [sortedOrders, invoiceLoader, sortBy, sortOrder]);

  return (
    <div className='invoices'>
      <div className='invoices__heading' dangerouslySetInnerHTML={{ __html: myinvoices?.my_invoice_heading?.content }} />
      <label>
        <input type="search" placeholder='Search Order' onChange={e => setSearchID(e.target.value)} onKeyDown={handleEnter} />
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-search" viewBox="0 0 16 16" onClick={searchInvoice}>
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </span>
      </label>

      <div className='invoices__container'>
        <div className='invoices__pagination'>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
        <table className='invoices__table'>
          <thead>
            <tr>
              <td onClick={() => handleSort('invoice')}>
                {t("invoice")}
                <span className={sortBy === 'invoice' && sortOrder === 'asc' && 'asc'}>▲</span>
                <span className={sortBy === 'invoice' && sortOrder === 'desc' && 'desc'}>▼</span> 
              </td>
              <td onClick={() => handleSort('invoice_date')}>
                {t("invoice_date")}
                <span className={sortBy === 'invoice_date' && sortOrder === 'asc' && 'asc'}>▲</span>
                <span className={sortBy === 'invoice_date' && sortOrder === 'desc' && 'desc'}>▼</span> 
              </td>
              <td>
                {t("download")}
              </td>
              {/* <td onClick={() => handleSort('downloaded_date')}>
                {t("downloaded_date")}
                <span className={sortBy === 'downloaded_date' && sortOrder === 'asc' && 'asc'}>▲</span>
                <span className={sortBy === 'downloaded_date' && sortOrder === 'desc' && 'desc'}>▼</span>   
              </td> */}
              <td onClick={() => handleSort('status')}>
                {t("status")}
                <span className={sortBy === 'status' && sortOrder === 'asc' && 'asc'}>▲</span>
                <span className={sortBy === 'status' && sortOrder === 'desc' && 'desc'}>▼</span> 
              </td>
              <td onClick={() => handleSort('amount')}>
                {t("amount")}
                <span className={sortBy === 'amount' && sortOrder === 'asc' && 'asc'}>▲</span>
                <span className={sortBy === 'amount' && sortOrder === 'desc' && 'desc'}>▼</span>   
              </td>
            </tr>
          </thead>
          {invMemo}
        </table>
      </div>
    </div>
  )
}

export default Invoices