import React from 'react'
import './Loader.scss'
import LoaderImg from '../../assects/loader.svg'

function Loader() {
  return (
    <>
      <div className='loader'></div>
      <div class="loader__container">
        <div class="item item-1">
          <img src={LoaderImg} alt="" />
        </div>
        
        <div class="item item-2">
        <img src={LoaderImg} alt="" />
        </div>
        
        <div class="item item-3">
          <img src={LoaderImg} alt="" />
        </div>
        
        <div class="item item-4">
          <img src={LoaderImg} alt="" />
        </div>
      </div>
    </>
  )
}

export default Loader