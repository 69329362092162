import React, { useEffect, Suspense } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropdown/LanguageDropdown";
import { useDispatch, useSelector } from "react-redux";
import { FaUser, FaBars } from "react-icons/fa";
import Minicart from "./Minicart/Minicart";
import './Header.scss';
import Skeleton from 'react-loading-skeleton'
import { getMegaMenuList, getNewSubMenuList, sidenavClose, sidenavOpen } from "../../redux/features/Header/Action";
import SubMenu from "./SubMenu/SubMenu";
import { deleteToken, setToken, updateToken } from "../../redux/features/Register/Action";
import Logo from "./Logo/Logo";
import { Dropdown } from 'react-bootstrap';
import MobileMenu from "./MobileMenu/MobileMenu";
import { toast } from "react-toastify";
import Search from "./Search/Search";
import { BsCheck2 } from "react-icons/bs";

function Header({ lgChange }) {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const getMenuData = useSelector(
    (state) => state?.megaMenu?.mega_menu
  )

  const getSubMenuData = useSelector(
    (state) => state?.subMenu
  )

  const getUserData = useSelector(
    (state) => state?.userdata
  )

  const getMiniSearchData = useSelector(
    (state) => state?.miniSearchData
  )

  const logOut = () => {
    dispatch(deleteToken(navigate, languageCode, toast, t));
    dispatch({ type: "CURRENT__SHIPPING", payload: '' });
    dispatch({ type: "CURRENT__BILLING", payload: '' });
    dispatch({ type: "CLEAR_CART" });
  };

  const getSubMenu = (id, slug, languageCode, userGroup) => {
    dispatch(getNewSubMenuList(id, slug, languageCode, userGroup));
  }

  useEffect(() => {
    if (getUserData?.userid) {
      dispatch(updateToken(getUserData, languageCode));
    }
  }, [location]);

  useEffect(() => {
    if (languageCode) {
      document.documentElement.lang = languageCode;
    }
  }, [languageCode])

  useEffect(() => {
    if (languageCode) {
      i18n.changeLanguage(languageCode);
      dispatch(getMegaMenuList(languageCode, getUserData));
    }
  }, [languageCode, getUserData])

  useEffect(() => {
    let userDatas = JSON.parse(localStorage.getItem('userdata'));

    if (!getUserData?.success && userDatas?.success) {
      dispatch(setToken(userDatas));
    }
    // dispatch(getSubMenuList(getMenuData, languageCode, getUserData?.group));
  }, [getMenuData, getUserData])

  useEffect(() => {
    dispatch(sidenavClose());
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch({ type: "FORGOT__PASSWORD", payload: '' })
  }, [window.location.pathname])

  useEffect(() => {
    dispatch({ type: "LOADER__TRIGGER", payload: false });
  }, [])

  const RouteAccount = () => {
    navigate(`/${languageCode}/myaccount`)
  }

  return (
    <Suspense fallback={null}>
      <div className="Header__Section">
        <div className="Header__top">
          <div className="Header__container pt-1 pb-1">
            <div className="d-flex justify-content-between align-items-center Header__top__block">
              <div className="Header__top__left">
                <p className="mb-0 Header__txt">
                  {t("KARACTERMANIA")}
                </p>
              </div>
              <div className="Header__top__right">
                <div className="Header__top__action">

                  <div className="Header__Link top__action">
                    <Link to={`/${languageCode}/aboutus`} className="aboutus" title={t("About_us")}>
                      {t("About_us")}
                    </Link>
                  </div>

                  {getUserData?.success
                    ? (
                      <div className="Header__user top__action">
                        <Dropdown>
                          <Dropdown.Toggle as="div" id="dropdown-basic" className='d-flex align-items-center justify-content-center cursor-pointer'>
                            <FaUser className="User__auth" />
                            <span className="base__user">
                              <BsCheck2 />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => RouteAccount()} key="1">
                              <span>{t("my_account")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" key="2" onClick={() => logOut()}>
                              <span>{t("logout")}</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )
                    : (
                      <div className="Header__user top__action">
                        <Link
                          to={`/${languageCode}/login`}
                          className="d-flex align-items-center justify-content-center"
                          title="User"
                        >
                          <FaUser className="User__auth" />
                        </Link>
                      </div>
                    )
                  }

                  <div className="minicart__section top__action shopping-cart">
                    <Minicart />
                  </div>

                  <div className="language__dropdown top__action">
                    <LanguageDropdown lgChange={lgChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Header__bottom">
          <div className="Header__container pt-3 pb-3">
            <div className="Header__logo">
              <button className="navbar__toggler" aria-label="Toggle navigation" onClick={() => dispatch(sidenavOpen())}>
                <FaBars className="User__navbar" />
              </button>
              <Logo />
            </div>
            <div className="Header__menu">
              <div className={`Header__menu__block ${getMiniSearchData}`}>
                <ul className="Header__menu__navbar me-auto mb-2 mb-lg-0">
                  {getMenuData ?
                    (
                      <>
                        {getMenuData?.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <li className="nav-item dropdown has-megamenu main__menu" key={i}>
                                <Link
                                  to={`/${languageCode}/${data?.slug_name}`}
                                  className="text-decoration-none nav-link main__menu__link"
                                  aria-expanded="false"
                                  onMouseEnter={() => getSubMenu(data?.id, data?.slug_name, languageCode, getUserData?.group)}
                                >
                                  {data?.page_title}
                                </Link>
                                <SubMenu subMenu={getSubMenuData[data?.slug_name]} menuData={data?.slug_name}> </SubMenu>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                        </li>
                        <li className="nav-item skeleton__li">
                          <Skeleton variant="rectangular" animation="wave" width={150} height={20} />
                        </li>
                      </>
                    )
                  }
                </ul>
              </div>
            </div>
            <MobileMenu />
            <div className="Header__search">
              <Search />
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default Header