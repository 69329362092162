import React, { Suspense, startTransition, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getHomePage, getHomePageSeoData } from '../../redux/features/Home/Action';
import { Helmet } from "react-helmet";
const CharacterSliderBlock = React.lazy(() => import('./CharacterSliderBlock/CharacterSliderBlock'));
const RegisterAndDiscover = React.lazy(() => import('./RegisterAndDiscover/RegisterAndDiscover'));
const ProductCategories = React.lazy(() => import('./ProductCategories/ProductCategories'));
const LicensedProduct = React.lazy(() => import('./LicensedProduct/LicensedProduct'));
const HeroBanner = React.lazy(() => import('../../Components/HeroBanner/HeroBanner'));
const BestSeller = React.lazy(() => import('./BestSeller/BestSeller'));
const Brand = React.lazy(() => import('./Brand/Brand'));
const FAQ = React.lazy(() => import('./FAQ/FAQ'));

function Home() {
  const dispatch = useDispatch();
  const { lg } = useParams();
  const [singleLoad, setSingleLoad] = useState(false);
  const [currentlang, setCurrentlang] = useState();
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const getHomePageData = useSelector(
    (state) => state?.homePageData
  )

  const getUserData = useSelector(
    (state) => state?.userdata
  )

  const homeSeoData = useSelector(
    (state) => state?.homeSeoData
  )
  const [alterNative, setAlterNative] = useState();

  useEffect(() => {
    if (homeSeoData?.meta_details?.alternative_url?.length > 0) {
      setAlterNative(homeSeoData?.meta_details?.alternative_url);
    }
  }, [homeSeoData])

  useEffect(() => {
    const langCode = languageCode ? languageCode : lg;
    setCurrentlang(langCode);
    dispatch(getHomePage(getUserData, langCode));
    dispatch(getHomePageSeoData(langCode));
  }, [languageCode, getUserData]);

  useEffect(() => {
    if (!singleLoad) setSingleLoad(true)
  }, [singleLoad]);

  const alterNativeurlData = alterNative?.map((value, k) => {
    return (
      <link rel="alternate" hreflang={value?.lang} href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${value?.lang}`} />
    )
  });

  return (
    <Suspense fallback={null}>
      {singleLoad ? (
        <Helmet>
          {/* title Tag */}
          {homeSeoData?.seo_tag_content?.meta_title &&
            <title>{homeSeoData?.seo_tag_content?.meta_title}</title>
          }

          {/* Meta tag */}
          {homeSeoData?.seo_tag_content?.language &&
            <meta name="language" content={homeSeoData?.seo_tag_content?.language} />
          }

          {homeSeoData?.seo_tag_content?.meta_title &&
            <meta name="title" content={homeSeoData?.seo_tag_content?.meta_title} />
          }

          {homeSeoData?.seo_tag_content?.meta_description &&
            <meta name="description" content={homeSeoData?.seo_tag_content?.meta_description} />
          }

          {homeSeoData?.seo_tag_content?.meta_keywords &&
            <meta name="keywords" content={homeSeoData?.seo_tag_content?.meta_keywords} />
          }

          {homeSeoData?.seo_tag_content?.robots &&
            <meta name="robots" content={homeSeoData?.seo_tag_content?.robots} />
          }

          {homeSeoData?.seo_tag_content?.author &&
            <meta name="author" content={homeSeoData?.seo_tag_content?.author} />
          }

          {homeSeoData?.seo_tag_content?.publisher &&
            <meta name="publisher" content={homeSeoData?.seo_tag_content?.publisher} />
          }

          {/* Meta OG tag */}
          <meta property="og:site_name" content="KARACTERMANIA" />
          <meta property="og:type" content="website" />
          {homeSeoData?.seo_tag_content?.meta_title &&
            <meta property="og:title" content={homeSeoData?.seo_tag_content?.meta_title} />
          }
          {homeSeoData?.seo_tag_content?.meta_description &&
            <meta property="og:description" content={homeSeoData?.seo_tag_content?.meta_description} />
          }
          <meta name="og:url" content={`${process.env.REACT_APP_FRONTEND_BASE_URL}/`} />

          {/* canonical links */}
          <link rel="canonical" href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${currentlang}`} />
          <link rel="alternate" hreflang="x-default" href={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${currentlang}`} />
          {alterNativeurlData}
        </Helmet>
      ) : (<></>)}

      <div className='Home__container Main__section'>
        <HeroBanner />

        <LicensedProduct
          licenseContent={getHomePageData?.license_block_content}
          licenseData={getHomePageData?.license_block}
          licenseLink="category"
        />

        <CharacterSliderBlock
          charactersContent={getHomePageData?.characters_block_content}
          charactersData={getHomePageData?.characters_block}
          charactersLink="Home"
          isThisHome={true}
        />

        <LicensedProduct
          licenseContent={getHomePageData?.main_category_content}
          licenseData={getHomePageData?.main_category}
          licenseLink="mainCategory"
        />

        <ProductCategories
          Content={getHomePageData?.product_type_block_content}
          Data={getHomePageData?.product_type_block}
          cactegoriesLink="Home"
          isThisHome={true}
        />

        <RegisterAndDiscover
          advantage={getHomePageData?.homepage_advantages_block}
          identifier={getHomePageData?.homepage_b2b_identifier_block}
        />

        <Brand
          brandContent={getHomePageData?.brand_block_content}
          brandData={getHomePageData?.brand_block}
          dataFrom="Home"
        />

        <BestSeller
          sellerContent={getHomePageData?.best_selling_product_block_content}
          sellerData={getHomePageData?.best_selling_product_block}
        />

        <FAQ
          faqContent={getHomePageData?.faq}
          faqData={getHomePageData?.faq?.content}
        />
      </div>
    </Suspense>
  )
}

export default Home
