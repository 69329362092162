import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

export const useCustomerRegisterEvent = props => {
  const addCustomerRegisterEvent = useCallback(async userData => {
    let data = {
      dataLayer: {
        event: 'successful_register',
        FirstName: userData.FirstName,
        LastName: userData.LastName,
        Email: userData.Email,
        Language: userData.Language,
        RegisterType: 'Private Customer'
      }
    };

    console.log('GTM_EVENT successful_Register',data);
    
    TagManager.dataLayer(data);
  });

  return {
    addCustomerRegisterEvent
  };
};
