import React, { useEffect, Suspense } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from 'react-bootstrap';
import { getLanguageList, languageNav, setCurrentLanguage, setSelectedLanguage } from "../../../redux/features/Header/Action";
import './LanguageDropdown.scss';

export default function LanguageDropdown({ lgChange }) {
  const { lg } = useParams();
  const urlslugs = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const languageListData = useSelector(
    (state) => state?.languageList
  );

  const langSwitchUrlData = useSelector(
    (state) => state?.langSwitchUrl
  );

  // When Language get selected, updating it in LocalStorage and Redux Store
  const browserLanguage = (value, switchUrlData) => {
    localStorage.setItem('languageCode', value.code);
    localStorage.setItem('languageData', JSON.stringify(value));
    dispatch(setCurrentLanguage(value.code));
    dispatch(setSelectedLanguage(value));
    let currenturl = window.location.pathname;
    let replacelg = `/${value.code}/`;

    let newValue = switchUrlData[value?.code] ? switchUrlData[value?.code] : '';

    if (lgChange === "lgChange") {
      if (newValue) {
        navigate(`/${newValue}`)
        window.location.reload();
      } else {
        navigate(`${replacelg}`)
        window.location.reload();
      }
    } else {
      navigate(replacelg + currenturl.substring(4))
    }
  };

  // API Call for Language List on Page load
  useEffect(() => {
    dispatch(getLanguageList());
  }, [])

  // To Check, Whether the Language code is Wrong
  useEffect(() => {
    if (languageListData?.length > 0) {
      const slugLanguage = languageListData?.filter(
        (lag) => lag.code === lg
      );

      if (slugLanguage?.length === 0) {
        navigate(-1)
      }

      // On Page Refresh, get the data from LocalStorage if any and updating Redux Store.
      const storeLanguageCode = localStorage.getItem('languageCode');
      const storeLanguageData = JSON.parse(localStorage.getItem('languageData'));

      if (storeLanguageCode && storeLanguageCode === lg) {
        dispatch(setCurrentLanguage(storeLanguageCode));
        dispatch(setSelectedLanguage(storeLanguageData))
      }

      // On Initial Load, setting the default lanuage based on User id.
      if (!storeLanguageCode) {

        const availableLanguage = languageListData?.filter(
          (lag) => lag.code === process.env.REACT_APP_BASE_LANGUAGE
        );

        localStorage.setItem('languageCode', process.env.REACT_APP_BASE_LANGUAGE);
        localStorage.setItem('languageData', JSON.stringify(...availableLanguage));

        dispatch(setCurrentLanguage(process.env.REACT_APP_BASE_LANGUAGE));
        dispatch(setSelectedLanguage(...availableLanguage));
      }

      if (storeLanguageCode !== lg) {
        localStorage.setItem('languageCode', lg);
        localStorage.setItem('languageData', JSON.stringify(...slugLanguage));

        dispatch(setCurrentLanguage(process.env.REACT_APP_BASE_LANGUAGE));
        dispatch(setSelectedLanguage(...slugLanguage));
      }
    }
  }, [languageListData])

  // Language Data
  const selectedLanguage = useSelector(
    (state) => state?.selectedLanguage
  );

  return (
    <Suspense fallback={null}>
      <Dropdown>
        <Dropdown.Toggle as="div" id="dropdown-basic" className='d-flex align-items-center justify-content-center cursor-pointer'>
          <div className='d-flex align-items-center'>
            <img className='languageImg'
              src={`${process.env.REACT_APP_BASE_URL}/${selectedLanguage?.flag}`}
              alt="image"
              loading="eager"
              width={24}
              height={24}
              title={selectedLanguage?.label}
            />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {languageListData?.map(LD =>
            <Dropdown.Item href="#" key={LD?.id} onClick={() => browserLanguage(LD, langSwitchUrlData)}>
              <div className='d-flex align-items-center'>
                <img className='me-2' src={`${process.env?.REACT_APP_BASE_URL}/${LD?.flag}`}
                  alt="image"
                  width={24}
                  height={24}
                  loading="eager"
                />
                <span>{LD?.label}</span>
              </div>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Suspense>
  )
}
