import React, { Suspense, useState } from 'react';
import './FAQ.scss';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

function FAQ(data) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const CustomExpandIcon = () => {
    return (
      <Box sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
      >
        <div className="expandIconWrapper">-</div>
        <div className="collapsIconWrapper">+</div>
      </Box>
    );
  };

  return (
    <Suspense fallback={null}>
      <div className='FAQ__section'>
        <div className='FAQ__block'>
          <div className="FAQ__content mb2">
            <div className='FAQ__title'>
              <span>{data?.faqContent?.title}</span>
            </div>

            {data?.faqContent?.identifier === "homepage_faq" && <div className='contactusParent' style={{ textAlign: "center" }}>
              <div dangerouslySetInnerHTML={{ __html: data?.faqContent?.contact_us }}></div>
            </div>}

          </div>
          <div className="FAQ__according">
            {data?.faqData?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="accordion-cont">
                    <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                      <AccordionSummary expandIcon={<CustomExpandIcon />} aria-controls={`panel${i}bh-content`} id={`panel${i}bh-content`} >
                        <Typography className="faqheading">
                          {e?.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: e?.answer }}></Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </React.Fragment>
              );
            })}
          </div>

          {data?.faqContent?.identifier === "clp_faq" && <div className='contactusParent' style={{ textAlign: "center" }}>
            <div dangerouslySetInnerHTML={{ __html: data?.faqContent?.contact_us }}></div>
          </div>}

        </div>
      </div>
    </Suspense>
  )
}

export default FAQ;