import React, { useEffect, useRef, useState } from 'react';
import './ProductDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getProductDetailsPage } from '../../redux/features/ProductDetails/Action';
import ProductBreadcrumbs from './ProductBreadcrumbs/ProductBreadcrumbs';
import SliderImage from 'react-zoom-slider';
import { useTranslation } from 'react-i18next';
import QuantityBox from '../../Components/QuantityBox/QuantityBox';
import { HiShoppingCart } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import BestSeller from '../Home/BestSeller/BestSeller';
import { toast } from 'react-toastify';
import { addToCartAction } from '../../redux/features/QuantityBox/Action';
import { Helmet } from 'react-helmet';
import { IoIosArrowBack } from "react-icons/io";
import SeoContent from '../../Components/SeoContent/SeoContent';
import LangSwitchUrl from '../../Components/SeoContent/LangSwitchUrl';

function ProductDetails() {
  const dispatch = useDispatch();
  const slugName = useParams();
  const { t } = useTranslation();
  const flyCartRef = useRef();
  const navigate = useNavigate();

  const [flyCartImg, setFlyCartImg] = useState();
  const [productDetails, setProductDetails] = useState();
  const [productShipping, setProductShipping] = useState();
  const [imageData, setImageData] = useState([]);
  const [seoContent, setSeoContent] = useState();
  const [alterNative, setAlterNative] = useState();

  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const getUserData = useSelector(
    (state) => state?.userdata
  );

  const linearDiscount = getUserData?.linear_discount ? getUserData?.linear_discount : 0;

  const slugSku = slugName?.slugName?.split("-")?.pop();

  // customer group
  const CustomerGroup = getUserData?.group ? getUserData?.group : "general";

  const getProductDetailsData = useSelector(
    (state) => state?.productDetailsData
  );

  const quantityStore = useSelector(
    (state) => state?.quantityStore
  )

  useEffect(() => {
    dispatch(getProductDetailsPage(getUserData, languageCode, slugName?.slugName, slugSku));
  }, [languageCode, getUserData, slugName])

  useEffect(() => {
    if (getProductDetailsData?.meta_details?.seo_content_details?.meta_title) {
      setSeoContent({ ...getProductDetailsData?.meta_details?.seo_content_details, ...getProductDetailsData?.seo_tag_content });
    }
    if (getProductDetailsData?.meta_details?.alternative_url?.length > 0) {
      setAlterNative(getProductDetailsData?.meta_details?.alternative_url);
    }
  }, [getProductDetailsData?.meta_details])

  useEffect(() => {
    if (getProductDetailsData?.product_details) {
      const deatils = getProductDetailsData?.product_details;
      setProductDetails(deatils);
    }

    if (getProductDetailsData?.shipping_info) {
      const shippinginfo = getProductDetailsData?.shipping_info;
      setProductShipping(shippinginfo);
    }

  }, [getProductDetailsData]);

  useEffect(() => {

    if (productDetails) {
      const splitImages = productDetails?.image?.toString()?.split(",");
      setFlyCartImg(`${process.env.REACT_APP_BASE_PRODUCT_URL}/${splitImages[0]}`)

      const galleryImage = splitImages?.map((value, i) => {
        const newImage = {
          image: `${process.env.REACT_APP_BASE_PRODUCT_URL}/${value}`
        }
        return newImage;
      })
      setImageData(galleryImage);

    }
  }, [productDetails]);

  // pre sale tag
  const preSaleBlock = (
    <>
      {productDetails?.pre_sale == 1 && (<span className="pre__sale">{t("presale")}</span>)}

      {(productDetails?.pre_sale == 0 && CustomerGroup === "retailer" && productDetails?.min_qty_retailer > productDetails?.b2b_qty) &&
        (<span className="pre__sale">{t("presale")}</span>)
      }

      {(productDetails?.pre_sale == 0 && CustomerGroup === "wholesaler" && productDetails?.min_qty_wholesaler > productDetails?.b2b_qty) &&
        (<span className="pre__sale">{t("presale")}</span>)
      }
    </>
  );

  // offer tag
  const offerBlock = (
    <>
      {productDetails?.outlet_status == 1 && (
        <span className="offer__label">{t("offer")}</span>
      )}
    </>
  );

  // price formate
  const priceFormate = (lang, value) => {
    return (
      <>
        {lang === "es" ? (
          <>
            {Number(value).toLocaleString("es-ES", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : lang === "fr" ? (
          <>
            {Number(value).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
            })}
          </>
        ) : (
          <> {Number(value).toFixed(2)}</>
        )}
        <span className="currency">€</span>
      </>
    );
  };

  // discout calculation
  const discount = ((price, outletDiscount, linearDiscount) => {
    const discounts = parseInt(outletDiscount) + parseInt(linearDiscount);
    const value = (price - ((price / 100) * discounts)).toFixed(2);
    return priceFormate(languageCode, value);
  })

  // gust or b2c customer discount
  const discountBlock = (
    <>
      {(CustomerGroup === "retailer" || CustomerGroup === "wholesaler") &&
        <>
          <span className='product__discount'></span>
          <span className='product__price'>
            {priceFormate(languageCode, productDetails?.b2c_price)}
          </span>
        </>
      }

      {(CustomerGroup !== "retailer" && CustomerGroup !== "wholesaler") &&
        <>
          {(productDetails?.outlet_status == 0 && linearDiscount == 0) && (
            <>
              <span className='product__discount'>
                {productDetails?.price_before > productDetails?.b2c_price
                  ? priceFormate(languageCode, productDetails?.price_before)
                  : <></>
                }
              </span>
              <span className='product__price'>
                {priceFormate(languageCode, productDetails?.b2c_price)}
              </span>
            </>
          )}

          {(productDetails?.outlet_status == 1 && linearDiscount == 0) && (
            <>
              <span className='product__discount'>
                {priceFormate(languageCode, productDetails?.b2c_price)}
              </span>
              <span className='product__price'>
                {discount(productDetails?.b2c_price, 20, linearDiscount)}
              </span>
            </>
          )}

          {(productDetails?.outlet_status == 0 && linearDiscount > 0) && (
            <>
              <span className='product__discount'>
                {priceFormate(languageCode, productDetails?.b2c_price)}
              </span>
              <span className='product__price'>
                {discount(productDetails?.b2c_price, 0, linearDiscount)}
              </span>
            </>
          )}

          {(productDetails?.outlet_status == 1 && linearDiscount > 0) && (
            <>
              <span className='product__discount'>
                {priceFormate(languageCode, productDetails?.b2c_price)}
              </span>
              <span className='product__price'>
                {discount(productDetails?.b2c_price, 20, linearDiscount)}
              </span>
            </>
          )}
        </>
      }
    </>
  );

  // retailer or wholesaler price block
  const distributorBlock = ((CustomerGroup === "retailer" || CustomerGroup === "wholesaler") &&
    <>
      {(productDetails?.outlet_status == 0 && linearDiscount == 0) && (
        <>
          <span className='product__discount'>
            {CustomerGroup === "retailer" && productDetails?.price_before > productDetails?.retailer_price
              && priceFormate(languageCode, productDetails?.price_before)
            }
            {CustomerGroup === "wholesaler" && productDetails?.price_before > productDetails?.wholesaler_price
              && priceFormate(languageCode, productDetails?.price_before)
            }
          </span>
          <span className='product__price'>
            {CustomerGroup === "retailer" && priceFormate(languageCode, productDetails?.retailer_price)}
            {CustomerGroup === "wholesaler" && priceFormate(languageCode, productDetails?.wholesaler_price)}
          </span>
        </>
      )}

      {(productDetails?.outlet_status == 1 && linearDiscount == 0) && (
        <>
          <span className='product__discount'>
            {CustomerGroup === "retailer" && priceFormate(languageCode, productDetails?.retailer_price)}
            {CustomerGroup === "wholesaler" && priceFormate(languageCode, productDetails?.wholesaler_price)}
          </span>
          <span className='product__price'>
            {CustomerGroup === "retailer" && discount(productDetails?.retailer_price, productDetails?.discount_retailer, 0)}
            {CustomerGroup === "wholesaler" && discount(productDetails?.wholesaler_price, productDetails?.discount_wholesaler, 0)}
          </span>
        </>
      )}

      {(productDetails?.outlet_status == 0 && linearDiscount > 0) && (
        <>
          <span className='product__discount'>
            {CustomerGroup === "retailer" && priceFormate(languageCode, productDetails?.retailer_price)}
            {CustomerGroup === "wholesaler" && priceFormate(languageCode, productDetails?.wholesaler_price)}
          </span>
          <span className='product__price'>
            {CustomerGroup === "retailer" && discount(productDetails?.retailer_price, 0, linearDiscount)}
            {CustomerGroup === "wholesaler" && discount(productDetails?.wholesaler_price, 0, linearDiscount)}
          </span>
        </>
      )}

      {(productDetails?.outlet_status == 1 && linearDiscount > 0) && (
        <>
          <span className='product__discount'>
            {CustomerGroup === "retailer" && priceFormate(languageCode, productDetails?.retailer_price)}
            {CustomerGroup === "wholesaler" && priceFormate(languageCode, productDetails?.wholesaler_price)}
          </span>
          <span className='product__price'>
            {CustomerGroup === "retailer" && discount(productDetails?.retailer_price, productDetails?.discount_retailer, linearDiscount)}
            {CustomerGroup === "wholesaler" && discount(productDetails?.wholesaler_price, productDetails?.discount_wholesaler, linearDiscount)}
          </span>
        </>
      )}
    </>
  );

  // min order quantity
  const minQty = CustomerGroup === "retailer"
    ? productDetails?.min_qty_retailer
    : CustomerGroup === "wholesaler"
      ? productDetails?.min_qty_wholesaler
      : 1;

  // max order quantity
  const maxQty = (CustomerGroup === "retailer" || CustomerGroup === "wholesaler")
    ? parseInt(productDetails?.b2b_qty) + parseInt(productDetails?.pre_order_qty)
    : productDetails?.b2c_qty;

  // add to cart lable
  const cartLable = (productDetails?.pre_sale == 1
    || (productDetails?.pre_sale == 0 && CustomerGroup === "retailer" && productDetails?.min_qty_retailer > productDetails?.b2b_qty)
    || (productDetails?.pre_sale == 0 && CustomerGroup === "wholesaler" && productDetails?.min_qty_wholesaler > productDetails?.b2b_qty))
    ? t("PreSale")
    : t("AddToCart");


  const addToCartApi = ((product) => {
    const productQty = quantityStore[product?.sku] ? quantityStore[product?.sku] : minQty;
    dispatch(addToCartAction(product, languageCode, productQty, linearDiscount, CustomerGroup, getUserData, toast, flyCartImg, flyCartRef.current, false));
  })

  const showPrice = (data) => {
    if (data === "en") {
      return "RRP";
    } else if (data === "es") {
      return "PVR"
    } else if (data === "fr") {
      return "PVC"
    } else if (data === "it") {
      return "RRP";
    }
  }

  return (
    <div className='Pd__container Main__section'>
      {seoContent &&
        <>
          <SeoContent seoDetails={seoContent} alterNative={alterNative} />
          <LangSwitchUrl alterNative={alterNative} />
        </>
      }


      <div className='Pd__details__section'>
        <ProductBreadcrumbs
          ProductDetailsData={getProductDetailsData}
          languageCode={languageCode}
        />
        <div className='Pd__details__backbutton'>
          <span><button className='Pd__backbutton' onClick={() => navigate(-1)}><IoIosArrowBack />{t("Back")}</button></span>
        </div>
        <div className='Pd__details__container'>
          <div className='Pd__details__block'>
            <div className='Pd__details__gallery'>
              <div className='Pd__details__flytocart'>
                <img src={flyCartImg} width={270} height={270} ref={flyCartRef} />
              </div>
              {imageData?.length > 0 && (
                <SliderImage
                  data={imageData}
                  width="100%"
                  showDescription={true}
                  direction="right"
                />
              )}

              <div className='Pd_product__lable'>
                <div className='product__lable'>
                  {offerBlock}
                  {preSaleBlock}
                </div>
              </div>
            </div>

            <div className='Pd__details__des'>
              <p className="Pd__details__brand">{productDetails?.brand}</p>
              <h1 className="Pd__details__title">{productDetails?.meta_title}</h1>
              <nav className="slantedtabs" style={{ marginTop: "25px" }}>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-features-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-features"
                    type="button"
                    role="tab"
                    aria-controls="nav-features"
                    aria-selected="true"
                    aria-label='navlinkbtn'
                  >
                    <span className="slanted-text">{t("features")}</span>
                  </button>

                  <button
                    className="nav-link"
                    id="nav-shipping-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-shipping"
                    type="button"
                    role="tab"
                    aria-controls="nav-shipping"
                    aria-selected="false"
                    aria-label='navlinkbtn'
                  >
                    <span className="slanted-text">{t("shipping")}</span>
                  </button>

                  <button
                    className="nav-link d-none"
                    id="nav-features-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-features"
                    type="button"
                    role="tab"
                    aria-controls="nav-features"
                    aria-selected="true"
                    aria-label='navlinkbtn'
                  >
                    <span className="slanted-text">{t("features")}</span>
                  </button>
                </div>
              </nav>

              <div className="product__tab__cont" style={{ marginTop: "0px", marginLeft: "1px" }}>
                {/* <nav className="slantedtabs">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="nav-features-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-features"
                      type="button"
                      role="tab"
                      aria-controls="nav-features"
                      aria-selected="true"
                      aria-label='navlinkbtn'
                    >
                      <span className="slanted-text">{t("features")}</span>
                    </button>

                    <button
                      className="nav-link"
                      id="nav-shipping-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-shipping"
                      type="button"
                      role="tab"
                      aria-controls="nav-shipping"
                      aria-selected="false"
                      aria-label='navlinkbtn'
                    >
                      <span className="slanted-text">{t("shipping")}</span>
                    </button>

                    <button
                      className="nav-link d-none"
                      id="nav-features-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-features"
                      type="button"
                      role="tab"
                      aria-controls="nav-features"
                      aria-selected="true"
                      aria-label='navlinkbtn'
                    >
                      <span className="slanted-text">{t("features")}</span>
                    </button>
                  </div>
                </nav> */}
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-features"
                    role="tabpanel"
                    aria-labelledby="nav-features-tab"
                    tabindex="0"
                  >
                    <div className="details-cont">
                      <div className="heading">{t("data_sheet")}</div>
                      <div className="description">
                        <ul className="data-points">
                          {productDetails?.pre_sale == 0 &&
                            CustomerGroup == "retailer" &&
                            productDetails?.min_qty_retailer > productDetails?.b2b_qty ? (
                            <>
                              <li>
                                <div className="item-error m-2">
                                  * {t("PRE-ORDER")}
                                </div>
                              </li>
                            </>
                          ) : productDetails?.pre_sale == 0 &&
                            CustomerGroup == "wholesaler" &&
                            productDetails?.min_qty_wholesaler > productDetails?.b2b_qty ? (
                            <>
                              <li>
                                <div className="item-error m-2">
                                  * {t("PRE-ORDER")}
                                </div>
                              </li>
                            </>
                          ) : productDetails?.pre_sale == 1 ? (
                            <>
                              <li>
                                <div className="item-error m-2">
                                  * {t("PRE-ORDER")}
                                </div>
                              </li>
                            </>
                          ) : (
                            <>
                              <li>
                                <div className="item-success m-2">
                                  * {t("immediate_service")}
                                </div>
                              </li>
                            </>
                          )}
                          <li className="Pd__details__sku"><span>Ref.&nbsp;</span>{productDetails?.sku}</li>
                          <li>{t("ean")}: {productDetails?.ean}</li>
                          <li>{t("product")}: {productDetails?.product_type}</li>
                          <li>{t("color")}: {productDetails?.color}</li>
                          <li>{t("format")}: {productDetails?.format}</li>
                          <li>{t("brand")}: {productDetails?.brand}</li>
                          <li>{t("measure")}: {productDetails?.measures}</li>
                          <li>{t("weight")}: {productDetails?.weight} Kg</li>
                        </ul>
                      </div>
                      <div className="heading">{t("description")}</div>
                      <div className="description">{productDetails?.description}</div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-shipping"
                    role="tabpanel"
                    aria-labelledby="nav-shipping-tab"
                    tabindex="0"
                  >
                    <div className="details-cont">
                      <div className="heading">{t("shipping-details")}</div>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: productShipping?.content }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='Pd__details__action'>
              <div className='Pd__details__action_tab'>
                {(CustomerGroup == "retailer" || CustomerGroup == "wholesaler")
                  ? (<></>)
                  : (
                    <div className="Regdis__identifier">
                      <div className="identifier__block" dangerouslySetInnerHTML={{
                        __html: getProductDetailsData?.b2b_identifier_block_pdp?.content,
                      }}
                      ></div>
                    </div>
                  )
                }
              </div>

              <div className='Pd__details__action_des'>
                <div className='pdpriceblock'>
                  {
                    (CustomerGroup === "retailer" || CustomerGroup === "wholesaler")
                      ? <>
                        <div className="pd__price__block">
                          <div className="pd__price__block__test">
                            {/* {t("client_price")} */}
                            {showPrice(languageCode)}
                          </div>
                          <div className='pd__price__block__price'>
                            <div className='price__block'>
                              <span className='product__discount'></span>
                              <span className='product__price'>
                                {priceFormate(languageCode, productDetails?.b2c_price)}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="pd-vat-b2b">* {t("vat_included")}</div>

                        <div className="pd__price__block pd__price__block_b2b ">
                          <div className="pd__price__block__test">
                            {/* {t("pdp_price")} */}
                            {showPrice(languageCode)}
                          </div>
                          <div className='pd__price__block__price'>
                            <div className='price__block'>
                              <>{distributorBlock}</>
                            </div>
                          </div>
                        </div>

                        <div className="pd-vat">* {t("vat_not_included")}</div>
                      </>

                      : <>
                        <div className="pd__price__block">
                          <div className="pd__price__block__test">
                            {/* {t("price")} */}
                            {showPrice(languageCode)}
                          </div>
                          <div className='pd__price__block__price'>
                            <div className='price__block'>
                              <>{discountBlock}</>
                            </div>
                          </div>
                        </div>
                        <div className="pd-vat">* {t("vat_included")}</div>
                      </>
                  }

                </div>

                <div className='cartinputblock'>
                  <div className='cart__input__block'>
                    <QuantityBox CustomerGroup={CustomerGroup} sku={productDetails?.sku} minQty={minQty} maxQty={maxQty} />
                  </div>

                  <div className='cart__action'>
                    <button className="cart__btn btn__addtocart" id="btn__addtocart" aria-label='btnaddtocart' title={cartLable} onClick={() => addToCartApi(productDetails)}>
                      <HiShoppingCart className="cart__icon" />
                      {" "} {cartLable}
                    </button>
                  </div>
                </div>

                {(CustomerGroup == "retailer" || CustomerGroup == "wholesaler")
                  ? (<></>)
                  : (
                    <div className="Regdis__identifier">
                      <div className="identifier__block" dangerouslySetInnerHTML={{
                        __html: getProductDetailsData?.b2b_identifier_block_pdp?.content,
                      }}
                      ></div>
                    </div>
                  )
                }

                <div className='pdbrand'>
                  <div className="pd__brand">{t("brand")}</div>
                  <div className="pd__brand__img">
                    <img
                      className="img-fluid"
                      src={`${process.env.REACT_APP_BASE_ATTRIBUTE_URL}/${productDetails?.brand_image}`}
                      alt={productDetails?.brand}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {getProductDetailsData?.similar_products?.length > 0
        ? (
          <div className='Pd__slider__section'>
            <div className='Pd__slider__title'>
              <span className="heading__underlines">{t("related_products")}</span>
            </div>
            <BestSeller
              sellerData={getProductDetailsData?.similar_products}
            />
          </div>
        )
        : (
          <></>
        )
      }

      {getProductDetailsData?.pdp_page_banner?.image && (
        <div className="casual__gaming__cont">
          <img
            className="img-fluid"
            src={`${process.env.REACT_APP_BASE_URL}/${getProductDetailsData?.pdp_page_banner?.image}`}
            width="100%" height={777} alt="Casual Gaming"
          />
        </div>
      )}

      <div className="brands__cont">
        <div className='brand__logo__block'>
          {getProductDetailsData?.category_type_logo?.map((value, i) => {
            return (
              <div className='brand__logo__col'>
                <span className='brand__logo'>
                  <Link to={`/${languageCode}/category/licenses/${value?.slug_name}`} title={value?.subcatname}>
                    <img
                      alt={value?.name} title={value?.name}
                      src={`${process.env.REACT_APP_BASE_CATEGORY_URL}/${value?.category_logo}`}
                    />
                    {value?.subcatname}
                  </Link>
                </span>
                <span className='brand__name'>{value?.name}</span>
              </div>
            )
          })}
        </div>
        <div className='brand__tag__block d-none'>
          {getProductDetailsData?.brand_block?.map((value, i) => {
            return (
              <Link to={`/${languageCode}/productos/${value?.slugname}`} className="brand__tag__col" title={value?.title}>
                <span className='brand__tag_name'>{value?.title}</span>
              </Link>

            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ProductDetails;
