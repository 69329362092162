import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import './CouponCode.scss';
import { useTranslation } from "react-i18next";
import { applyCouponCode, couponMgsReset } from "../../redux/features/Checkout/Action";

function CouponCode({totalPrice}) {
  const { t, i18n } = useTranslation();
  const dispatch    = useDispatch();
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  // Get Language Code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  const { applyCodeRes, successMsg, errorInfo } = useSelector(
    (state) => state?.promoCodeData
  );

  useEffect(() => {
    if (errorInfo || successMsg) {
      setIsAlertVisible(true);

      setTimeout(() => {
        dispatch(couponMgsReset());
        setIsAlertVisible(false);
      }, 5000);
    }
  }, [successMsg, errorInfo]);

  const SuccessAlert = () => {
    return <p className="couponCode__success">{successMsg}</p>
  };

  const FailureAlert = () => {
    return <p className="couponCode__error">{errorInfo}</p>
  };

  const formiks = useFormik({
    initialValues: {
      promoValue: "",
    },

    validate: (values) => {
      const errors = {};

      if (!values.promoValue) {
        errors.promoValue = t("coupon_code__blank_validation");
      } 

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(applyCouponCode(values?.promoValue, totalPrice, languageCode));
    },
  });

  return (
    <div className='CouponCode__Section'>
      <Formik>
        <form onSubmit={formiks.handleSubmit}>
          <div className="CouponCode__title">
            <label htmlFor="coupon-code">{t("coupon_code")}</label>
          </div>
          <div className="CouponCode__body">
            <input type="text" className="CouponCode__input" title={t("coupon_code")} name="promoValue" placeholder={t("coupon_code_input")}
              onChange={formiks.handleChange} value={formiks.values.promoValue} id="promoValue" />
            <button className="CouponCode__btn" type="submit" title={t("apply_coupon")} value="Apply Coupon">
                {t("apply_coupon")}
            </button>
          </div>

          <p className="CouponCode__error">
            {formiks.errors.promoValue && formiks.touched.promoValue && formiks.errors.promoValue}
          </p>

          <div className="CouponCode__mgs">
            {successMsg && isAlertVisible && <SuccessAlert />}
            {errorInfo && isAlertVisible && <FailureAlert />}
          </div>
        </form>
      </Formik>
    </div>
  )
}

export default CouponCode
