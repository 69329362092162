import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setLangSwitchUrl } from '../../redux/features/Header/Action';

function LangSwitchUrl(data) {

  const dispatch = useDispatch();


  useEffect(() => {
    if(data?.alterNative?.length > 0) {
      const alterNative = data?.alterNative;
      dispatch(setLangSwitchUrl(alterNative));
    }
   
  }, [data?.alterNative])


  return (
    <></>
  )
}

export default LangSwitchUrl
