import React, { useEffect, useMemo, useState } from 'react'
import './PopupAddressModal.scss'
import { useSelector, useDispatch } from 'react-redux'
import { getCountryList } from '../../redux/features/Register/Action'
import { createAddress } from '../../redux/features/MyAccount/Action'
import { useParams } from 'react-router'
import { VscChromeClose } from 'react-icons/vsc'
import { useTranslation } from "react-i18next";

function PopupModal({ editAddress = {}, handleCancel, page, setList, list, selectedTab, userInfo }) {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [currentEdit, setCurrentEdit] = useState({
    first_name: '',
    last_name: '',
    email: '',
    telephone: '',
    address_1: '',
    address_id: '',
    country_id: '',
    state: '',
    city: '',
    postal_code: '',
  })
  const [formValidation, setFormValidation] = useState({
    first_name: false,
    last_name: false,
    email: false,
    telephone: false,
    address_1: false,
    country_id: false,
    state: false,
    city: false,
    postal_code: false
  })
  const { checkoutType } = useParams()

  // Get Customer Info
  const customerinfo = useSelector((state) => state?.customerinfo)

  const [guestID, setGuestID] = useState(1)
  // Form Validation
  const submitHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setFormValidation({
      first_name: Boolean(currentEdit?.first_name),
      last_name: Boolean(currentEdit?.last_name),
      email: Boolean(currentEdit?.email),
      telephone: Boolean(currentEdit?.telephone),
      address_1: Boolean(currentEdit?.address_1),
      country_id: Boolean(currentEdit?.country_id),
      state: Boolean(currentEdit?.state),
      city: Boolean(currentEdit?.city),
      postal_code: Boolean(currentEdit?.postal_code)
    });

    if (Boolean(currentEdit?.first_name) && Boolean(currentEdit?.last_name) && Boolean(currentEdit?.email) && Boolean(currentEdit?.telephone) &&
      Boolean(currentEdit?.address_1) && Boolean(currentEdit?.country_id) && Boolean(currentEdit?.state) && Boolean(currentEdit?.city) && Boolean(currentEdit?.postal_code)
    ) {
      if (userInfo?.userid) {
        dispatch(createAddress(currentEdit, customerinfo?.customer_id));
      } else {
        const newmessage = {
          message: currentEdit?.address_id !== undefined ? t("guest_address_updated") : t("guest_address_added"),
          success: 'true'
        };
        if (currentEdit?.address_id !== undefined) {
          const updatedArray = list?.map(L => (L?.address_id === currentEdit?.address_id ? { ...L, ...currentEdit } : L));
          setList(updatedArray);
          dispatch({ type: "ADDRESS__STATUS", payload: newmessage });
        } else {
          setList(prevState => [
            ...prevState,
            {
              ...currentEdit,
              address_id: guestID,
              default_billing_address: 0,
              default_shipping_address: 0
            }
          ]);
          setGuestID(guestID + 1);
          dispatch({ type: "ADDRESS__STATUS", payload: newmessage });
        }
      }
    }
  };


  // get Country List from the Redux
  const countryList = useSelector((state) => state?.countryList);

  useEffect(() => {
    setCurrentEdit({})
    setFormValidation({
      first_name: true,
      last_name: true,
      email: true,
      telephone: true,
      address_1: true,
      country_id: true,
      state: true,
      city: true,
      postal_code: true
    })
    setCurrentEdit(editAddress)
  }, [editAddress])

  // Getting language code
  const languageCode = useSelector(
    (state) => state?.languageCode
  );

  useEffect(() => {
    dispatch(getCountryList(languageCode))
  }, [languageCode])

  const changeHandler = (name, value) => {
    setCurrentEdit(prevState => ({
      ...prevState,
      [name]: value === true ? 1 : value === false ? 0 : value
    }))
  }

  return (
    <div class="modal-dialog modal-dialog-centered popupmodal modal-dialog-scrollable" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-content popupaddressmodal__content">
        {editAddress?.address_id
          ? <h3 className='popupaddressmodal__title'>
            {t("update_address")} <span className='close__icon' onClick={e => handleCancel(e)}><VscChromeClose /></span>
          </h3>
          : <h3 className='popupaddressmodal__title'>
            {t("new_address")} <span className='close__icon' onClick={e => handleCancel(e)}><VscChromeClose /></span>
          </h3>
        }

        <form className='popupmodal__form' onSubmit={e => submitHandler(e)}>
          <div className='ppm__left'>
            <h5>{t("contact_info")}</h5>
            <div className='ppm__content'>
              <div className='ppm__contact'>
                <label>{t("first_name_input")}
                  <input type="text" name="first_name" onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                    placeholder={t("first_name_input")} value={currentEdit?.first_name === undefined ? "" : currentEdit?.first_name}
                    onChange={e => changeHandler(e.target.name, e.target.value)}
                    disabled={selectedTab === t("address_book") && currentEdit?.default_billing_address === 1 &&
                      (userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')
                    }
                  />
                </label>
                {!formValidation?.first_name && <p className='error'>{t("first_name_blank_validation")}</p>}

                <label>{t("last_name_input")}
                  <input type="text" name="last_name" onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                    placeholder={t("last_name_input")} value={currentEdit?.last_name === undefined ? "" : currentEdit?.last_name}
                    onChange={e => changeHandler(e.target.name, e.target.value)}
                    disabled={selectedTab === t("address_book") && currentEdit?.default_billing_address === 1 &&
                      (userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')
                    }
                  />
                </label>
                {!formValidation?.last_name && <p className='error'>{t("last_name_blank_validation")}</p>}

                <label>{t("email_address_input")}
                  <input type="text" name="email" placeholder={t("email_address_input")} onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                    value={currentEdit?.email === undefined ? "" : currentEdit?.email} onChange={e => changeHandler(e.target.name, e.target.value)}
                    disabled={selectedTab === t("address_book") && currentEdit?.default_billing_address === 1 &&
                      (userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')
                    }
                  />
                </label>
                {!formValidation?.email && <p className='error'>{t("email_formate_validation")}</p>}

                <label>{t("phone_input")}
                  <input type="number" name="telephone" placeholder={t("phone_input")} onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                    value={currentEdit?.telephone === undefined ? "" : currentEdit?.telephone} onChange={e => changeHandler(e.target.name, e.target.value)}
                    disabled={selectedTab === t("address_book") && currentEdit?.default_billing_address === 1 &&
                      (userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')
                    }
                  />
                </label>
                {!formValidation?.telephone && <p className='error'>{t("phone_blank_validation")}</p>}
              </div>

              {window.innerWidth > 580 &&
                <div className='ppl__buttons'>
                  <button onClick={e => handleCancel(e)}>{t("cancel")}</button>
                  <button type='submit'>{t("save_address")}</button>
                </div>
              }
            </div>
          </div>

          <div className='ppm__right'>
            <h5>{t("Address")}</h5>
            <div className='ppm__address'>
              <label>{t("street_address_input")}
                <input type="text" name="address_1" placeholder={t("street_address_input")} onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                  value={currentEdit?.address_1 === undefined ? "" : currentEdit?.address_1} onChange={e => changeHandler(e.target.name, e.target.value)}
                  disabled={selectedTab === t("address_book") && currentEdit?.default_billing_address === 1 &&
                    (userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')
                  }
                />
              </label>
              {!formValidation?.address_1 && <p className='error'>{t("street_add_blank_validation")}</p>}

              <label>{t("city_input")}
                <input type="text" name="city" placeholder={t("city_input")} onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                  value={currentEdit?.city === undefined ? "" : currentEdit?.city} onChange={e => changeHandler(e.target.name, e.target.value)}
                  disabled={selectedTab === t("address_book") && currentEdit?.default_billing_address === 1 &&
                    (userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')}
                />
              </label>
              {!formValidation?.city && <p className='error'>{t("city_blank_validation")}</p>}

              <label>{t("state_input")}
                <input type="text" name="state" placeholder={t("state_input")} onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                  value={currentEdit?.state === undefined ? "" : currentEdit?.state} onChange={e => changeHandler(e.target.name, e.target.value)}
                  disabled={selectedTab === t("address_book") && currentEdit?.default_billing_address === 1 &&
                    (userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')
                  }
                />
              </label>
              {!formValidation?.state && <p className='error'>{t("state_blank_validation")}</p>}

              <label>{t("country_input")}
                <select onChange={(e) => changeHandler("country_id", e.target.value)}
                  disabled={selectedTab === t("address_book") && currentEdit?.default_billing_address === 1 &&
                    (userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')
                  }
                >
                  <option selected>{currentEdit?.country_id === undefined ? t("select_country_input") : currentEdit?.country_name}</option>
                  {countryList?.map(CL => <option key={CL?.country_id} value={CL?.country_id}>{CL?.country_name}</option>)}
                </select>
              </label>
              {!formValidation?.country_id && <p className='error'>{t("country_blank_validation")}</p>}

              <label>{t("zip_input")}
                <input type="text" name="postal_code" placeholder={t("zip_input")} onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                  value={currentEdit?.postal_code === undefined ? "" : currentEdit?.postal_code} onChange={e => changeHandler(e.target.name, e.target.value)}
                  disabled={selectedTab === t("address_book") && currentEdit?.default_billing_address === 1 &&
                    (userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')
                  }
                />
              </label>
              {!formValidation?.postal_code && <p className='error'>{t("zip_blank_validation")}</p>}

              {checkoutType === 'addressinfo' ?
                ""
                :
                <>
                  {(userInfo?.group === 'wholesaler' || userInfo?.group === 'retailer')
                    ? <label className='ppm__checkbox'>
                      <input type='checkbox' name="default_shipping_address" checked={currentEdit?.default_shipping_address === 1} disabled={editAddress?.default_shipping_address === 1}
                        onChange={e => changeHandler(e.target.name, e.target.checked)}
                      />
                      {t("select_shipping_address")}
                    </label>
                    : <>
                      <label className='ppm__checkbox'>
                        <input type='checkbox' name="default_billing_address" checked={currentEdit?.default_billing_address & currentEdit?.default_billing_address === 1} disabled={editAddress?.default_billing_address === 1}
                          onChange={e => changeHandler(e.target.name, e.target.checked)}
                        />
                        {t("select_billing_address")}
                      </label>
                      <label className='ppm__checkbox'>
                        <input type='checkbox' name="default_shipping_address" checked={currentEdit?.default_shipping_address === 1} disabled={editAddress?.default_shipping_address === 1}
                          onChange={e => changeHandler(e.target.name, e.target.checked)}
                        />
                        {t("select_shipping_address")}
                      </label>
                    </>
                  }
                </>
              }
              {window.innerWidth < 580 &&
                <div className='ppl__buttons'>
                  <button onClick={e => handleCancel(e)}>{t("cancel")}</button>
                  <button type='submit'>{t("save_address")}</button>
                </div>
              }
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PopupModal