import axios from "axios";

export const brandPageApiData = (data) => {
    return async (dispatch) => {
        if (data.lg) {
            try {
                const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/blppage/${data?.lg}/brand/${data?.slugName}`
                const response = await axios.get(apiUrl);
                dispatch({ type: "BRANDPAGE_APIDATA", payload: response?.data });
            } catch (error) {
                return error;
            }
        }
    }
}