import React, { Suspense } from 'react';
import './CategoryLicensedProduct.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

function CategoryLicensedProduct({ licenseContent, licenseData, Gallerydesc }) {

  //purposely commented
  // const languageCode = localStorage.getItem('languageCode');

  // function removeTags(input) {
  //   return input.replace(/<[^>]*>/g, '');
  // }

  return (
    <Suspense fallback={null}>
      <div className='CatLicensed__section sec__container mb2 pt-3'>
        {licenseData ? (
          <div className="CatLicensed__content mb2">
            <div className='CatLicensed__title'>
              <span dangerouslySetInnerHTML={{ __html: licenseContent?.title }}></span>
            </div>
            <div className='CatLicensed__text'>
              <div dangerouslySetInnerHTML={{ __html: licenseContent?.content }} />
            </div>
          </div>
        ) : (
          <div className="CatLicensed__content mb2">
            <div className='CatLicensed__title'>
              <Skeleton variant="rectangular" animation="wave" width={388} height={40} />
              <Skeleton variant="rectangular" animation="wave" width={388} height={40} />
            </div>
            <div className='CatLicensed__text'>
              <Skeleton variant="rectangular" animation="wave" width={388} height={40} />
              <Skeleton variant="rectangular" animation="wave" width={388} height={40} />
            </div>
          </div>
        )}

        {licenseData?.length > 0 ? <div className="CatLicensed__gallery">
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }} >
            <Masonry >
              {licenseData?.map((data, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="Gallery__block">
                      <img src={`${process.env.REACT_APP_BASE_URL}/${data?.image}`} title={data?.image_title_tag} alt={data?.image_alt_tag} width={915} height={424} />
                      <div className="Gallery__content">
                        {data?.nolink === "brand"
                          ? (
                            <div className="CatLicensed__gallery__title">
                              <h2 className='Gallery__title' dangerouslySetInnerHTML={{ __html: data?.title }}></h2>
                              {Gallerydesc ? <p className='Gallery__desc' dangerouslySetInnerHTML={{ __html: data?.content }}></p> : null}
                            </div>
                          )
                          : data?.nolink === "category"
                            ? (
                              // <Link to={`/${languageCode}/category/licenses/${data?.slugname}`} className="CatLicensed__gallery__title" title={removeTags(data?.title)}>
                              <div className="CatLicensed__gallery__title">
                                <h2 className='Gallery__title' dangerouslySetInnerHTML={{ __html: data?.title }} ></h2>
                                {Gallerydesc ? <p className='Gallery__desc' dangerouslySetInnerHTML={{ __html: data?.content }}></p> : null}
                              </div>
                              //</Link> 
                            )
                            : (
                              // <Link to={`/${languageCode}/category/licenses/${data?.slug_name}`} className="CatLicensed__gallery__title" title={removeTags(data?.title)}>
                              <div className="CatLicensed__gallery__title">
                                <h2 className='Gallery__title' dangerouslySetInnerHTML={{ __html: data?.title }}></h2>
                                {Gallerydesc ? <p className='Gallery__desc' dangerouslySetInnerHTML={{ __html: data?.content }} ></p> : null}
                              </div>
                              //</Link>
                            )
                        }
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div> :
          <div className='imagesUploadSoon'>
            Images Will Upload Soon
          </div>
        }
      </div>
    </Suspense>
  )
}

export default CategoryLicensedProduct;