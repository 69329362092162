import axios from "axios";

export const getProductDetailsPage = (getUserData, languageCode, slugName, slugSku) => {
    return async(dispatch) => {
        if(languageCode){
            try {
                const priceList = getUserData?.price_list ? getUserData?.price_list : '';
                const groupId   = getUserData?.group ? getUserData?.group : '';
                const linearDiscount = getUserData?.linear_discount ? getUserData?.linear_discount : '';

                const data = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BASE_API_URL}/product_details/${languageCode}/${slugName}/${slugSku}?price_list=${priceList}&group=${groupId}&linear_discount=${linearDiscount}`
                })

                dispatch({ type: "PRODUCTDETAILS_DATA", payload: data?.data });

            } catch(e) {
                console.log(e)
            }
        }
    }
};
