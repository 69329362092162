import { useCallback } from 'react';
import TagManager from 'react-gtm-module';

export const useShopRegisterEvent = props => {
  const addShopRegisterEvent = useCallback(async userData => {
    let data = {
      dataLayer: {
        event: 'successful_register',
        RegisterType: userData.RegisterType,
        FirstName: userData.FirstName,
        LastName: userData.LastName,
        Email: userData.Email,
        Telephone: userData.Telephone,
        Address: userData.Address,
        Country: userData.Country,
        State: userData.State,
        City: userData.City,
        Postal: userData.Postal,
        Company: userData.Company,
        TaxNumber: userData.TaxNumber,
        Language: userData.Language,
      }
    };

    console.log('GTM_EVENT successful_Register',data);
    
    TagManager.dataLayer(data);
  });

  return {
    addShopRegisterEvent
  };
};
