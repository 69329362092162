import React, { useRef, useEffect } from 'react';

const FlyingCartImage = ({ imageSrc, targetFrom, targetSize, maxX, maxY }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;

    const element = document.querySelector('.shopping__cart');
    const targetRect = element.getBoundingClientRect();

    const imageRect  = ref.current.getBoundingClientRect();

    const deltaX = targetRect.left - (parseInt(imageRect.left) + parseInt(maxX));
    const deltaY = targetRect.top - (parseInt(imageRect.top) + parseInt(maxY));

    ref.current.animate(
      [
        { transform: `translate(0, 0)` },
        { transform: `translate(${deltaX}px, ${deltaY}px) scale(0.1, 0.1)` },
      ],
      {
        duration: 1000,
        easing: 'ease-out',
      }
    ).onfinish = () => {
      ref.current.remove();
    };
  }, []);

  return <img ref={ref} src={imageSrc} style={{ top: targetFrom?.posTop, left: targetFrom?.posLeft, position: "fixed", zIndex:999, width: targetSize?.posWidth, height: targetSize?.posHeight}}/>
};

export default FlyingCartImage;
