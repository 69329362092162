import axios from "axios";

export const getContactUs = (languageCode) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/aboutus/${languageCode}/contact`
            })

            dispatch({ type: "CONTACTUS__DATA", payload: data?.data });

        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};

export const contactFormAction = (values, languageCode, { resetForm }) => {
    try {
        return async (dispatch) => {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/contactinfo?name=${values?.name}&email=${values?.email}&telephone=${values?.phone}&comment=${values?.comment}`
            })

            if (data?.data?.success === true) {
                dispatch(contactSuccessAction(data?.data));
                resetForm();
            } else {
                dispatch(contactErrorAction(data?.data));
                resetForm();
            }
        };
    } catch (error) {
        console.log(`Error has occurred!`);
    }
};


export const contactSuccessAction = (pData) => {
    return {
        type: "CONTACT_FORM_MESSAGE",
        payload: pData,
    };
};

export const contactErrorAction = (pData) => {
    return {
        type: "CONTACT_FORM_MESSAGE",
        payload: pData,
    };
};

export const contactResetAction = () => {
    return {
        type: "CONTACT_FORM_RESET",
    };
};

export const getCMSSecurePayemtData = (languageCode, pageSlug) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/${pageSlug}`
            })
            dispatch({ type: "CMSSecurePayemt__DATA", payload: data?.data });
        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};

export const getCMSShippingData = (languageCode, pageSlug) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/${pageSlug}`
            })
            dispatch({ type: "CMSSShipping__DATA", payload: data?.data });
        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};

export const getCMSLegalNotice = (languageCode, pageSlug) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/${pageSlug}`
            })
            dispatch({ type: "CMSLegalNotice__DATA", payload: data?.data });
        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};

export const getCMSCookiesData = (languageCode, pageSlug) => {
    return async (dispatch) => {
        try {
            const data = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/${pageSlug}`
            })
            dispatch({ type: "CMSCookies__DATA", payload: data?.data });
        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};

export const getAboutUs = (languageCode) => {
    return async (dispatch) => {
        try {

            const bannerData = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/aboutusbanner/`
            })

            console.log("bannerData", bannerData);


            if (bannerData?.data?.length > 0) {
                dispatch({ type: "ABOUTBANNER__DATA", payload: bannerData?.data });
            }

            const contentData = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/aboutuscontent/`
            })

            if (contentData?.status === 200) {
                dispatch({ type: "ABOUTCONTENT__DATA", payload: contentData?.data });
            }

            const galleryData = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_API_URL}/${languageCode}/aboutusgallery/`
            })

            if (galleryData?.data) {
                dispatch({ type: "ABOUTGALLERY__DATA", payload: galleryData?.data });
            }

        } catch (error) {
            console.log(`Error has occurred!`);
        }
    };
};