import React from 'react'
import { useSelector } from 'react-redux'

function RedsysMethod({ PlaceOrderNav }) {
  const paymentselect = useSelector(state => state?.paymentselect)
  console.log(paymentselect, 'paymentselect')
  return (
    <form
      action={paymentselect?.host_url}
      method="post"
      name="redsys form"
      target="_blank"
    >
      <input
        type="hidden"
        name="Ds_SignatureVersion"
        value={paymentselect?.Ds_SignatureVersion}
      />
      <input
        type="hidden"
        name="Ds_MerchantParameters"
        value={paymentselect?.Ds_MerchantParameters}
      />
      <input
        type="hidden"
        name="Ds_Signature"
        value={paymentselect?.Ds_Signature}
      />

      <button
        style={{
          backgroundColor: '#0da5e8', color: 'white', height: "40px", border: 'none'
        }}
        type="submit"
        // onClick={PlaceOrderNav}
        className="checkoutbutton w-100"
      >
        Place Order
      </button>
    </form>
  )
}

export default RedsysMethod